import React from "react";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from "chart.js";
import { Radar } from "react-chartjs-2";
import Loader from "../containers/Loader";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const options = {
  maintainAspectRatio: false,
  scales: {
    r: {
      min: 0, // レーダーチャートの値の最小値を0に設定
      max: 1, // レーダーチャートの値の最大値を100に設定
      pointLabels: {
        font: {
          size: 15,
        },
      },
      ticks: {
        maxTicksLimit: 2, // 最大で表示するticksの数を2に設定（minとmaxだけ表示する）
      },
    },
  },
};

export const RadarChart = (props) => {
  return props.data === "empty" ? (
    <div className="top-page-content-2rows top-page-content-no-data">No Data</div>
  ) : props.data ? (
    <div className="top-page-content-2rows">
      <Radar options={options} data={props["data"][props.data_key]} height={200} />
    </div>
  ) : (
    <div className="top-page-content-2rows">
      <Loader></Loader>
    </div>
  );
};

import React, { useState } from "react";
import Layout from "./layout/Layout";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SurveySetting from "./SurveySetting";

const ResearchManagement = () => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("survey");
  
  return (
    <Layout>
      <div className="col-12 px-2 child-layout">
        <div className="pb-2 cus-tab-container">
          <Tabs
            id="uncontrolled-tab-example"
            className="mb-0 d-flex"
            onSelect={(e) => {
              setActiveTab(e);
            }}
            activeKey={activeTab}
          >
            <Tab eventKey="survey" title={t("survey")}>
              <SurveySetting title={"surveys"} />
            </Tab>
            <Tab eventKey="topinterviews" title={t("interviews")}>
              <SurveySetting title={"topinterviews"} />
            </Tab>
            <Tab eventKey="workshops" title={t("workshops")}>
              <SurveySetting title={"workshops"} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Layout>
  );
};
export default ResearchManagement;

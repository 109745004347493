import React, { useState, useEffect, useCallback, } from "react";
import Layout from "./layout/Layout";
import { useTranslation } from "react-i18next";
import { FaUpload, FaTrashAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import "./WorkshopResult.scss";
import axios from "axios";
import { SurveyService } from "../utils/services/survey.service";
import { useStore } from "../../src/Store";

import Swal from "sweetalert2";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { ResponseRemarks } from '../containers/ResponseRemarks'

const surveyService = new SurveyService();
const TopInterviewResult = ({ title }) => {
  const { t, i18n } = useTranslation();

  const [image2, setImage2] = useState(null);
  const [image, setImage] = useState(null);
  const { tenant } = useStore();
  const [responses, setResponse] = useState([]);

  const [responseId, setResponseId] = useState(null);
  const [inputForm, setInputForm] = useState("");
  const [responseRemarks, setResponseRemarks] = useState([]);
  const [questionAndAnswer, setQuestionAndAnswer] = useState([]);
  const values = useForm();

  const onChangeImage = async (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

    }
  };
  const onChangeImage2 = async (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage2(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

    }
  };


  useEffect(() => {
    if (tenant) {
      setResponseId(null)
      getSurveyResponsesByTenant();
    }
  }, [tenant]);


  const getSurveyResponsesByTenant = async () => {
    try {
      const data = {
        tenant: tenant,
        type: title,
      };
      const response = await surveyService.getSurveyResponsesByUser(data);
      if (response) {
        setResponse(response.data);

      }
    } catch (err) { }
  };

  const fetchResponseData = async (responseData) => {
    const parsedResponse = JSON.parse(responseData);
    setResponseId(parsedResponse.response_id);
    const parsedResponseJson = JSON.parse(parsedResponse.response);
    values.reset()
    setQuestionAndAnswer([])
    getSingleSurvey(parsedResponse.survey_id, title, parsedResponseJson, parsedResponse.response_id);
  };

  const getSingleSurvey = useCallback(
    async (survey_id, title, responseJson, response_id) => {
      const questions = [];
      try {
        const params = {
          survey_id,
        };
        const response = await surveyService.getSingleSurvey(params, title);
        const parsedSurveyData = await JSON.parse(response.data.survey);

        if (parsedSurveyData && responseJson) {
          parsedSurveyData.pages.map((page) => {
            page.elements.map((ques) => {
              ques["response"] = "";
              Object.entries(responseJson).map((resp) => {
                const [key, answer] = resp;

                if (key === ques.name) {
                  if (ques.type === "rating") {
                    let rateMax, checkminmax;
                    rateMax = ques.rateMax || 5;
                    checkminmax = rateMax / 2;

                    if (answer <= checkminmax) {
                      ques["response"] = `${ques.mininumRateDescription ||
                        ques.minRateDescription ||
                        ""
                        } (${answer})`;
                    } else {
                      ques["response"] = `${ques.maximumRateDescription ||
                        ques.maxRateDescription ||
                        ""
                        } (${answer})`;
                    }
                  } else if (["matrix"].includes(ques.type)) {
                    ques?.rows &&
                      ques.rows.map((rowQue) => {
                        let colAns = ques.columns.find(
                          (col) => col.value === answer[rowQue.value]
                        ).text;
                        ques["response"] += `${rowQue.text}: ${colAns} \n`;
                      });
                  } else if (ques.type === "multipletext") {
                    let arr = [];
                    ques.items.map((ele) => {
                      for (let key in answer) {
                        if (ele.name === key) {
                          let ans = ` ${ele.title + ": " + answer[key]} \n`;
                          arr.push(ans);
                        }
                      }
                    });
                    ques["response"] = arr;
                  } else if (ques.type === "boolean") {
                    ques["response"] = answer.toString();
                  } else if (ques.type === "checkbox") {
                    ques["response"] = answer.join(", ");
                  } else if (
                    [
                      "radiogroup",
                      "dropdown",
                      "comment",
                      "text",
                      "signaturepad",
                      "imagepicker",
                    ].includes(ques.type)
                  ) {
                    ques["response"] = answer;
                  } else {
                    ques[
                      "response"
                    ] = `${ques.type.toUpperCase()} type not supported`;
                  }
                }
              });
              questions.push(ques);
            });
          });
          callQuestionsAndAnswers(questions, response_id);
          if (questions) {
            try {
              const response = await surveyService.getWorkshopRemarks(response_id)
              setResponseRemarks(response.data.responseRemarks)
              if (response) {
                questions.map(q => {
                  response.data.responseRemarks[q.name]
                    .forEach((item, i) => {
                      values.setValue(`${q.name}[${i}]`, item || '')

                    })
                })
              }
            } catch (err) {
              console.log(err)
            }

          }
        }
      } catch (err) { }
    },
    []
  );

  const callQuestionsAndAnswers = (questions) => {
    setQuestionAndAnswer(questions);
  };
  const updateRemarks = useCallback(() => {
    const responseRemarks = values.getValues()
    if (responseId) {
      const data = {
        response_id: responseId,
        inputForm,
        responseRemarks,
      };
      surveyService.updateRemarks(data).then(() => {
        Swal.fire({
          title: t("comments_submitted_successfuly"),
          confirmButtonColor: "#D57B85",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        })
      });
    }
  }, [responseId, inputForm, responseRemarks]);

  return (
    <Layout>
      <div
        className="col-12 px-2 h-100"
        style={{ maxHeight: "100vh", overflow: "auto" }}
      >
        <div className="d-flex justify-content-end pt-3">
          <h4 className="clr-14213D font-weight-bold fnt-wgt-600"></h4>
          {responses.length && !!responseId && (
            <Button className="me-3" onClick={values.handleSubmit(updateRemarks)}>
              Save all Remarks
            </Button>
          )}
          <select
            name="responseId"
            onChange={(e) => {
              fetchResponseData(e.target.value);
            }}
            className="py-2"
          >
            <option value="DEFAULT" selected={!responseId} disabled>
              {t("select")}
            </option>
            {responses &&
              responses.map((elem, index) => (
                <option key={index} value={JSON.stringify(elem)}>
                  {elem.survey_name} {t("on")}{" "}
                  {new Date(elem.createdAt * 1000).toDateString()}
                </option>
              ))}
          </select>
        </div>
        <div className="h-100">
          {responses.length && responseId === null && (
            <div className="align-items-md-center d-flex h-100 justify-content-center text-body">
              <h3>{t("select_a_response")}</h3>
            </div>
          )}
          {responses.length && responseId !== null && (
            <FormProvider {...values} >
              <form className="text-body">
                <>
                  <label
                    type="text"
                    className="clr-14213D fnt-14"
                    htmlFor="consultants"
                  >
                    <b> {t("consultants")}</b>
                  </label>
                  <input
                    as="textarea"
                    id="consultants"
                    name="consultants"
                    className="w-100 "
                    rows={5}
                    value={inputForm}
                    onChange={(e) => setInputForm(e.target.value)}
                    placeholder={t("input_by_consultant")}
                  />

                </>
                <div className="col-12 d-flex">
                  <div className="col-8">
                    {questionAndAnswer?.length &&
                      questionAndAnswer.map((item) => {
                        return (
                          <h2 className="pb-2 pt-1 text-black-50" key={item.name}>
                            {
                              <>

                                <label
                                  type="text"
                                  className="clr-14213D fnt-14"
                                  htmlFor="consultants"
                                >
                                  <b>{item.title}</b>
                                </label>


                                {["imagepicker", "signaturepad"].includes(
                                  item.type
                                ) ? (
                                  <img src={item.response} />
                                ) : (
                                  <p
                                    id="position1"
                                    className="w-100 pre-line"
                                  >
                                    {item.response || "Not Answered"}
                                  </p>
                                )}

                                {responseRemarks && <ResponseRemarks questionData={{
                                  'question': item.name, 'remarks': responseRemarks[item.name]
                                }} ></ResponseRemarks>}



                              </>
                            }
                          </h2>
                        );
                      })}
                  </div>
                  <div className="col-4 p-4">
                    {image ? (
                      <div className="previewProfilePic pt-2">
                        <img
                          className="playerProfilePic_home_tile w-100"
                          src={image}
                        />
                        <button
                          className="btn btn-E65C4F mt-2"
                          onClick={() => setImage(null)}
                        >
                          <FaTrashAlt /> <span>Delete</span>
                        </button>
                      </div>
                    ) : (
                      <div className="p-2 file-outer-border ">
                        <label className="file">
                          <FaUpload />
                          <div className="drag">Drag and drop</div>
                          <input
                            type="file"
                            id="myFile"
                            name="myFile"
                            className="pt-2"
                            accept="image/*"
                            onChange={onChangeImage}
                          />
                        </label>
                      </div>
                    )}

                    {image2 ? (
                      <div className="previewProfilePic pt-2">
                        <img
                          className="playerProfilePic_home_tile w-100"
                          src={image2}
                        />
                        <button
                          className="btn btn-E65C4F mt-2"
                          onClick={() => setImage2(null)}
                        >
                          <FaTrashAlt /> <span>Delete</span>
                        </button>
                      </div>
                    ) : (
                      <div className="p-2 file-outer-border mt-2">
                        <label className="file ">
                          <FaUpload />
                          <div className="drag">Drag and drop</div>
                          <input
                            type="file"
                            id="myFile"
                            name="myFile"
                            className="pt-2"
                            accept="image/*"
                            onChange={onChangeImage2}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default TopInterviewResult;

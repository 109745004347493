import React, { useState, useEffect } from "react";
import Layout from "./layout/Layout";
import FileUpload from "./shared/Fileupload/FileUpload";
import { Tab, Tabs } from "react-bootstrap";
import "./shared/Fileupload/Analysis.scss";
import { useTranslation } from "react-i18next";
import { AnalysisService } from "../utils/services/analysis.service";
import { ApiService } from "../utils/services/api";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useStore } from "../Store";
import RoleType from "../utils/constants/RoleType";
import { FaRedo } from "react-icons/fa";
import Loader from "./Loader";
import {
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";

const initialValues = {
    admin_comments: "",
    new_office_address: "",
};
const savedValues = {
    admin_comments: "",
    new_office_address: "",
};
const validationSchema = Yup.object({
    new_office_address: Yup.string()
});
const apiService = new ApiService();
const analysisService = new AnalysisService();

const fileTypesforCsv = [".csv"];
const fileTypesforJpg = [".png", ".jpeg", ".jpg"];

export default function LocationAnalysis() {
    const [key, setKey] = useState("data-analysis");
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState("");
    const { tenant } = useStore();
    const [User, setUser] = useState("");
    const [EmbedUrl, setEmbedUrl] = useState("");
    const location = useLocation();
    const [formValues, setFormValues] = useState(null);
    const [userType, setUserType] = React.useState("user");
    const [editAdmin, setEditAdmin] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [index, setIndex] = useState(0);
    const [cognitoTenant, setCognitoTenant] = useState("");
    const [mapUrl, setMapUrl] = useState("");
    const [addressLoader, setAddressLoader] = useState(false);
    const [adminLoader, setAdminLoader] = useState(false);

    const changeLang = (val) => {
        setLang(val);
        sessionStorage.setItem("lang", val);
        i18n.changeLanguage(val);
    };
    useEffect(() => {
        getVerifier();
        if (key === "data-analysis" && tenant && User && location) {
            getQuickSightAnalysis();
            getMap();
        }
        if (i18n.language) {
            changeLang(i18n.language);
        }
    }, [i18n.language, User, tenant, location, userType]);

    useEffect(() => {
        if (tenant && location) {
            getAdminComments();
        }
        if (tenant && location) {
            getOfficeAddress();
        }

        if (location.pathname) {
            setKey("data-analysis");
        }
    }, [location, tenant, formValues]);

    const getVerifier = async () => {
        try {
            const response = await apiService.getToken();
            const decoded = jwt_decode(response);
            setUser(decoded["cognito:username"]);
            setCognitoTenant(decoded["cognito:groups"][0]);
            setUserType(decoded["custom:role"]);
        } catch (err) { }
    };
    const getQuickSightAnalysis = async () => {
        getVerifier();
        const params = {
            user: User,
            analysis: location.pathname.split("/")[1],
            tenant: cognitoTenant,
            tenant_dashboard: tenant,
            userType: userType,
        };
        const response = analysisService.getQuickSightDashboardAnalysis(params);
        const res = await response;
        setEmbedUrl(res.data.EmbedUrl);
    };

    const onSetKey = (k) => {
        setKey(k);
        if (k === "data-analysis" && tenant && User) {
            getQuickSightAnalysis();
        }
    };

    const getAdminComments = async () => {
        const params = {
            analysis: location.pathname.split("/")[1],
        };
        const response = await analysisService.getAdminComments(params, tenant);
        if (response) {
            savedValues["admin_comments"] = response.data.comments;
            setFormValues(savedValues);
        }
    };
    const saveAdminComments = async (e) => {
        setAdminLoader(true);
        const data = {
            comments: e.admin_comments,
            analysis: location.pathname.split("/")[1],
        };
        const response = await analysisService.postAdminComments(data, tenant);
        if (response) {
            setAdminLoader(false);
            setFormValues(initialValues);
            getAdminComments();
            Swal.fire({
                title: t("comments_submitted_successfuly"),
                confirmButtonColor: "#D57B85",
                confirmButtonText: "OK",
                allowOutsideClick: false,
            })
            setEditAdmin(false);
        }
    };

    const postOfficeAddress = async (e) => {
        setAddressLoader(true);
        const data = {
            tenant: tenant,
            new_address: e.new_office_address,
        };

        try {
            const response = await analysisService.postOfficeAddress(data);
            if (response) {
                setFormValues(savedValues.new_office_address);
                setAddressLoader(false);
                setEditAddress(false);

                const [officeAddressResult, mapResult, quickSightResult] = await Promise.all([
                    getOfficeAddress(),
                    getMap(),
                    getQuickSightAnalysis(),
                ]);

                Swal.fire({
                    title: t("address_changed"),
                    confirmButtonColor: "#D57B85",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                });
            }
        } catch (error) {
            setAddressLoader(false);
            let errorMessage;
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        errorMessage = t("address_not_found");
                        break;
                    case 403:
                        errorMessage = t("remaining_time_expired");
                        break;
                    default:
                        errorMessage = t("simulation_failed");
                        break;
                }
            } else {
                errorMessage = t("simulation_failed");
            }

            Swal.fire({
                title: errorMessage,
                confirmButtonColor: "#D57B85",
                confirmButtonText: "OK",
                allowOutsideClick: false,
            });
        }
    };


    const getOfficeAddress = async () => {
        const response = await analysisService.getOfficeAddress(tenant);
        if (response) {
            if (!response.data.data.new) {
                setEditAddress(true);
            } else {
                setEditAddress(false);
            }
            savedValues["new_office_address"] = response.data.data.new;
            setFormValues(savedValues);
        } else {
            setEditAddress(false);
        }
    };

    const getMap = async () => {
        try {
            const response = await analysisService.getMap(tenant);
            if (response) {
                setMapUrl(response.data);
            }
        } catch (error) {
            console.error("Error while fetching the map:", error);
            Swal.fire({
                title: t("get_map_failed"),
                confirmButtonColor: "#D57B85",
                confirmButtonText: "OK",
                allowOutsideClick: false,
            });
        }
    }


    const onEdit = async (formikType) => {
        if (formikType === "comments") {
            setEditAdmin(true);
        } else if (formikType === "addresses") {
            setEditAddress(true);
        }
    };

    return (
        <Layout>
            <div className="col-12 px-2 child-layout">
                {location.pathname === "/commute-simulation" && (
                    <Formik
                        initialValues={formValues || initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        validateOnMount
                    >
                        {(formik) => {
                            return (
                                <Form className="text-purple p-1 h-100">
                                    <Grid container >
                                        {/* タイトル */}
                                        <Grid item xs={4} className="grid-padding">
                                            <div className="d-flex justify-content-between" >
                                                <div className="p-1 d-flex flex-column">
                                                    <div className="d-flex">
                                                        <h3 className="mb-0 text-body">
                                                            <b>{t("CommuteSimulation")}</b>
                                                        </h3>
                                                    </div>
                                                    <p className="text-body mb-0">{t("commute_simulation_title")}</p>
                                                </div>
                                            </div>
                                        </Grid>
                                        {/* コメント部分 */}
                                        <Grid item xs={7} className="grid-padding">
                                            <label
                                                type="text-in"
                                                className="clr-14213D pt-1"
                                                htmlFor="admin_comments"
                                            >
                                                <h4>
                                                    <b>{t("admin_comments")}</b>
                                                </h4>
                                            </label>
                                            <Field
                                                as="textarea"
                                                id="admin_comments"
                                                name="admin_comments"
                                                className="admin_comments"
                                                rows={3}
                                                disabled={
                                                    ![RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                                                        userType
                                                    ) || !editAdmin
                                                }
                                                placeholder={t("admin_comments")}
                                            />
                                        </Grid>

                                        {/* 編集ボタン */}
                                        <Grid item xs={1} className="grid-padding">
                                            {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                                                userType
                                            ) && (
                                                    <div className=" align-self-end  text-nowrap">
                                                        {!editAdmin ? (
                                                            <button
                                                                className="btn btn-14213D "
                                                                onClick={() => onEdit("comments")}
                                                            >
                                                                {t("edit")}
                                                            </button>
                                                        ) : adminLoader ? (
                                                            <Loader />
                                                        ) : (
                                                            <button
                                                                className="btn btn-14213D w-100"
                                                                onClick={() => saveAdminComments(formik.values)}
                                                            >
                                                                {t("save")}
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            <div className="text-danger">
                                                <ErrorMessage
                                                    name="admin_comments"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <button
                                                className="mt-3 mb-0 bg-white border-0"
                                                onClick={(e) => {
                                                    getQuickSightAnalysis();
                                                    getAdminComments();
                                                }}
                                            >
                                                <h4 className="mt-0 mb-0 text-dark p-0">
                                                    <FaRedo />
                                                </h4>
                                            </button>
                                        </Grid>

                                        {/* 新オフィス住所入力 */}
                                        <Grid item xs={11} className="grid-padding">
                                            <div className="col-10">
                                                <label
                                                    type="text-in"
                                                    className="clr-14213D pt-1 "
                                                    htmlFor="new_office_address"
                                                >
                                                    <h6 className="mb-1">
                                                        <b>{t("new_office_address")}</b>
                                                    </h6>
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    id="new_office_address"
                                                    name="new_office_address"
                                                    className="admin_comments"
                                                    rows={2}
                                                    style={{ height: "38px" }}
                                                    disabled={!editAddress}
                                                    placeholder={t("enter_new_office_address")}
                                                />
                                                <div className="text-danger">
                                                    <ErrorMessage
                                                        name="new_office_address"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </Grid>

                                        {/* 編集ボタン */}
                                        <Grid item xs={1} className="grid-padding">
                                            <div className=" px-2">
                                                <div className="align-self-center ">
                                                    {!editAddress ? (
                                                        <button
                                                            className="btn btn-14213D text-nowrap mt-4"
                                                            onClick={() => onEdit("addresses")}
                                                            hidden={userType === "user"}
                                                        >
                                                            {t("edit")}
                                                        </button>
                                                    ) : addressLoader ? (
                                                        <Loader />
                                                    ) : (
                                                        <button
                                                            className="btn btn-success mt-4"
                                                            onClick={(e) => {
                                                                postOfficeAddress(formik.values);
                                                            }}
                                                        >
                                                            {t("save_address")}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} className="grid-padding">
                                            <Grid container spacing={2} style={{ height: "100%" }}>
                                                <div id="iframeBlock" style={{ height: "100%", width: "100%" }}>
                                                    <div style={{ height: "100%", width: "100%" }} className="iframeBody">
                                                        <div className="commute-info">
                                                            <iframe className="commute-kpi" src={EmbedUrl} ></iframe>
                                                            <iframe style={{ display: "block", width: "100%" }} className="location-map" src={mapUrl} ></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                )
                }
            </div>
        </Layout >
    );
}

import React, { useState, useEffect, useCallback } from "react";
import Layout from "./layout/Layout";
import { Card } from "react-bootstrap";
import { useStore } from "../../src/Store";
import { useTranslation } from "react-i18next";
import {
  FaEdit,
  FaRegCircle,
  FaCloudUploadAlt,
  FaPlusCircle,
  FaTimes
} from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage, FormikConsumer } from "formik";
import { Modal, Button, ProgressBar, ModalHeader } from "react-bootstrap";
import * as Yup from "yup";
import { ClientService } from "../utils/services/client.service";
import { RegisterService } from "../utils/services/register.service";
import RoleType from "../utils/constants/RoleType";
import { industry_en, industry_ja } from "../utils/constants/IndustryType";
import { FaRedo, FaFileCsv, FaTrashAlt } from "react-icons/fa";
import { contract_plan_en, contract_plan_ja } from "../utils/constants/ContractPlan";
import Files from "react-files";
import Papa from "papaparse";
import Loader from "../containers/Loader";
import Swal from "sweetalert2";
import Auth from "@aws-amplify/auth";
import { ConsultantService } from "../utils/services/consultant.service";
import { useSortableData } from "../utils/useSortableData";
import useCompaniesInfo from "../../src/useDataFeching";
import { set } from "lodash";
const clientService = new ClientService();
const registerService = new RegisterService();
const fileTypesforCsv = [".csv"];
const consultantService = new ConsultantService();

const ClientUser = ({ setBulkUserCreateshow, bulkUserCreateshow, setActiveTenant, activeTab, setActiveAgent, setUploadError, uploadErrors, setCreateError, setBottomError}) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [form2Values, setForm2Values] = useState(null);
  const [emailBefore, setEmailBefore] = useState(null);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [users, setUsers] = useState(null);
  const [userId, setuserId] = useState("");
  const [tenantType, settenantType] = useState();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const handleBulkCreateModalClose = () => setBulkUserCreateshow(false);
  const [bulkCreateshow, setBulkCreate] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editEmail, setEditEmail] = useState(true);
  const [userType, setUserType] = useState();
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [targetUserEmail, setTargetUserEmail] = useState(null);
  const [updateSuccessFlag, setUpdateSuccessFlag] = useState(false);

  const { sortItems, requestSort, sortConfig } = useSortableData(users || []);
  const { companies, setCompanies, isLoading } = useCompaniesInfo();
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const userValues = {
    userType: null,
    tenantName: null,
    name: "",
    email: "",
  };
  
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex.test(email);
  };

  const validationSchema = Yup.object({
    userType: Yup.string()
      .required(t("usertype_is_required"))
      .typeError(t("usertype_is_required")),
    tenantName: Yup.string()
      .required(t("tenant_name_is_required"))
      .typeError(t("tenant_name_is_required")),
    email: Yup.string()
      .required(t("email_is_required"))
      .email(t("not_proper_email")),
    name: Yup.string().required(t("user_is_required")),
  });

  const addClientUserModal = async () => {
    setShow(true);
    setFormValues(userValues);
    setIsEdit(false);
    setShow2(false);
  };

  const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

  // DEV_WDT-1 【追加開発_2】契約レンジに合わせて登録可能件数の上限設定 20230925 S
  const strToDate = (str, delim) => {
    var arr = str.split(delim)
    return new Date(arr[0], arr[1] - 1, arr[2]);
  };

  const listCompany = async (data, filter_tenant) => {
    setIsLoadingTableData(true)

    try {
      let companies_filtered
      if (data) {
        const response = await clientService.listCompany(data);
        companies_filtered = response.data
      } else {
        const response = await clientService.listCompany();
        companies_filtered = response.data
      }
      if (companies_filtered.length == 0) {
      }
      else {
      }
      const new_data = companies_filtered.map((c) => {
        return { ...c, startAt: strToDate(c.startAt, '/'), expireAt: strToDate(c.expireAt, '/') }
      })
      setCompanies(new_data)
    } catch (err) { console.log(err) } finally {
      setIsLoadingTableData(false)
    }
  };
  // DEV_WDT-1 【追加開発_2】契約レンジに合わせて登録可能件数の上限設定 20230925 E

  const onSubmitUser = async (e) => {
    try {
      setUploadError([]);
      // DEV_WDT-1 【追加開発_2】契約レンジに合わせて登録可能件数の上限設定 20230925 S
      const selectedTenant = companies.find((ele) => ele.companyName === e.tenantName);
      console.log("selectedTenant.num_of_acc : " + selectedTenant.num_of_acc)
      console.log("selectedTenant.companyName : " + selectedTenant.companyName)
      console.log("selectedTenant.contractRange : " + selectedTenant.contractRange)
      if (selectedTenant.plan !== 'free_survey') {
        if (Number(selectedTenant.contractRange) > 0) {
          if (Number(selectedTenant.contractRange) < Number(selectedTenant.num_of_acc) + 1) {

            // 契約レンジをオーバーした時のメッセージを表示
            const planName = contract_plan_ja.find(e => e.value === selectedTenant.plan)?.name;
            Swal.fire({
              icon: "error",
              title: t("user_registration_failed"),
              text: t("contractRange_err_required").replace("{0}", planName),
            });
            return;
          }
        }
      }
      // DEV_WDT-1 【追加開発_2】契約レンジに合わせて登録可能件数の上限設定 20230925 S
      await sleep(500);
      setLoader(true);

      if (!selectedTenant) {
        Swal.fire({
          icon: "error",
          title: t("user_registration_failed"),
          text: t("tenant_not_found"),
        });
        return;
      }

      console.log("ユーザー登録中")
      console.log(e)

      const data = {
        isCreating:true,
        email: e.email,
        userType: e.userType,
        username: e.email,
        name: e.name,
        tenant: selectedTenant.tenantId,
        groupNamePrefix: "Reader",
        list_tenant_dashboard: [selectedTenant.tenantId],
      };

      const response = await registerService.createUser(data);
      if (response) {
        setShow(false);
        setLoader(false);
        getUser();
        Swal.fire({
          title: t("new_user_registered"),
          confirmButtonColor: "#D57B85",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        })
        // DEV_WDT-1 【追加開発_2】契約レンジに合わせて登録可能件数の上限設定 20230925 S
        listCompany();
        setCompanies(
          companies.map((item) =>
            item.tenantId === e.tenantId ? { ...item } : item
          )
        );
        handleClose(true);
        // DEV_WDT-1 【追加開発_2】契約レンジに合わせて登録可能件数の上限設定 20230925 S
      } else {
        setLoader(false);
      }
    } catch (err) {
      Swal.fire({
        title: "ERROR!",
        icon: "error",
        buttonsStyling: false,
        text: t("user_registration_failed"),
        confirmButtonText: "Close",
        customClass: {
          confirmButton: "btn btn-danger op-100 ",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      setLoader(false);
    }
  };

  const getUser = async (data) => {
    setIsLoadingTableData(true)
    try {
      const res = await clientService.getUser(data);
      setUsers(res.data);
      setBottomError("");
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 404) {
        setUsers([]);
        setBottomError(t("serch_user_result_empty"));
      } else if(err.response && err.response.status === 503) {
        setUsers([]);
        setBottomError(t("resource_error"));
      } else {
        setUsers([]);
        setBottomError(t("unexcepted_error"));
      }
      } finally {
      setIsLoadingTableData(false)
    }
  };

  const editUser = async (data) => {
    setEditEmail(false);
    const selectedUser = users.find((ele) => ele.userId === data.userId);

    setuserId(data.userId);
    setTargetUserEmail(data.email);

    const selectedTenant = companies.find((ele) => ele.tenantId === data.tenant);

    const selectedUsersValues = {
      userType: selectedUser.userType,
      tenantName: selectedTenant.companyName,
      name: selectedUser.userName,
      email: selectedUser.email,
    };
    setFormValues(selectedUsersValues);
    setIsEdit(true);
    setShow(true);
  };

  useEffect(() => {
    if (activeTab == 'client_user') {
      setActiveTenant('')
      setActiveAgent('')
    }
  }, [activeTab]);

  const updateUser = async (ele) => {
    ele["userId"] = userId;
    if (ele.userId !== undefined) {

      console.log("ユーザー更新中")
      console.log(userId)
      const selectedTenant = companies.find((e) => e.companyName === ele.tenantName);
      const tenatId = selectedTenant.tenantId;

      ele["tenant"] = tenatId;
      ele["username"] = targetUserEmail
      ele["groupNamePrefix"] = 'Reader'
      ele["isCreating"] = false

      console.log(ele)
      try {
        setUploadError([]);
        const response = await registerService.createUser(ele);
        if (response) {
          getUser();
          handleClose(true);
          setUsers(
            users.map((item) => (item.id === ele.userId ? { ...item } : item))
          );
          setBottomError("");
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 404) {
          setBottomError(t("serch_user_result_empty"));
        } else if(err.response && err.response.status === 503) {
          setBottomError(t("resource_error"));
        } else {
          setBottomError(t("unexcepted_error"));
        }
      }
    }
  };

  const onFilesChange = (files) => {
    setUploadError([]);
    if (files && files[0].type === "text/csv") {
      try {
        let uploadError = [];
        let emails = [];
        const expectedHeaders = ['userType', 'tenantName', 'name', 'email'];
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            const actualHeaders = results.meta.fields;
            console.log("actualHeaders")
            console.log(actualHeaders)
            const headersMatch = expectedHeaders.every((header, index) => actualHeaders[index] === header);
            console.log(headersMatch)
            if (!headersMatch || actualHeaders.length != 4) {
              Swal.fire({
                icon: "error",
                text: t("table_title_err"),
              });
              return;
            }

            if (results.data.length === 0) {
              Swal.fire({
                icon: "error",
                text: t("none_data_csv"),
              });
              return;
            }
            setParsedData(
              results.data.map((c, index) => {
                if (!(c.userType)) {
                  uploadError.push({ type: "required", index: index, item: "usertype", column: "userType" })
                } else {
                  if (c.userType!="CLIENT_LEADER" && c.userType!="CLIENT_MEMBER") {
                    uploadError.push({ type: "userTypeInvalid", index: index })
                  }
                }
                if (!(c.tenantName)) {
                  uploadError.push({ type: "required", index: index, item: "tenantname", column: "tenantName" })
                } else {
                  const selectedTenant = companies.find((ele) => ele.companyName === c.tenantName);
                  if (!selectedTenant) {
                    uploadError.push({ type: "tenantInvalid", index: index })
                  }
                }
                if (!(c.name)) {
                  uploadError.push({ type: "required", index: index, item: "name", column: "name" })
                }
                c.email = c.email.trim()
                if (!(c.email)) {
                  uploadError.push({ type: "required", index: index, item: "email", column: "email" })
                } else {
                  if(!validateEmail(c.email)) {
                    uploadError.push({ type: "emailInvalid", index: index, item: "email", column: "email" })
                  }
                }
                const emailIndex = emails.findIndex((e) => e === c.email)
                if (c.email && emailIndex >= 0) {
                  uploadError.push({ type: "duplicated", index1: emailIndex, index2: index })
                }
                emails.push(c.email)

                if (uploadError.length > 0) {
                  Swal.fire({
                    icon: "error",
                    text: t("invalid_csv"),
                  });
                  return;
                }
                c.tenant = companies.find((ele) => ele.companyName === c.tenantName)
                return c;
              })
            );
            setUploadError(uploadError);
          },
        });
        setFiles(files);
      } catch (e) {
        Swal.fire({
          icon: "error",
          text: t("csv_system_err"),
        });
      }
    }
  };

  const onFilesError = (error, file) => {
    setError(error.message);
  };

  const openBukkCreationModal = () => {
    setBulkCreate(true);

    if (files.length) {
      setFiles(null);
    }
  };

  const postCsv = async () => {
    Swal.fire({
      title: t("register_processing_title"),
      html: t("register_processing_text"),
      didOpen: () => {
          Swal.showLoading();
      },
      confirmButtonColor: "#D57B85",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      showConfirmButton: false,
    })

    setBulkCreate(false);
    setUpdateSuccessFlag(false);
    setLoader(true);

    let errorlist = [];
    for (let i = 0; i < parsedData.length; i++) {
      // await new Promise((resolve) => setTimeout(resolve, i * 10000));
      const c =  parsedData[i];
      if(!c) {
        continue;
      }
      const selectedTenant = companies.find((ele) => ele.companyName === c.tenantName);
      // await sleep(500);
      if (!selectedTenant) {
        errorlist.push({ name: c.name, tenant: t("client_not_exit"), email:c.email})
        continue;
      }
      if (selectedTenant.plan !== 'free_survey') {
        if (Number(selectedTenant.contractRange) > 0) {
          if (Number(selectedTenant.contractRange) < Number(selectedTenant.num_of_acc) + 1) {
            errorlist.push({ name: c.name, tenant: selectedTenant.companyName, email:c.email})
            const planName = contract_plan_ja.find(c => c.value === selectedTenant.plan)?.name;
            continue;
          }
        }
      }

      try {
        console.log("ユーザー登録中");
        console.log(c);

        const data = {
          isCreating: true,
          email: c.email,
          userType: c.userType,
          username: c.email,
          name: c.name,
          tenant: selectedTenant.tenantId,
          groupNamePrefix: "Reader",
          list_tenant_dashboard: [selectedTenant.tenantId],
        };
        const resp = await registerService.createUser(data);
        console.log(resp);
        setCompanies(
          companies.map((item) =>
            item.tenantId === c.tenantId ? { ...item } : item
          )
        );
      } catch (e) {
        console.log(e?.response?.data?.message);
        errorlist.push({ email: c.email, index:i, error: e?.response?.data?.message})
      }
    }
    getUser();
    listCompany();
    handleClose(true);
    setLoader(false);
    if (errorlist.length > 0) {
      let uploadError = [];
      errorlist.map((item) => {
        if(item.error=="email_in_use"){
          console.log('email');
          uploadError.push({ type: "email_in_use_err", index: item.index, email:item.email })
        }
      }) 
      setUploadError(uploadError);
      Swal.update({
        title: t("register_complete_fail_title"),
        html: t("register_complete_fail_text"),
        showConfirmButton: true,
      });
      Swal.hideLoading();
    } else {
      Swal.update({
        title: t("register_complete_title"),
        html: t("register_complete_text"),
        showConfirmButton: true,
      });
      Swal.hideLoading();
    }
  };

  useEffect(() => {
    (async () => {
      const data = await Auth.currentUserInfo();
      if (data) {
        setUserType(data.attributes["custom:role"]);
        setUserName(data["username"]);
        setEmail(data.attributes["email"]);
      }
      await getUser();
    })();
  }, []);

  const onDelete = async (row) => {
    setUploadError([]);
    const data = {
      username: row.email,
      groupNamePrefix: "Reader",
      tenant: row.tenant,
    };
    try {
      const deleted = await registerService.deleteUser(data);
      if (deleted) {
        getUser();
      }
      // DEV_WDT-1 【追加開発_2】契約レンジに合わせて登録可能件数の上限設定 20230925 S
      listCompany();
      setCompanies(
        companies.map((item) =>
          item.tenantId === row.tenantId ? { ...item } : item
        )
      );
      console.log("ユーザー削除済みデータ更新")
      handleClose(true);
      Swal.fire({
        title: "削除しました",
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    } catch (err) {
      Swal.fire({
        title: "削除対象のユーザーが見つかりませんでした",
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    }
    // DEV_WDT-1 【追加開発_2】契約レンジに合わせて登録可能件数の上限設定 20230925 E
  };

  const confirmDelete = (row) => {
    Swal.fire({
      title: t("are_u_Sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D57B85",
      cancelButtonColor: "#66AB79",
      confirmButtonText: "削除する",
      cancelButtonText: "キャンセル",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(row);
      }
    });
  };
  const handleSearch = (event) => {
    getUser({ search: searchValue.trim() });
  };
  const selectUserType = (option) => {
    getUser({ search: option });
  };

  return (
    <>
      <div className="col-12 px-2">
        <div>
          <div className="d-flex align-items-center justify-content-between ">
            <label htmlFor="search" className="text-dark mb-2 d-flex align-items-center">
              <input
                id="search"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="form-control rounded-0 mr-2"
                placeholder=""
              />
              <button className="btn btn-14213D w-50 m-3 " onClick={handleSearch}>
                {t("search_data")}
              </button>
            </label>
            <div className="d-flex">
              <button
                className="btn add-btn op-100"
                onClick={addClientUserModal}
              >
                <FaPlusCircle className="align-text-middle" /> {t("add_new_user")}{" "}
              </button>
            </div>
          </div>
          {isLoading || isLoadingTableData ? (
            <Loader></Loader>
          ) : sortItems ? (
            <Card className="border-0">
              <Card.Body className="card-body-height p-0 m-2">
                <Card.Text className=" px-2 ">
                  <table className="table table-striped clr-14213D w-auto">
                    <thead>
                      <tr>
                        <th scope="col">
                          <p className="m-2">No.</p>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("tenant")}
                            className={`${getClassNamesFor("tenant")} btn`}
                          >
                            {t("tenant")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("userName")}
                            className={`${getClassNamesFor("userName")} btn`}
                          >
                            {t("name")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("email")}
                            className={`${getClassNamesFor("email")} btn`}
                          >
                            {t("email")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("userType")}
                            className={`${getClassNamesFor("userType")} btn`}
                          >
                            {t("usertype")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("status")}
                            className={`${getClassNamesFor("status")} btn`}
                          >
                            {t("status")}
                          </button>
                        </th>
                        <th scope="col">
                          <p className="m-2"> {t("action")}</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortItems &&
                        sortItems.map((row, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {companies && companies.find((tenant) => tenant.tenantId === row?.tenant)?.companyName}
                            </td>
                            <td>{row?.userName} </td>
                            <td>{row?.email}</td>
                            <td>{row?.userType.replace("_", " ")}</td>
                            <td>
                              {row.status === 2 ? t("process") : row.status === 1 ? <FaRegCircle /> :
                                <FaTimes />}
                            </td>
                            <td className="bg-transparent p-0 d-flex w-100">
                              <button
                                className="btn btn-green rounded-0 op-100 me-2"
                                onClick={() => editUser(row)}
                              >
                                <FaEdit className="align-text-middle" />{" "}
                                <span className="pt-1">{t("edit")} </span>
                              </button>
                              <button
                                className="btn btn-E65C4F rounded-0"
                                onClick={() => {
                                  confirmDelete(row);
                                }}
                              >
                                <FaTrashAlt /> <span></span>
                                <span className="pt-1">{t("delete")} </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <h4 className="clr-14213D text-center">{t("no_data_available")}</h4>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-agent-popup"
      >
        <Modal.Header closeButton>
          <h5 className="clr-14213D mb-0 ">
            <b> {isEdit ? t("edit") : t("add_new_user")}</b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {(formik) => {
                return (
                  <Form className="text-body" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="userType"
                      >
                        <b> {t("usertype")}</b>
                      </label>
                      <Field
                        as="select"
                        name="userType"
                        className="w-100 select-class"
                        id="inputGroupSelect02"
                        defaultValue={"DEFAULT"}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "userType",
                            e.target.value || null
                          )
                        }
                      >
                        <option value="DEFAULT" selected disabled>
                          {t("select_option")}
                        </option>
                        <option value={RoleType.CLIENT_LEADER}>
                          {t("client_leader")}
                        </option>
                        <option value={RoleType.CLIENT_MEMBER}>
                          {t("client_member")}
                        </option>
                      </Field>
                      <div className="text-danger mb-1">
                        <ErrorMessage name="industry" className="text-danger" />
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="w-100">
                          <label
                            type="text"
                            className="clr-14213D fnt-14"
                            htmlFor="tenantName"
                          >
                            <b> {t("tenant")}</b>
                          </label>
                          <Field
                            as="select"
                            name="tenantName"
                            className="w-100 select-class"
                            // disabled={isEdit}
                            id="inputGroupSelect02"
                            defaultValue={"DEFAULT"}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "tenantName",
                                e.target.value || null
                              )
                            }
                          >
                            <option value="DEFAULT" disabled selected>
                              {t("select_option")}
                            </option>
                            {companies.map((option, index) => (
                              <>
                                <option
                                  key={option.companyName}
                                  value={option.companyName}
                                >
                                  {option.companyName}
                                </option>
                              </>
                            ))}
                          </Field>
                          <div className="text-danger mb-1 ">
                            <ErrorMessage
                              name="tenant"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="name"
                      >
                        <b> {t("user_name")}</b>
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        className="w-100 "
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value || "")
                        }
                      />
                      <div className="text-danger mb-1 ">
                        <ErrorMessage name="name" className="text-danger" />
                      </div>

                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="email"
                      >
                        <b> {t("email")}</b>
                      </label>
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        className="w-100 "
                      />
                      <div className="text-danger mb-1">
                        <ErrorMessage name="email" className="text-danger" />
                      </div>
                    </div>

                    <div className="d-flex justify-content-center pt-2">
                      <button
                        type="button"
                        className="btn btn-E65C4F  op-100"
                        onClick={() => setShow(false)}
                      >
                        {t("cancel")}
                      </button>
                      {isEdit ? (
                        <button
                          className="btn btn-14213D ms-3 op-100"
                          // disabled={!formik.isValid}
                          onClick={() => updateUser(formik.values)}
                        >
                          {t("update")}
                        </button>
                      ) : loader ? (
                        <Loader />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-green ms-3 op-100"
                          disabled={!formik.isValid || !formik.dirty}
                          onClick={() => onSubmitUser(formik.values)}
                        >
                          {t("save")}
                        </button>
                      )}


                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal >

      <Modal
        show={bulkUserCreateshow}
        onHide={handleBulkCreateModalClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h5 className="clr-14213D mb-0 ">{t("upload_csv")}</h5>
        </Modal.Header>
        <Modal.Body className="pb-lg-5 pt-1">
          <div>
            <div className="mb-4 ">
              <div className="d-flex justify-content-center">
                <div>
                  <a
                    href="https://wdp-dev-bucket-tool-zone.s3.ap-northeast-1.amazonaws.com/templates/csv/client_user_sample.csv"
                    className="clr-14213D fnt-14"
                  >
                    {t("downloadSample")}
                  </a>
                </div>
              </div>
              <div className="">
                <Files
                  className="d-flex files-dropzone w-75 mx-auto flex-column align-items-center justify-content-center"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={fileTypesforCsv}
                  multiple={false}
                  name="files"
                >
                  <h6 className="mb-4">{t("drag_drop")}</h6>
                  <FaCloudUploadAlt size={60} />
                  <h6 className="mt-3 mb-0">{t("select_file")}</h6>
                </Files>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {files && files.length
                ? files.map((ele) => {
                  return (
                    <div className="pb-2 pt-1 ps-3 text-black-50" key={ele.id}>
                      {ele.name}
                    </div>
                  );
                })
                : ""}
            </div>
            {error ? <div className="text-danger">{error}</div> : ""}
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn  btn-green   mt-2 op-100 "
                disabled={!(files &&
                  files[0]?.type === "text/csv" &&
                  parsedData.filter((d) => !!d).length &&
                  uploadErrors.length==0)}
                onClick={postCsv}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ClientUser;
import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Loader from "../containers/Loader";
import { Card, CardHeader, CardMedia, CardContent, Typography, makeStyles } from "@material-ui/core";

export const KpiCard = (props) => {
  const { t, i18n } = useTranslation();
  return props.data === "empty" ? (
    <div className="top-page-content-1row top-page-content-no-data">No data</div>
  ) : props.data ? (
    <div className="top-page-content-1row ">
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <p className="kpi-card-title">{t(props["data"][props.data_key]["title"])}</p>
          </Grid>
          <Grid item xs={12} className="kpi-card-value-and-unit">
            <span className="kpi-card-value">{t(props["data"][props.data_key]["value"])}</span>
            <span className="kpi-card-value-unit">{t(props.unit)} </span>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className="top-page-content-1row">
      <Loader></Loader>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import Layout from "./layout/Layout";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { SurveyService } from "../utils/services/survey.service";
import { ApiService } from "../../src/utils/services/api";
import { useTranslation } from "react-i18next";
import "./survey.scss";
import { FaPen, FaChevronRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { FaRedo } from "react-icons/fa";
import "./layout/layout.scss"
import { useStore } from "../../src/Store";
import { ClientService } from "../utils/services/client.service";
import jwt_decode from "jwt-decode";
import { ComponentPropsToStylePropsMap } from "@aws-amplify/ui-react";
import Auth from "@aws-amplify/auth";
import RoleType from "../utils/constants/RoleType";

const surveyService = new SurveyService();
const clientService = new ClientService();
const apiService = new ApiService();
const SurveyList = ({ title }) => {
  const workDesignSurveyId = "9fa8164a-c76d-446f-bfa9-8bae211aaa1f" // for prod
  // const workDesignSurveyId = "1bffad48-cdb3-4c99-9e5e-76b53ac7896d" // for dev
  const navigate = useNavigate();
  const [surveyList, setSurveylist] = useState(null);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("");
  const [tenant_cognito, setTenantCognito] = useState();
  const { tenant } = useStore();
  const [userType, setUserType] = useState(null);
  const location = useLocation();

  useEffect(() => {
    Auth.currentUserInfo().then((data) => {
      setUserType(data.attributes["custom:role"]);
    });
  }, []);

  const getSurveyStatus = async (survey_id, survey_title, tenant_cognito) => {
    const params = {
      "survey_id": survey_id,
      "tenant": tenant,
      "tenant_cognito": tenant_cognito
    };

    const response = await surveyService.checkSurveyResponseReady(params);
    const notificationId = response.data.notification_id;
    const isResponseReady = response.data.is_response_ready;
    const status = response.data.status;
    return {
      survey_id: survey_id,
      survey_title: survey_title,
      notification_id: notificationId,
      is_response_ready: isResponseReady,
      status: status
    };
  };

  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };

  useEffect(() => {
    if (i18n.language) {
      changeLang(i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (userType) {
      getVerifier();
    }
  }, [userType, location, tenant, tenant_cognito]);

  const getSurveys = async (plan, tenant_cognito) => {
    try {
      const response = await surveyService.getSurveys(title);
      const surveys = response.data;

      let filteredSurveys = surveys;
      if (title === "surveys") {
        if (plan === "free_survey") {
          filteredSurveys = surveys.filter(
            (survey) => survey.survey_id === workDesignSurveyId
          );
        }
      }

      const surveyList = filteredSurveys.map(async (survey) => {
        return await getSurveyStatus(
          survey.survey_id,
          survey.survey_title,
          tenant_cognito
        );
      });

      const resolvedSurveyList = await Promise.all(surveyList);
      setSurveylist(resolvedSurveyList);
    } catch (err) {
      console.error(err);
    }
  };

  const getCompany = async (tenant_cognito) => {
    // tenantは、選択された企業名
    if (tenant && [RoleType.CLIENT_LEADER, RoleType.CLIENT_MEMBER].includes(userType)) {
      const response_company = await clientService.getCompany(tenant);
      if (response_company) {
        const plan = response_company.data.plan
        await getSurveys(plan, tenant_cognito);
      }
    }

    if ([RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(userType)) {
      await getSurveys("no_tenant", tenant_cognito);
    }

  };

  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      const tenant_cognito = decoded["cognito:groups"][0];

      await getCompany(tenant_cognito);
    } catch (err) {
      console.error(err);
    }
  };



  return (
    <Layout>
      <div className="col-12 px-2 tab-content border-0 child-layout">
        <div className="d-flex justify-content-between pb-2">
          <h4 className="clr-14213D font-weight-bold fnt-wgt-600">
            {title === "workshops"
              ? t("workshop_list")
              : title === "topinterviews"
                ? t("top_interview_list")
                : t("listSurvey")}
          </h4>
          {/* <div className="d-flex">
            <button className="mt-0 mb-0 me-3 bg-white border-0 p-0" onClick={getSurveys}>
              <h4 className="mt-0 mb-0 text-dark p-0">
                <FaRedo />
              </h4>
            </button>
          </div> */}
        </div>
        <Card className="">
          <Card.Body className="card-body-height p-0 m-2">
            <Card.Title className="text-body"></Card.Title>
            <Card.Text>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '15%' }}>No.</th>
                    <th style={{ width: '70%' }}>
                      {title === "workshops"
                        ? t("workshop_title")
                        : title === "topinterviews"
                          ? t("top_interview_theme")
                          : t("surveyTitle")}
                    </th>
                    <th scope="col">{t("action")}</th>
                  </tr>
                </thead>

                {surveyList &&
                  <tbody>
                    {surveyList.map((row, i) => {
                      {
                        let buttonText = t("takeSurvey");
                        let buttonDisabled = false;

                        if ([RoleType.CLIENT_LEADER, RoleType.CLIENT_MEMBER].includes(userType)) {
                          if (row.status === "ALREADY_RESPONDED") {
                            buttonText = t("responded");
                            buttonDisabled = true;
                          } else if (row.status === "NO_NOTIFICATION") {
                            buttonText = t("no_notification");
                            buttonDisabled = true;
                          }
                        }

                        return (
                          <tr key={i} className="col-12">
                            <td scope="row" className="col-2 align-middle" colspan="1">
                              {i + 1}
                            </td>
                            <td className="col-5 align-middle">
                              {row.survey_title}
                            </td>
                            <td className="col-5 align-middle bg-transparent p-0">
                              <button
                                className="btn btn-2EA56D me-3 op-100"
                                onClick={() =>
                                  navigate(
                                    title === "surveys"
                                      ? `/survey-list/${row.survey_id}/${row.notification_id}`
                                      : title === "workshops"
                                        ? `/workshop-list/${row.survey_id}`
                                        : `/list-top-interview/${row.survey_id}`
                                  )
                                }
                                disabled={buttonDisabled}
                              >
                                <FaPen /> {buttonText} <FaChevronRight />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                }
              </table>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default SurveyList;

import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaEdit,
  FaPlusCircle,
  FaTrashAlt,
  FaCloudUploadAlt,
} from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { ConsultantService } from "../utils/services/consultant.service";
import { ClientService } from "../utils/services/client.service";
import { AgentService } from "../utils/services/agent.service"
import { RegisterService } from "../utils/services/register.service";
import RoleType from "../utils/constants/RoleType";
import Files from "react-files";
import Papa from "papaparse";
import Loader from "./Loader";
import Swal from "sweetalert2";
import { useSortableData } from "../utils/useSortableData";
import useCompaniesInfo from "../useDataFeching";

const consultantService = new ConsultantService();
const clientService = new ClientService();
const agentService = new AgentService();
const registerService = new RegisterService();
const fileTypesforCsv = [".csv"];

const Consultant = ({
  activeTab,
  setActiveTab,
  activeAgent,
  setBulkConsultantCreateshow,
  bulkConsultantCreateshow,
  setActiveTenant,
  activeConsultants,
  setUploadError,
  setBottomError
}) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [consultants, setConsultants] = useState([]);
  const [editEmail, setEditEmail] = useState(true);
  const [lang, setLang] = useState("");
  const [agents, setAgents] = useState([]);
  const [consultantId, setConsultantId] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const handleBulkCreateModalClose = () => setBulkConsultantCreateshow(false);
  const [bulkCreateshow, setBulkCreate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [parsedConsultants, setParsedConsultants] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { sortItems, requestSort, sortConfig } = useSortableData(
    consultants || null
  );
  const { companies, setCompanies, isLoading } = useCompaniesInfo();
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const [targetUserEmail, setTargetUserEmail] = useState(null);

  const initialValues = {
    agentName: "",
    consultantName: "",
    email: "",
    clients: "",
  };

  const validationSchema = Yup.object({
    agentName: Yup.string().required(t("agent_is_required")),
    consultantName: Yup.string().required(t("name_is_required")),
    email: Yup.string()
      .required(t("email_is_required"))
      .email(t("not_proper_email")),
    // clients: Yup.array().required(t("clients_is_required")).min(1),
  });

  const addConsultantModal = async () => {
    setEditEmail(true);
    setShow(true);
    setFormValues(initialValues);
    setIsEdit(false);
  };

  const onSubmit = async (e) => {
    setLoader(true);
    const selectedAgent = agents.find((ele) => ele.agentName === e.agentName);
    if (!selectedAgent) {
      Swal.fire({
        icon: "error",
        title: t("user_registration_failed"),
        text: t("agent_not_found"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
      setLoader(false)
      throw new Error(t("agent_not_found"));
    }
    const selectedTenants = companies.filter((tenant) => {
      return e.clients.includes(tenant.tenantId)
    })


    if (selectedTenants.length !== e.clients.length) {
      Swal.fire({
        icon: "error",
        title: t("user_registration_failed"),
        text: t("tenant_not_found"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })

      throw new Error(t("tenant_not_found"));
    }

    try {
      const response = await registerService.createUser({
        email: e.email,
        username: e.email,
        consultantName: e.consultantName,
        groupNamePrefix: "Reader",
        userType: RoleType.CONSULTANT,
        tenant: "_consultant",
        agentId: selectedAgent.agentId,
        clients: selectedTenants.map((client) => client.tenantId),
        isCreating: true,
      });
      if (response) {
        setShow(false);
        setLoader(false);
        listConsultant();
      }
      Swal.fire({
        title: t("new_consultants_registered"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    } catch (err) {
      Swal.fire({
        title: "ERROR!",
        icon: "error",
        buttonsStyling: false,
        text: t("user_registration_failed"),
        confirmButtonText: "Close",
        customClass: {
          confirmButton: "btn btn-danger op-100 ",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      setLoader(false);
    }
  };

  const listConsultant = async (data) => {
    setIsLoadingTableData(true)
    try {
      await listAgent()
      const response = await consultantService.listConsultant(data);
      if (!response["data"].length) {
        console.log("No consultants found")
        setConsultants([]);
        setBottomError(t("serch_user_result_empty"));
      } else {
        if (!agents) {
          console.log("No agents found")
          setBottomError(t("serch_user_result_empty"));
          return
        }
        if (response.data) {
          if (!activeConsultants) {
            response.data.map((el) => {
              agents.map((item) => {
                if (el.agentId === item.agentId) {
                  return el['agentName'] = item.agentName
                }
              })
            })
            setConsultants(response["data"]);
            setBottomError("");
          } else {
            const filteredConsultants = response.data.filter((consultant) => {
              return activeConsultants.includes(consultant.consultantId)
            }
            )
            filteredConsultants.map((el) => {
              agents.map((item) => {
                if (el.agentId === item.agentId) {
                  return el['agentName'] = item.agentName
                }
              })
            })
            setConsultants(filteredConsultants);
            setBottomError("");
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 404) {
        setConsultants([]);
        setBottomError(t("serch_user_result_empty"));
      } else if(err.response && err.response.status === 503) {
        setConsultants([]);
        setBottomError(t("resource_error"));
      } else {
        setConsultants([]);
        setBottomError(t("unexcepted_error"));
      }
    } finally {
      setIsLoadingTableData(false)
    }
  };


  const handleActiveTab = (val) => {
    setActiveTenant(val)
    setActiveTab("client_company")
  }

  useEffect(() => {
    if (activeTab && activeAgent) {
      listConsultant({ agentId: [activeAgent] })
    }
    else {
      listConsultant()
    }
  }, [activeTab, activeAgent, activeConsultants]);


  useEffect(() => {
    if (activeTab == 'consultants') {
      listAgent();
      setActiveTenant('')
    }
  }, [activeTab]);


  const editConsultant = async (row) => {
    setEditEmail(false)

    if (consultants) {
      setConsultantId(row.consultantId);
      setTargetUserEmail(row.email);
      consultants.map((e) => {
        if (e.consultantId === row.consultantId) {
          setShow(true);
          setIsEdit(true);
          const selectedTenants = companies.filter((tenant) => {
            return e.clients.includes(tenant.tenantId)
          })
          const selectedAgent = agents.find((ele) => ele.agentId === e.agentId);
          const data = {
            consultantName: e.consultantName,
            email: e.email,
            agentName: selectedAgent.agentName,
            clients: [...selectedTenants.map((client) => client.tenantId)],
            agentId: e.agentId
          };
          setFormValues(data);
        }
      });
    }
  };

  const updateConsultant = async (ele) => {
    console.log("updateConsultant")
    console.log(ele)

    ele["consultantId"] = consultantId;
    ele["username"] = targetUserEmail
    ele["userType"] = RoleType.CONSULTANT
    ele["tenant"] = "_consultant"
    ele["groupNamePrefix"] = "Reader"
    ele["isCreating"] = false

    if (ele.consultantId !== undefined) {
      const selectedAgent = agents.find((e) => e.agentName === ele.agentName);
      const selectedTenants = companies.filter((tenant) => {
        return ele.clients.includes(tenant.tenantId)
      })
      try {
        const response = await registerService.createUser({
          ...ele,
          agentId: selectedAgent.agentId,
          clients: selectedTenants.map((client) => client.tenantId),
        })
  
        if (response) {
          listConsultant();
          handleClose(true);
          setConsultants(
            consultants.map((item) =>
              item.consultantId === ele.consultantId ? { ...item } : item
            )
          );
        }
        setBottomError("");
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 403) {
          setBottomError(t("update_user_not_exit"));
        } else if(err.response && err.response.status === 503) {
          setBottomError(t("resource_error"));
        } else {
          setBottomError(t("unexcepted_error"));
        }
      } 
    }
  };

  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };

  useEffect(() => {
    if (i18n.language) {
      changeLang(i18n.language);
    }
    listConsultant();
  }, [i18n.language]);


  const listAgent = async () => {
    const response = await agentService.listAgent();
    if (response) {
      setAgents(response.data);
    }
  }

  const onFilesChange = async (files) => {
    if (files && files[0].type === "text/csv") {
      try {
        let uploadError = [];
        let emails = [];
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (results.data.length === 0) {
              Swal.fire({
                icon: "error",
                text: t("none_data_csv"),
              });
              return;
            }
            setParsedConsultants(
              results.data.map((c, index) => {
                if (!(c.agentName)) {
                  uploadError.push({type:"required", index:index, item:"client", column: "agentName"})
                }
                if (!(c.clients)) {
                  uploadError.push({type:"required", index:index, item:"client", column: "clients"})
                }
                if (!(c.consultantName)) {
                  uploadError.push({type:"required", index:index, item:"consultant_name", column: "consultantName"})
                }
                c.email = c.email.trim()
                if (!(c.email)) {
                  uploadError.push({type:"required", index:index, item:"email", column: "email"})
                }
                const emailIndex = emails.findIndex((e) => e === c.email)
                if (c.email && emailIndex>=0) {
                  uploadError.push({type:"duplicated", index1:emailIndex, index2:index})
                }
                emails.push(c.email)
                if (uploadError.length > 0) {
                  Swal.fire({
                    icon: "error",
                    text: t("invalid_csv"),
                  });
                  return;
                }
                return { ...c, clients: c.clients.split(",").map((client) => companies.find((e) => e.companyName === client).tenantId) };
              })
            );
            setUploadError(uploadError);
          },
        });
        setFiles(files);
      } catch (e) {
        Swal.fire({
          icon: "error",
          text: t("csv_system_err"),
        });
      }
    }
  };

  const onFilesError = (error, file) => {
    setError(error.message);
  };

  const openBukkCreationModal = () => {
    setBulkCreate(true);
    if (files.length) {
      setFiles(null);
    }
  };

  const postCsv = async () => {
    parsedConsultants.forEach((c, i) => {
      setTimeout(() => {
        if (c) {
          console.log("コンサルタント登録中")
          console.log(c);
          onSubmit(c);
        }
      }, i * 1000);
    });
    Swal.fire({
      title: t("csv_request_submitted"),
      confirmButtonColor: "#D57B85",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    })
  }

  const onDelete = async (row) => {
    const data = {
      username: row.email,
      groupNamePrefix: "Reader",
      tenant: "_consultant",
    };

    try {
      const response = await registerService.deleteUser(data);
      Swal.fire({
        title: "削除しました",
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    } catch (err) {
      Swal.fire({
        title: "削除対象のユーザーが見つかりませんでした",
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    }
    listConsultant();

  };

  const confirmDelete = (row) => {
    Swal.fire({
      title: t("are_u_Sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D57B85",
      cancelButtonColor: "#66AB79",
      confirmButtonText: "削除する",
      cancelButtonText: "キャンセル",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(row);
      }
    });
  };

  const handleSearch = (event) => {
    if (activeAgent.length == 0)
      listConsultant({ search: searchValue.trim() });
    else
      listConsultant({ search: searchValue.trim(), agentId: [activeAgent] })
  };

  return (
    <>
      <div className="col-12 px-2">
        <div>
          <div className="d-flex align-items-center justify-content-between ">
            <label htmlFor="search" className="text-dark mb-2 d-flex align-items-center">
              <input
                id="search"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="form-control rounded-0 mr-2"
                placeholder=""
              />
              <button className="btn btn-14213D w-50 m-3 " onClick={handleSearch}>
                {t("search_data")}
              </button>
            </label>
            <div className="d-flex">
              <button
                className="btn add-btn op-100"
                onClick={addConsultantModal}
              >
                <FaPlusCircle className="align-text-middle" /> {t("add_new_consultant")}{" "}
              </button>
            </div>
          </div>

          {isLoading || isLoadingTableData ? (
            <Loader></Loader>
          ) : sortItems ? (
            <Card className="border-0">
              <Card.Body className="card-body-height p-0 m-2">
                <Card.Text className=" px-2 ">
                  <table className="table table-striped clr-14213D w-auto">
                    <thead>
                      <tr className="py-1">
                        <th scope="col">
                          <p className="m-2">No.</p>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("agent")}
                            className={`${getClassNamesFor(
                              "agentName"
                            )} btn`}
                          >
                            {t("agent_name")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("consultantName")}
                            className={`${getClassNamesFor(
                              "consultantName"
                            )} btn`}
                          >
                            {t("consultant_name")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("email")}
                            className={`${getClassNamesFor("email")} btn`}
                          >
                            {t("email")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("clients")}
                            className={`${getClassNamesFor("client")} btn`}
                          >
                            {t("client")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("num_clients")}
                            className={`${getClassNamesFor("num_clients")} btn`}
                          >
                            {t("num_clients")}
                          </button>
                        </th>
                        <th scope="col">
                          <p className="m-2"> {t("action")}</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortItems &&
                        sortItems?.map((row, i) => (
                          <tr key={i}>
                            <td scope="row" className="col-2 align-middle">
                              {" "}
                              {i + 1}
                            </td>
                            <td>{row.agentName}</td>
                            <td>{row.consultantName}</td>
                            <td>{row.email}</td>
                            <td className="btn-contain">
                              <button
                                className="btn btn-14213D"
                                onClick={() => {
                                  handleActiveTab(row.clients);
                                }}
                              >
                                {t("list")}
                              </button>
                            </td>
                            <td>{row.num_clients}</td>
                            <td className="bg-transparent p-0 d-flex w-100">
                              <button
                                className="btn btn-green rounded-0 op-100 me-2"
                                onClick={() => {
                                  editConsultant(row);
                                }}
                              >
                                <FaEdit className="align-text-middle" />{" "}
                                <span className="pt-1">{t("edit")} </span>
                              </button>
                              <button
                                className="btn btn-E65C4F rounded-0"
                                onClick={() => {
                                  confirmDelete(row);
                                }}
                              >
                                <FaTrashAlt /> <span></span>
                                <span className="pt-1">{t("delete")} </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <h4 className="clr-14213D text-center">{t("no_data_available")}</h4>
          )}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-agent-popup"
      >
        <Modal.Header closeButton>
          <h5 className="clr-14213D mb-0 ">
            <b> {isEdit ? t("edit_consultant") : t("add_new_consultant")}</b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={formValues || initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form className="text-body" onSubmit={formik.handleSubmit}>
                    <div className="d-flex justify-content-between">

                      <div className="col-8">
                        <label
                          type="text"
                          className="clr-14213D fnt-14"
                          htmlFor="agentName"
                        >
                          <b> {t("agent_name")}</b>
                        </label>
                        <Field
                          as="select"
                          name="agentName"
                          className="w-100 select-class"
                          id="agentName"
                          defaultValue={isEdit ? formValues.agentName : "DEFAULT"}
                          onChange={(e) =>
                            formik.setFieldValue("agentName", e.target.value)
                          }
                        >
                          <option >
                            {t("select_option")}
                          </option>
                          {agents && agents.map((item) => {
                            return <option
                              key={item.agentName}
                              value={item.agentName}
                            >
                              {item.agentName}
                            </option>
                          })}
                        </Field>
                        <div className="text-danger mb-1">
                          <ErrorMessage
                            name="agentName"
                            className="text-danger"
                          />
                        </div>
                        <label
                          type="text"
                          className="clr-14213D fnt-14"
                          htmlFor="consultantName"
                        >
                          <b> {t("consultant_name")}</b>
                        </label>
                        <Field
                          type="text"
                          id="consultantName"
                          name="consultantName"
                          className="w-100 "
                          onChange={(e) =>
                            formik.setFieldValue(
                              "consultantName",
                              e.target.value || ""
                            )
                          }
                        />
                        <div className="text-danger mb-1 ">
                          <ErrorMessage
                            name="consultantName"
                            className="text-danger"
                          />
                        </div>
                        <label
                          type="text"
                          className="clr-14213D fnt-14"
                          htmlFor="email"
                        >
                          <b> {t("email")}</b>
                        </label>
                        <Field
                          type="text"
                          id="email"
                          name="email"
                          className="w-100 "
                        />
                        <div className="text-danger mb-1">
                          <ErrorMessage name="email" className="text-danger" />
                        </div>
                      </div>
                      <div className=" col-4 px-3 text-body">
                        <h5>
                          {" "}
                          <b>{t("client")} </b>
                        </h5>
                        <div role="group" aria-labelledby="checkbox-group">
                          <label className="col-12">
                            {companies &&
                              companies.map((element, index) => (
                                <div className="col-12">
                                  <Field
                                    type="checkbox"
                                    name="clients"
                                    value={element.tenantId}
                                  />
                                  <span className="ms-2">
                                    {" "}
                                    {element.companyName}
                                  </span>
                                </div>
                              ))}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center pt-2">
                      <button
                        type="button"
                        className="btn btn-E65C4F  op-100"
                        onClick={() => setShow(false)}
                      >
                        {t("cancel")}
                      </button>
                      {isEdit ? (
                        <button
                          type="button"
                          className="btn btn-green ms-3 op-100"
                          disabled={!formik.isValid}
                          onClick={() => updateConsultant(formik.values)}
                        >
                          {t("update")}
                        </button>
                      ) : loader ? (
                        <Loader />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-green  ms-3 op-100"
                          disabled={!formik.isValid || !formik.dirty}
                          onClick={() => { onSubmit(formik.values) }}
                        >
                          {t("save")}
                        </button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={bulkConsultantCreateshow}
        onHide={handleBulkCreateModalClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-0">
          <h5 className="clr-14213D mb-0 ">{t("upload_csv")}</h5>
        </Modal.Header>
        <Modal.Body className="pb-lg-4 pt-1">
          <div className="mb-4 ">
            <div className="d-flex justify-content-center">
              <div>
                <a
                  href="https://wdp-dev-bucket-tool-zone.s3.ap-northeast-1.amazonaws.com/templates/csv/consultant_sample.csv"
                  className="clr-14213D fnt-14"
                >
                  {t("downloadSample")}
                </a>
              </div>
            </div>

            <div className="">
              <Files
                className="d-flex files-dropzone w-75 mx-auto flex-column align-items-center justify-content-center"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={fileTypesforCsv}
                multiple={false}
                name="files"
              >
                <h6 className="mb-4">{t("drag_drop")}</h6>
                <FaCloudUploadAlt size={60} />
                <h6 className="mt-3 mb-0">{t("select_file")}</h6>
              </Files>
            </div>

            <div className="d-flex justify-content-center">
              {files && files.length
                ? files.map((ele) => {
                  return (
                    <div className="pb-2 pt-1 ps-3 text-black-50" key={ele.id}>
                      {ele.name}
                    </div>
                  );
                })
                : ""}
            </div>
            {error ? <div className="text-danger">{error}</div> : ""}
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn  btn-green   mt-2 op-100 "
                disabled={!(files &&
                  files[0]?.type === "text/csv" &&
                  parsedConsultants.filter((d) => !!d).length)}
                onClick={postCsv}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Consultant;
import { ApiService } from "./api";

export class AgentService extends ApiService {
  createAgent(data, file, params = {}) {
    return this.post("/agent", data, file, params);
  }

  listAgent(data) {
    return this.get(`/agent?${this.serializeParams(data)}`);
  }

  getAgent(id) {
    return this.get(`/agent/${id}`);
  }

  deleteAgent(id) {
    return this.delete(`/agent/${id}`);
  }

  updateAgent(id, data) {
    return this.put(`/agent/${id}`, data);
  }

  getConsultantTenants(data) {
    return this.get(`/consultant-client-company-filtering?${this.serializeParams(data)}`)
  }

}

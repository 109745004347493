import React from "react";
import Select from "react-select";

const CustomSelect = ({
    className,
    placeholder,
    options,
    isMulti = false,
    name,
    value,
    onChange,
    disabled = false,
}) => {
    const handleChange = (option) => {
        const newValue = isMulti
            ? (option || []).map((item) => item.value)
            : (option || {}).value;
        onChange(newValue);
    };

    const getValue = () => {
        if (options) {
            return isMulti
                ? options.filter((option) => (value || []).indexOf(option.value) >= 0)
                : options.find((option) => option.value === value);
        } else {
            return isMulti ? [] : "";
        }
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black",
            backgroundColor: state.isSelected ? "black" : "white", // 選択時の背景色を設定
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "black", // プレースホルダーの文字色を黒に設定
            alignItems: 'center',
            textAlign: "center"
        }),
        control: (provided) => ({
            ...provided,
            width: "100%",
            borderStyle: "hidden",
            height: "90px",
            maxHeight: "90px",
            overflowY: "auto",
            alignItems: 'flex-start', // 上端揃え
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: "200px", // オプションのリストの最大高さを設定
            alignItems: 'flex-start', // 上端揃え
        }),
    };


    return (
        <Select
            className={className}
            name={name}
            value={getValue()}
            onChange={handleChange}
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
            styles={customStyles} // カスタムスタイルを適用
            isDisabled={disabled}
        />
    );
};

export default CustomSelect;

export const contract_range_new_en = [
  { name: 'Contract range (~100ppl)', value: '100' },
  { name: 'Contract range (101ppl~500ppl)', value: '500' },
  { name: 'Contract range (501ppl~1000ppl)', value: '1000' },
  { name: 'Contract range (1001ppl~1500ppl)', value: '1500' },
  { name: 'Contract range (1501ppl~2000ppl)', value: '2000' },
  { name: 'Contract range (2001ppl~2500ppl)', value: '2500' },
  { name: 'Contract range (2501ppl~3000ppl)', value: '3000' },
  { name: 'Contract range (3001ppl~)', value: '0' },
];

export const contract_range_en = [
  { name: 'Contract range (~50ppl)', value: '50' },
  { name: 'Contract range (51ppl~100ppl)', value: '100' },
  { name: 'Contract range (101ppl~300ppl)', value: '300' },
  { name: 'Contract range (301ppl~500ppl)', value: '500' },
  { name: 'Contract range (501ppl~1000ppl)', value: '1000' },
  { name: 'Contract range (1001ppl~1500ppl)', value: '1500' },
  { name: 'Contract range (1501ppl~2000ppl)', value: '2000' },
  { name: 'Contract range (2001ppl~2500ppl)', value: '2500' },
  { name: 'Contract range (2501ppl~3000ppl)', value: '3000' },
  { name: 'Contract range (3001ppl~)', value: '0' },
];

export const contract_range_new_ja = [
  { name: '契約レンジ (~100名)', value: '100' },
  { name: '契約レンジ (101名~500名)', value: '500' },
  { name: '契約レンジ (501名~1000名)', value: '1000' },
  { name: '契約レンジ (1001名~1500名)', value: '1500' },
  { name: '契約レンジ (1501名~2000名)', value: '2000' },
  { name: '契約レンジ (2001名~2500名)', value: '2500' },
  { name: '契約レンジ (2501名~3000名)', value: '3000' },
  { name: '契約レンジ (3001名~)', value: '0' },
];

export const contract_range_ja = [
  { name: '契約レンジ (~50名)', value: '50' },
  { name: '契約レンジ (51名~100名)', value: '100' },
  { name: '契約レンジ (101名~300名)', value: '300' },
  { name: '契約レンジ (301名~500名)', value: '500' },
  { name: '契約レンジ (501名~1000名)', value: '1000' },
  { name: '契約レンジ (1001名~1500名)', value: '1500' },
  { name: '契約レンジ (1501名~2000名)', value: '2000' },
  { name: '契約レンジ (2001名~2500名)', value: '2500' },
  { name: '契約レンジ (2501名~3000名)', value: '3000' },
  { name: '契約レンジ (3001名~)', value: '0' },
];
import "survey-core/modern.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Layout from "./layout/Layout";
import "./survey.scss";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { SurveyService } from "../utils/services/survey.service";
import { useTranslation } from "react-i18next";
import { useStore } from "../../src/Store";
import { ApiService } from "../../src/utils/services/api";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";

const surveyService = new SurveyService();
let defaultThemeColors = StylesManager.ThemeColors["modern"];
defaultThemeColors["$main-color"] = "#eac21d";
defaultThemeColors["$sub-color"] = "#eac21d";
StylesManager.applyTheme("modern");

var surveyCss = {
  question: {
    titleOnAnswer: "question-title-answered"
  }
};

const TakeSurvey = ({ title }) => {
  const apiService = new ApiService();
  const [surveyId, setSurveyId] = useState(null);
  const [questionery, setQuestionery] = useState({});
  const { t, i18n } = useTranslation();
  const [tenant_cognito, setTenantCognito] = useState();
  const { tenant } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [savedResponses, setSavedResponses] = useState(null);
  const inProgressRef = useRef(false); // 途中保存の状態を保持する
  const [responseData, setResponseData] = useState({});
  const [survey, setSurvey] = useState(null);

  const getSingleSurvey = async (survey_id, title) => {
    try {
      const params = {
        survey_id,
      };
      const response = await surveyService.getSingleSurvey(params, title);
      const questionery = JSON.parse(response.data.survey);
      questionery.description = "※回答しない状態で30分が経過するとタイムアウトされます。離れる場合は途中保存ボタンを押してください。"
      setQuestionery(JSON.stringify(questionery));
    } catch (err) { }
  };

  const params = useParams();

  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setTenantCognito(decoded["cognito:groups"][0]);
    } catch (err) { }
  };

  //　リロードしたらサーベイを選択するページに強制的に飛ばす
  useEffect(() => {
    const handlePageReload = (event) => {
      sessionStorage.setItem("reloadFlag", "true");
    };

    window.addEventListener("beforeunload", handlePageReload);

    if (sessionStorage.getItem("reloadFlag") === "true") {
      sessionStorage.removeItem("reloadFlag");
      navigate("/survey-list");
    }

    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, [navigate]);

  useEffect(() => {
    if (params) {
      setSurveyId(params.survey_id);
      getSingleSurvey(params.survey_id, title);
      getSavedResponses();
      getVerifier();
    }
  }, [surveyId, questionery]);

  useEffect(() => {
    const surveyModel = new Model(questionery);
    surveyModel.css = surveyCss;
    surveyModel.locale = i18n.language;
    setSurvey(surveyModel);
  }, [surveyId, questionery]);

  const onCustomCompleteButtonClick = async () => {
    // 途中保存データを取得
    const responseToPost = JSON.stringify(survey.data)

    // 途中保存の状態をtrueに設定
    inProgressRef.current = true;

    // 途中保存データをPOSTする
    await saveSurveyResponse(responseToPost);

  };

  const onComplete = async (sender, options) => {
    const data = JSON.stringify(sender.data);
    await saveSurveyResponse(data, true);
  };


  const saveSurveyResponse = async (data, isCompleted = false) => {
    // 途中保存データの形式を変換
    const response = await surveyService.postSurveyResponse(
      {
        title: title,
        tenant: tenant_cognito,
        tenant_target: tenant,
        notification_id: params.notification_id,
        isCompleted: isCompleted,
        currentPageNo: survey.currentPageNo,
      },
      surveyId,
      data
    );

    if (response.status == 201 && !isCompleted) {
      Swal.fire({
        title: t("temporary_response_saved"),
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#D57B85",
      });
    }
  };

  const getSavedResponses = async () => {
    const surveyResponse = await surveyService.getSingleSurveyResponses(params, title);
    const responsesInProgress = surveyResponse.data.filter((ele) => ele.is_completed === "false");

    setSavedResponses(responsesInProgress);
  };

  const selectResponseById = (e) => {
    const resp = savedResponses.find((ele) => {
      return ele.response_id == e;
    });
    if (resp.current_page_no === "undefined") {
      survey.currentPageNo = 0
    } else {
      survey.currentPageNo = resp.currentPageNo
    }
    survey.data = JSON.parse(resp.response)

  };

  return tenant_cognito && savedResponses !== null && survey !== null ? (
    <Layout>
      <div className="col-12 px-2 child-layout">
        <div className="d-flex justify-content-end ">
          {savedResponses.length && (
            <select
              name="responseId"
              className="me-2"
              onChange={(e) => {
                selectResponseById(e.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {t("select")}
              </option>
              {savedResponses &&
                savedResponses.map((elem, index) => (
                  <option key={index} value={elem.response_id}>
                    {new Date(elem.createdAt * 1000).toLocaleString()}
                  </option>
                ))}
            </select>
          )}
          <button
            id="complete"
            className="btn btn-dark ms-2"
            onClick={onCustomCompleteButtonClick}
          >
            {t("save_progress")}
          </button>
          <button
            id="back"
            className="btn btn-outline-secondary mx-3"
            onClick={() => {
              navigate(
                title === "surveys"
                  ? `/survey-list`
                  : title === "workshops"
                    ? `/workshop-list`
                    : `/list-top-interview`
              );
            }}
          >
            {t("back_to_list")}
          </button>
        </div>
        <Survey
          className="w-100 text-white"
          model={survey}
          onComplete={onComplete}
          css={{
            description: 'suv-description' // 应用自定义 CSS 类
          }}
        />
      </div>

    </Layout >
  ) : (
    <div>no data</div>
  );
};

export default TakeSurvey;

import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import Loader from "../containers/Loader";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const LineChart = (props) => {
  return props.data === "empty" ? (
    <div className="top-page-content-2rows top-page-content-no-data">No Data</div>
  ) : props.data ? (
    <div className="top-page-content-2rows">
      <Line
        options={{
          maintainAspectRatio: false,
          //   responsive: false,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: props["data"]["measurement_period"],
            },
            tooltip: {
              callbacks: {
                label: (ctx) => `${ctx.dataset.label}: ${ctx.raw.toFixed(1)}`,
              },
            },
          },
          scales: {
            y: {
              ticks: {
                callback: function (value) {
                  return value.toFixed(1);
                },
              },
            },
          },
        }}
        data={props["data"][props.data_key]}
        // width={750}
        height={250}
      />
    </div>
  ) : (
    <div className="top-page-content-3rows">
      <Loader></Loader>
    </div>
  );
};

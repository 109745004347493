import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./LoginPage.scss";
import logo_WORK_DESIGN_PLATFORM from "../assets/img/1WDP_positive.png";
import { Auth } from "aws-amplify";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { includes } from "lodash";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { ClientService } from "../utils/services/client.service";

const ChangePassword = ({ handleLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [error, setError] = useState(null);

  const { search, state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setUsername(new URLSearchParams(search).get("user"));
  }, [search]);

  const changepwd = async () => {
    setError(null);

    if (newPwd.length > 256) {
      setError(t("over_256_err"));
      return;
    }
    if (newPwd.length < 6) {
      setError(t("under_6_err"));
      return;
    }
    if (confirmPwd.length > 256) {
      setError(t("over_256_err"));
      return;
    }
    if (confirmPwd.length < 6) {
      setError(t("under_6_err"));
      return;
    }
    if (newPwd !== confirmPwd) {
      setError(t("not_match_err"));
      return;
    }

    const clientService = new ClientService();
    const res = await clientService.isSameAsTempPassword(state.user.username, newPwd);
    console.debug("res:", res);
    if (res.data.result) {
      setError(t("same_as_temp_password_err"));
      return;
    }

    try {
      const userPool = new CognitoUserPool({
        UserPoolId: state.user.userPoolId,
        ClientId: state.user.clientId,
      });

      const cognitoUser = new CognitoUser({
        Username: state.user.username,
        Pool: userPool,
      });
      cognitoUser.Session = state.user.session;

      const currentUser = await Auth.completeNewPassword(cognitoUser, newPwd);
      const user = await Auth.signIn(state.user.username, newPwd)
      const loginTime = Math.floor(Date.now() / 1000);
      sessionStorage.setItem('loginTime', loginTime.toString());
      sessionStorage.setItem('idToken', user.signInUserSession.idToken.jwtToken);
      handleLoginSuccess(user);
      navigate("/");
    } catch (e) {
      let errMessage;
      console.log("e:" + JSON.stringify(e));
      if (e.code === "UserNotFoundException") {
        errMessage = t("auth_error_UserNotFoundException");
      } else if (e.code === "InvalidParameterException") {
        errMessage = t("auth_error_InvalidParameterException");
      } else if (e.code === "NotAuthorizedException" && e.message !== "Password attempts exceeded") {
        errMessage = t("auth_error_NotAuthorizedException");
      } else if (e.code === "UserNotConfirmedException") {
        errMessage = t("auth_error_UserNotConfirmedException");
      } else if (e.code === "LimitExceededException" || e.message === "Password attempts exceeded") {
        errMessage = t("auth_error_LimitExceededException");
      } else if (e.code === "InvalidPasswordException") {
        if (newPwd.includes(" ")) {
          errMessage = t("auth_error_PasswordIncludeSpace");
        } else {
          errMessage = t("auth_error_InvalidPasswordException");
        }
      } else {
        errMessage = e.message;
      }

      setError(errMessage);
    }
  };

  return (
    username && (
      <div className="herobg">
        <div className=" main-container ">
          <div>
            <div className="d-flex justify-content-start">
              <img className="logo" src={logo_WORK_DESIGN_PLATFORM} alt="image" />
            </div>
          </div>
          <div className="login-form col-lg-5 col-xl-5 col-md-12 col-12 py-lg-2 py-xxl-5 py-xl-1 py-2 d-flex justify-content-start align-items-center">
            <Form>
              <div className="col-12">
                <h3 className="eng_text font-weight-bold">{t("welcome")}</h3>
                <h3 className="jap_text text-muted ps-2">{t("must_change_password")}</h3>
              </div>
              <div className="col-12">
                <div className="form-data" v-if="!submitted">
                  {error && <h4 className="error">{error}</h4>}
                  <div className="forms-inputs mt-xl-4 my-xxl-5 my-5">
                    <label htmlFor="email" className={`mx-3 px-2 mt-2 label1-focused`}>
                      {t("new_password")}
                    </label>
                    <input
                      className="rounded-2 col-12 py-3 mb-xl-4 mt-xl-3 mb-lg-1 mt-lg-1 mt-1 mb-1 px-4 bg-white"
                      id="newPwd"
                      autoComplete="off"
                      placeholder="........."
                      type="password"
                      v-model="newPwd"
                      onChange={(e) => setNewPwd(e.target.value)}
                    />
                    <div className="forms-inputs ">
                      <label className={`mx-3 px-2 mt-2 label2-focused`}>{t("confirm_new_password")}</label>
                      <input
                        className="rounded-2 col-12 py-3  mt-3 px-4 bg-white "
                        autoComplete="off"
                        placeholder="........."
                        type="password"
                        v-model="confirmPwd"
                        onChange={(e) => setConfirmPwd(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={changepwd} className="btn btn-EAC21D my-1 op-100 w-100" type="button">
                {t("change_password")}
              </button>
            </Form>
          </div>
        </div>
      </div>
    )
  );
};

export default ChangePassword;

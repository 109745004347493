import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./LoginPage.scss";
import logo_WORK_DESIGN_PLATFORM from "../assets/img/1WDP_positive.png";
import { FaChevronRight } from "react-icons/fa";
import { Amplify, Auth } from "aws-amplify";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import VisImg from '../assets/img/Black.svg';
import { ClientService } from "../utils/services/client.service";
import { RegisterService } from "../utils/services/register.service";

const registerService = new RegisterService();

const LoginPage = ({ handleLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [mail, setMail] = useState("");
  const [authMail, setAuthMail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [forgotModalShown, setForgotModalShown] = useState(false);
  const [error, setError] = useState(null);
  const [emailSend, setEmailSend] = useState(false);
  const [modalError, setModalError] = useState(null);
  const [emailfocused, setEmailFocused] = useState(false);
  const [pwdfocused, setpwdfocused] = useState(false);
  const [successFulllyChanged, setSuccessFulllyChanged] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const message = sessionStorage.getItem('errorMessage');
    if (message) {
      setError(message);
      sessionStorage.removeItem('errorMessage');
    }
  }, []);

  const login = async () => {

    try {
      // const response = await fetch('https://ipinfo.io/json')
      // const ipResponse = await response.json()
      const res = await Auth.signIn({
        username: username,
        password: password,
        rememberUser: false,
        // validationData: {
        //   ipAddress: ipResponse.ip
        // }
      });

      console.log(res)

      if (username != "admin") {
        const clientService = new ClientService();
        const email = encodeURIComponent(username);
        const resp = await clientService.checkContractTime(email);
        const checkResult = resp.data;
        console.log(checkResult);
        if (!checkResult.result) {
          setError('契約期間外のためログインができません');
          return;
        }
      }

      if (res?.challengeName === "NEW_PASSWORD_REQUIRED") {
        const user = {
          username: res.username,
          challengeName: res.challengeName,
          session: res.Session,
          userPoolId: res.pool.userPoolId,
          clientId: res.pool.clientId,
        };
        navigate(`/change-password?user=${res.username}`, {
          state: { user },
        });
        return;
      }
      const loginTime = Math.floor(Date.now() / 1000);
      sessionStorage.setItem('loginTime', loginTime.toString());
      sessionStorage.setItem('idToken', res.signInUserSession.idToken.jwtToken);

      const groups = res.signInUserSession.accessToken.payload['cognito:groups'];

      if (groups.length === 0) {
        sessionStorage.removeItem('loginTime');
        setError('このアカウントは所属企業が登録されていません');
        Auth.signOut();
        return;
      }

      handleLoginSuccess(res);
    } catch (e) {
      console.log(e);
      console.log(e.message);
      console.log("e:" + JSON.stringify(e));
      if (e?.message=="Temporary password has expired and must be reset by an administrator.") {
        setError(t("temp_pwd_expired_err"));
      } else if (e.code === "UserNotFoundException") {
        setError(t("auth_error_UserNotFoundException"));
      } else if (e.code === "InvalidParameterException") {
        setError(t("auth_error_InvalidParameterException"));
      } else if (e.code === "NotAuthorizedException" && e.message !== "Password attempts exceeded") {
        setError(t("auth_error_NotAuthorizedException"));
      } else if (e.code === "UserNotConfirmedException") {
        setError(t("auth_error_UserNotConfirmedException"));
      } else if (e.code === "LimitExceededException" || e.message === "Password attempts exceeded") {
        setError(t("auth_error_LimitExceededException"));
      } else {
        if (e?.message=="The username should either be a string or one of the sign in types"){
          setError(t("auth_error_InvalidParameterException"));
        } else {
          setError(e.message);
        }
      }
    }
  };

  const onHide = () => {
    setModalError(null);
    setForgotModalShown(false);
    setEmailSend(false);
    setSuccessFulllyChanged(false);
  };

  const handleEmailSend = async () => {
    try {
      await registerService.adminSetUserPwd(mail);
      await Auth.forgotPassword(mail);
      setAuthMail(mail);
      setMail("");
      setEmailSend(true);
      setModalError(null);
    } catch (e) {
      console.log(e);
      if (e.message === "Username cannot be empty") {
        e.message = t("mail_not_found");
      }
      if(e.message === "Username/client id combination not found.") {
        e.message = t("mail_not_found");
      }
      setMail("");
      setModalError(e.message);
      setEmailSend(false);
    }
  };

  const sendVerification = async () => {
    setForgotModalShown(true);
  };

  const completeReset = async () => {
    try {
      if (newPassword.length < 6) {
        throw new Error(t("under_6_err"));
      }
      if (newPassword.length > 256) {
        throw new Error(t("over_256_err"));
      }
      await Auth.forgotPasswordSubmit(authMail, verificationCode, newPassword);
      setAuthMail("");
      setModalError(null);
      setSuccessFulllyChanged(true);
      alert(t("reset_success"));
    } catch (e) {
      console.log(e);
      console.log(e.message);
      if(e.message == "Invalid verification code provided, please try again."){
        e.message = t("invalid_code_err")
      }
      setModalError(e.message);
    }
  };

  return (
    <div className="herobg">
      <div className=" main-container ">
        <div className="d-flex justify-content-between align-items-center">
          <img className="logo  text-start justify-content-start" src={logo_WORK_DESIGN_PLATFORM} alt="image" width={100} height={100} />
        </div>
        <div className="login-form col-lg-5 col-xl-5 col-md-12 col-12 py-lg-2 py-2 d-flex justify-content-start align-items-center">
          <Form>
            <div className="col-12">
              <div className="form-data" v-if="!submitted">
                {error && <h4 className="error">{error}</h4>}
                <div className="forms-inputs mt-xl-4 my-xxl-5 my-5">
                  <label
                    htmlFor="email"
                    className={`mx-3 px-2  mt-2  ${emailfocused ? "label1-focused" : "label1-notfocused"
                      } `}
                  ></label>
                  <input
                    className="rounded-2 col-12 py-3  mt-3 px-4 bg-white "
                    id="email"
                    autoComplete="off"
                    placeholder="ユーザー名"
                    type="text"
                    v-model="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onFocus={() => {
                      setpwdfocused(false);
                      setEmailFocused(true);
                    }}
                  />
                  <div className="forms-inputs ">
                    <label
                      className={`mx-3 px-2  mt-2  ${pwdfocused ? "label2-focused" : "label2-notfocused"
                        } `}
                    ></label>
                    <input
                      className="rounded-2 col-12 py-3  mt-3 px-4 bg-white "
                      autoComplete="off"
                      placeholder="パスワード"
                      type="password"
                      v-model="password"
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => {
                        setpwdfocused(true);
                        setEmailFocused(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={login}
              className="btn btn-EAC21D my-1 mt-lg-4 mt-xl-5 mt-xxl-5 op-100 w-100 fw-bold rounded-1 py-2"
              type="button"
            >
              {t("login")} <FaChevronRight />
            </button>
            <div className=" col-12 d-flex justify-content-center mt-2">
              {t("fgt_pwd")}
              <a
                onClick={sendVerification}
                className="text-decoration-none ms-1 cursor-pointer click-here-checkbox-color"
              >
                {t("clk_here")}
              </a>
            </div>
          </Form>
        </div>
      </div>
      <Modal
        show={forgotModalShown}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="forget-pass-popup"
      >
        <Modal.Header closeButton className="border-0">
          <h5 className="clr-14213D mb-0 text-center w-100">
            {!emailSend
              ? t("email_verified")
              : !successFulllyChanged
                ? t("enter_verification_received")
                : t("reset_success")}
          </h5>
        </Modal.Header>
        <Modal.Body>
          {modalError && <p className="text-danger text-center">{modalError}</p>}
          {!successFulllyChanged &&
            (!emailSend ? (
              <div className="verification-container">
                <input
                  className="col-12 py-3  mt-3 px-4 bg-white "
                  id="username"
                  autoComplete="off"
                  placeholder={t("username")}
                  type="text"
                  v-model="username"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  onFocus={() => {
                    setpwdfocused(false);
                    setEmailFocused(false);
                  }}
                />
                <button
                  type="button"
                  className="btn btn-EAC21D op-100"
                  disabled={false}
                  onClick={handleEmailSend}
                >
                  {t("send")} <FaChevronRight />
                </button>
              </div>
            ) : (
              <div className="verification-container">
                <input
                  className="col-12 py-3  mt-3 px-4 bg-white "
                  id="verification"
                  autoComplete="off"
                  placeholder={t("verification_code")}
                  type="text"
                  v-model="verification"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  onFocus={() => {
                    setpwdfocused(false);
                    setEmailFocused(false);
                  }}
                />
                <input
                  className="col-12 py-3  mt-3 px-4 bg-white "
                  id="newPassword"
                  placeholder={t("new_password")}
                  type="password"
                  v-model="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-EAC21D op-100"
                  disabled={false}
                  onClick={completeReset}
                >
                  {t("send")} <FaChevronRight />
                </button>
              </div>
            ))}
          {successFulllyChanged && (
            <div>
              <button
                type="button"
                className="btn btn-EAC21D op-100"
                disabled={false}
                onClick={onHide}
              >
                {t("back_login")} <FaChevronRight />
              </button>
            </div>
          )}
        </Modal.Body>

      </Modal>
    </div>
  );
};

export default LoginPage;

import React, { useState } from 'react';

export const useSortableData = (sortItems, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...sortItems];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                const valA = a[sortConfig.key];
                const valB = b[sortConfig.key];
                if (typeof valA === 'number' && typeof valB === 'number') {
                    return (valA - valB) * (sortConfig.direction === 'ascending' ? 1 : -1);
                } else if (typeof valA === 'string' && typeof valB === 'string') {
                    return valA.localeCompare(valB, undefined, { numeric: true }) * (sortConfig.direction === 'ascending' ? 1 : -1);
                }
                return 0;
            });
        }
        return sortableItems;
    }, [sortItems, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { sortItems: sortedItems, requestSort, sortConfig };
};
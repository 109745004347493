export const contract_plan_en = [
  { name: 'for UPDATE Premium', value: 'premium' },
  { name: 'for UPDATE Standard', value: 'standard' },
  { name: 'for UPDATE Light', value: 'light' },
  { name: 'for NEW OFFICE', value: 'programming' },
  { name: 'Free Survey', value: 'free_survey' },
];

export const contract_plan_ja = [
  { name: 'for UPDATE プレミアム', value: 'premium' },
  { name: 'for UPDATE スタンダード', value: 'standard' },
  { name: 'for UPDATE ライト', value: 'light' },
  { name: 'for NEW OFFICE', value: 'programming' },
  { name: '無料サーベイ', value: 'free_survey' },
]

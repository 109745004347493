import { ApiService } from "./api";

export class ConsultantService extends ApiService {
  createConsultant(data, file, params = {}) {
    return this.post("/consultant", data, file, params);
  }
  listConsultant(data) {
    return this.get(`/consultant?${this.serializeParams(data)}`);
  }

  getConsultantById(id) {
    return this.get(`/consultant/${id}`);
  }
  updateConsultant(id, data) {
    return this.put(`/consultant/${id}`, data);
  }
}

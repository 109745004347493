import React, { useState, useEffect, useCallback } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEdit, FaPlusCircle, FaCloudUploadAlt, FaTrashAlt } from "react-icons/fa";
import Select from "react-select"
import { Formik, Field, Form, useField, useFormikContext, ErrorMessage, FormikConsumer } from 'formik';
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { ClientService } from "../utils/services/client.service";
import { AgentService } from "../utils/services/agent.service";
import { useStore } from "../../src/Store";
import { industry_en, industry_en_2, industry_ja, industry_ja_2 } from "../utils/constants/IndustryType";
import { contract_plan_en, contract_plan_ja } from "../utils/constants/ContractPlan";
import { contract_range_en, contract_range_ja, contract_range_new_en, contract_range_new_ja } from "../utils/constants/ContractRange";
import Swal from "sweetalert2";
import Files from "react-files";
import Papa from "papaparse";
import Loader from "../containers/Loader";
import Auth from "@aws-amplify/auth";
import { useSortableData } from "../utils/useSortableData";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja'
import { ConsultantService } from "../utils/services/consultant.service";
import RoleType from "../utils/constants/RoleType";
import useCompaniesInfo from "../../src/useDataFeching";

registerLocale('ja', ja)

const dateToStr = (date) => {
  if(date==null){
    return "";
  }
  const year = date.toLocaleString('en-us', { year: 'numeric' });
  const month = date.toLocaleString('en-us', { month: '2-digit' });
  const day = date.toLocaleString('en-us', { day: '2-digit' });
  return [year, month, day].join('/');
};

const strToDate = (str, delim) => {
  var arr = str.split(delim)
  return new Date(arr[0], arr[1] - 1, arr[2]);
};

const parseAsMoment = (dateTimeStr) => {
  return moment.utc(dateTimeStr, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9)
}

const toUtcIso8601str = (momentInstance) => {
  return momentInstance
    .clone()
    .utc()
    .format('YYYY-MM-DDTHH:mm:00Z')
}

const MyDatePicker = ({ name = "" }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <div>
      <DatePicker
        {...field}
        className="w-100"
        selected={value}
        locale="ja"
        dateFormat="yyyy/MM/dd"
        placeholderText="yyyy/mm/dd"
        onChange={(date) => setValue(date)}
      />
    </div>
  );
};

const clientService = new ClientService();
const fileTypesforCsv = [".csv"];
const ClientCompany = ({
  setActiveTab,
  setBulkCompanyCreateshow,
  bulkCompanyCreateshow,
  activeTenant,
  setActiveTenant,
  activeTab,
  setActiveAgent,
  setActiveConsultants,
  setUploadError,
  setBottomError
}) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [userType, setUserType] = useState();
  const [consultants, setConsultants] = useState([]);
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const handleBulkCreateModalClose = () => setBulkCompanyCreateshow(false);
  const { tenant, setTenant } = useStore();
  const [agents, setAgents] = useState([]);
  const consultantService = new ConsultantService();
  const agentService = new AgentService();
  const { companies, setCompanies, mutate, isLoading } = useCompaniesInfo();
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [targetUserEmail, setTargetUserEmail] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const { sortItems, requestSort, sortConfig } = useSortableData(
    companies || null
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const listAgent = async (data) => {
    try {
      const response = await agentService.listAgent(data);
      setAgents(response["data"]);
    } catch (err) { }
  };

  const initialValues = {
    companyName: "",
    address: "",
    industry1: null,
    industry2: null,
    plan: null,
    contractRange: null,
    startAt: null,
    sendmailAt: null,
    expireAt: null,
    personInCharge: "",
    email: "",
    apiRemainingTimes: 3,
    logoUrl: "",
    clientId: ""
  };

  useEffect(() => {
    if (activeTab && activeTenant.length) {
      listCompany({ filter_tenant: [activeTenant] })
    } else {
      listCompany()
    }
  }, [activeTenant, activeTab]);

  useEffect(() => {
    if (activeTab == 'client_company') {
      setActiveAgent("")
      setActiveConsultants("")
      listAgent()
    }
  }, [activeTab]);

  const validationSchema = Yup.object({
    agentName: Yup.string().required(t("agent_name_is_required")).typeError(t("invalid_type")),
    companyName: Yup.string().required(t("company_name_is_required")).typeError(t("invalid_type")),
    address: Yup.string().required(t("address_is_required")).typeError(t("invalid_type")),
    industry1: Yup.string().required(t("industry1_is_required")).typeError(t("invalid_type")),
    industry2: Yup.array().min(1, t("industry2_is_required")).typeError(t("invalid_type")),
    plan: Yup.string().required(t("plan_is_required")).typeError(t("invalid_type")),
    contractRange: Yup.string().required(t("contractRange_is_required")).typeError(t("invalid_type")),
    startAt: Yup.string().required(t("start_at_is_required")).typeError(t("invalid_type")),
    sendmailAt: Yup.string().typeError(t("invalid_type")),
    expireAt: Yup.string().required(t("expire_at_is_required")).typeError(t("invalid_type")),
    personInCharge: Yup.string().required(t("PIC_is_required")).typeError(t("invalid_type")),
    email: Yup.string().email(t("valid_email_is_required")).required(t("email_is_required")).typeError(t("invalid_type")),
    apiRemainingTimes: Yup.number().required(t("API_remaining_time_is_required")).positive().integer().typeError(t("invalid_type")),
  });

  const addCompanyModal = async () => {
    setShow(true);
    setFormValues(initialValues);
    setIsEdit(false);
  };


  const onSubmit = async (e) => {
    e["groupNamePrefix"] = "Reader";
    e["action"] = "CREATE";
    const selectedAgent = agents.find((ele) => ele.agentName === e.agentName);
    if (!selectedAgent) {
      Swal.fire({
        icon: "error",
        text: t("agent_not_found"),
      });
      throw new Error(t("agent_not_found"));
    } else {
      e["agentId"] = selectedAgent.agentId
      clientService.createCompany(e)
      Swal.fire({
        title: t("new_tenant_request_registered"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
      setShow(false);
    }
  };

  const updateCompany = async (e) => {
    const selectedAgent = agents.find((ele) => e.agentName === ele.agentName);
    if (!selectedAgent) {
      Swal.fire({
        icon: "error",
        text: t("agent_not_found"),
      });
      throw new Error(t("agent_not_found"));
    } else {
      e["agentId"] = selectedAgent.agentId
      e["username"] = targetUserEmail
      e["tenant"] = formValues.tenantId
      e["groupNamePrefix"] = "Reader"
      e["action"] = "UPDATE";
      try {
        const response = await clientService.createCompany(e);
        if (response) {
          listCompany();
          setCompanies(
            companies.map((item) =>
              item.tenantId === e.tenantId ? { ...item } : item
            )
          );
          handleClose(true);
        }
        setBottomError("");
        Swal.fire({
          title: t("client_company_updated"),
          confirmButtonColor: "#D57B85",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then(() => {
          window.location.reload();
        });
        
      } catch (err) {
        if (err.response && err.response.status === 403) {
          setBottomError(t("update_user_not_exit"));
        } else if(err.response && err.response.status === 503) {
          setBottomError(t("resource_error"));
        } else {
          setBottomError(t("unexcepted_error"));
        }
      }
    }
  };

  const postCsv = async () => {
    try {
      parsedData.forEach((c) => {
        if (c) {
          onSubmit(c);
        }
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: t("csv_request_failed"),
      });
    }
  };

  const listCompany = async (data, filter_tenant) => {
    setIsLoadingTableData(true)

    try {
      let companies_filtered
      if (data) {
        const response = await clientService.listCompany(data);
        companies_filtered = response.data
      } else {
        const response = await clientService.listCompany();
        companies_filtered = response.data
      }
      if (companies_filtered.length == 0) {
        setBottomError(t("serch_user_result_empty"));
      }
      const new_data = companies_filtered.map((c) => {
        return { ...c, startAt: strToDate(c.startAt, '/'), expireAt: strToDate(c.expireAt, '/'), sendmailAt: c.sendmailAt ? strToDate(c.sendmailAt, '/') : null }
      })
      setCompanies(new_data);
      setBottomError("");
    } catch (err) { 
      console.log(err);
      if (err.response && err.response.status === 404) {
        setCompanies([])
        setBottomError(t("serch_user_result_empty"));
      } else if(err.response && err.response.status === 503) {
        setCompanies([])
        setBottomError(t("resource_error"));
      } else {
        setCompanies([])
        setBottomError(t("unexcepted_error"));
      }
    } finally {
      setIsLoadingTableData(false)
    }
  };


  const listConsultant = async (data) => {
    try {
      const response = await consultantService.listConsultant(data);
      if (response.data) {
        setConsultants(response["data"]);
      }
    } catch (err) { console.log(err) }
  };

  const confirmDelete = (row) => {
    Swal.fire({
      title: t("are_u_Sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D57B85",
      cancelButtonColor: "#66AB79",
      confirmButtonText: "削除する",
      cancelButtonText: "キャンセル",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(row);
      }
    });
  };
  const onDelete = async (row) => {
    try {
      const deleted = await clientService.deleteCompany(row.tenantId);
      Swal.fire({
        title: t("user_deleted_successfully"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    } catch {
      Swal.fire({
        title: "削除対象のユーザーが見つかりませんでした",
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    }
    listCompany();
  };

  const editCompanies = async (e) => {
    const selectedCompany = companies.find((c) => c.tenantId === e.tenantId);
    const selectedAgent = agents.find((ele) => ele.agentId === selectedCompany.agentId);
    setTargetUserEmail(e.email);
    if (selectedAgent) {
      setFormValues({ ...selectedCompany, agentName: selectedAgent.agentName });
    } else {
      setFormValues({ ...selectedCompany });
    }
    setIsEdit(true);
    setShow(true);
  };

  const changeLang = (val) => {
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };

  useEffect(() => {
    if (i18n.language) {
      changeLang(i18n.language);
    }
  }, [i18n.language]);

  const styles = {
    height: "26px",
  };

  useEffect(() => {
    if (activeTab == 'client_company') {
      listConsultant();
    }
  }, [activeTab]);

  useEffect(() => {
    (async () => {
      const data = await Auth.currentUserInfo();
      if (data) {
        setUserType(data.attributes["custom:role"]);
        setUserName(data["username"]);
        setEmail(data.attributes["email"]);
      }
    })();
  }, [userType, userName, email]);

  const onFilesChange = (files) => {
    if (files && files[0].type === "text/csv") {
      try {
        let uploadError = [];
        let emails = [];
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (results.data.length === 0) {
              Swal.fire({
                icon: "error",
                text: t("none_data_csv"),
              });
              return;
            }
            setParsedData(
              results.data.map((c, index) => {
                if (!(c.companyName)) {
                  uploadError.push({ type: "required", index: index, item: "company_name", column: "companyName" })
                }
                if (!(c.address)) {
                  uploadError.push({ type: "required", index: index, item: "address", column: "address" })
                }
                if (!(c.industry1)) {
                  uploadError.push({ type: "required", index: index, item: "industry1", column: "industry1" })
                }
                if (!(c.industry2)) {
                  uploadError.push({ type: "required", index: index, item: "industry2", column: "industry2" })
                }
                if (!(c.plan)) {
                  uploadError.push({ type: "required", index: index, item: "plan", column: "plan" })
                }
                if (!(c.contractRange)) {
                  uploadError.push({ type: "required", index: index, item: "contract_range", column: "contractRange" })
                }
                if (!(c.startAt)) {
                  uploadError.push({ type: "required", index: index, item: "start_at", column: "startAt" })
                }
                if (!(c.expireAt)) {
                  uploadError.push({ type: "required", index: index, item: "expire_at", column: "expireAt" })
                }
                if (!(c.personInCharge)) {
                  uploadError.push({ type: "required", index: index, item: "person_in_charge", column: "personInCharge" })
                }
                c.email = c.email.trim()
                if (!(c.email)) {
                  uploadError.push({ type: "required", index: index, item: "email", column: "email" })
                }
                const emailIndex = emails.findIndex((e) => e === c.email)
                if (c.email && emailIndex >= 0) {
                  uploadError.push({ type: "duplicated", index1: emailIndex, index2: index })
                }
                emails.push(c.email)
                if (!(c.apiRemainingTimes)) {
                  uploadError.push({ type: "required", index: index, item: "apiRemainingTimes", column: "apiRemainingTimes" })
                }
                if (uploadError.length > 0) {
                  Swal.fire({
                    icon: "error",
                    text: t("invalid_csv"),
                  });
                  return;
                }
                return c;
              })
            );
            setUploadError(uploadError);
          },
        });
        setFiles(files);
      } catch (e) {
        Swal.fire({
          icon: "error",
          text: t("agent_not_found"),
        });
      }
    }
  };

  const onFilesError = (error, file) => {
    setError(error.message);
  };

  const handleSearch = (event) => {
    if (searchValue.trim() == "") {
      listCompany()
    } else {
      listCompany({ search: searchValue.trim(), filter_tenant: [activeTenant] })
    }
  };

  const handleActiveTabConsultant = async (tenantId) => {
    const belonging_consultants = consultants.filter((consultant) => {
      return consultant.clients.includes(tenantId)
    })
    setActiveConsultants(belonging_consultants.map((consultant) => consultant.consultantId))
    setActiveTab("consultants")
  }

  return (
    <>
      <div className="col-12 px-2">
        <div>
          <div className="d-flex align-items-center justify-content-between ">
            <label htmlFor="search" className="text-dark mb-2 d-flex align-items-center">
              <input
                id="search"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="form-control rounded-0 mr-2"
                placeholder=""
              />
              <button className="btn btn-14213D w-50 m-3" onClick={handleSearch}>
                {t("search_data")}
              </button>
            </label>
            <div className="d-flex">
              <button
                className="btn add-btn op-100"
                onClick={addCompanyModal}
              >
                <FaPlusCircle className="align-text-middle" /> {t("add_new_tenant")}{" "}
              </button>
            </div>
          </div>
          {isLoading || isLoadingTableData ? (
            <Loader></Loader>
          ) : sortItems ? (
            <Card className="border-0">
              <Card.Body className="card-body-height p-0 m-2">
                <Card.Text className=" px-2 ">
                  <table className="table table-striped clr-14213D w-auto ">
                    <thead>
                      <tr>
                        <th scope="col">
                          <p className="m-2">No.</p>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("companyName")}
                            className={`${getClassNamesFor("companyName")} btn`}
                          >
                            {t("company_name")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("address")}
                            className={`${getClassNamesFor("address")} btn`}
                          >
                            {t("address")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("num_of_acc")}
                            className={`${getClassNamesFor("num_of_acc")} btn`}
                          >
                            {t("num_of_acc")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("personInCharge")}
                            className={`${getClassNamesFor("personInCharge")} btn`}
                          >
                            {t("person_in_charge")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("email")}
                            className={`${getClassNamesFor("email")} btn`}
                          >
                            {t("email")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("plan")}
                            className={`${getClassNamesFor("plan")} btn`}
                          >
                            {t("plan")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("startAt")}
                            className={`${getClassNamesFor("startAt")} btn`}
                          >
                            {t("start_at")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("sendmailAt")}
                            className={`${getClassNamesFor("sendmailAt")} btn`}
                          >
                            {t("sendmail_at")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("expireAt")}
                            className={`${getClassNamesFor("expireAt")} btn`}
                          >
                            {t("expire_at")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("apiRemainingTimes")}
                            className={`${getClassNamesFor("apiRemainingTimes")} btn`}
                          >
                            {t("apiRemainingTimes")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("client")}
                            className={`${getClassNamesFor("client")} btn`}
                          >
                            {t("consultant_in_charge")}
                          </button>
                        </th>
                        {(userType === RoleType.ADMIN || userType === RoleType.AGENT) && (
                          <th scope="col" className="col-2">
                            <p className="m-2"> {t("action")}</p>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {sortItems &&
                        sortItems.map((row, i) => (
                          <tr key={row.tenantId}>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {i + 1}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {row?.companyName}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {row?.address}{" "}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {row?.num_of_acc}{" "}
                            </td>

                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {row?.personInCharge}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {row?.email}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {row?.plan}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {dateToStr(row?.startAt)}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {row?.sendmailAt ? dateToStr(row.sendmailAt) : "未設定"}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {dateToStr(row?.expireAt)}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info" : ""}
                            >
                              {row?.apiRemainingTimes}
                            </td>
                            <td
                              className={tenant === row.tenantId ? "bg-info btn-contain" : "btn-contain"}
                            >
                              <button
                                className="btn btn-14213D"
                                onClick={() => handleActiveTabConsultant(row.tenantId)}
                              >
                                {t("list")}
                              </button>
                            </td>
                            {(userType === RoleType.ADMIN || userType === RoleType.AGENT) && (
                              <td className="bg-transparent p-0 d-flex w-100">
                                <button
                                  className="btn btn-green op-100 me-2 rounded-0"
                                  onClick={() => {
                                    editCompanies(row);
                                  }}
                                >
                                  <FaEdit className="align-text-middle" />{" "}
                                  <span className="pt-1">{t("edit")} </span>
                                </button>
                                <button
                                  className="btn btn-E65C4F rounded-0"
                                  onClick={() => {
                                    confirmDelete(row);
                                  }}
                                >
                                  <FaTrashAlt /> <span></span>
                                  <span className="pt-1">{t("delete")} </span>
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <h4 className="clr-14213D text-center">{t("no_data_available")}</h4>
          )}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-agent-popup"
      >
        <Modal.Header closeButton>
          <h5 className="clr-14213D mb-0 ">
            <b> {isEdit ? t("edit") : t("add_new_tenant")}</b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              validateOnMount={true}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form className="text-body" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="agentName"
                      >
                        <b> {t("agent_name")}</b>
                      </label>
                      <Field
                        as="select"
                        name="agentName"
                        className="w-100 select-class"
                        id="agentName"
                        defaultValue={isEdit ? formValues.agentName : "DEFAULT"}
                        onChange={(e) =>
                          formik.setFieldValue("agentName", e.target.value)
                        }
                      >
                        <option >
                          {t("select_option")}
                        </option>
                        {agents && agents.map((item) => {
                          return <option
                            key={item.agentName}
                            value={item.agentName}
                          >
                            {item.agentName}
                          </option>
                        })}
                      </Field>
                      <div className="text-danger mb-1">
                        <ErrorMessage
                          name="agentName"
                          className="text-danger"
                        />
                      </div>
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="name"
                      >
                        <b> {t("company_name")}</b>
                      </label>
                      <Field
                        type="text"
                        id="companyName"
                        name="companyName"
                        className="w-100 "
                      />
                      <div className="text-danger mb-1 ">
                        <ErrorMessage
                          name="companyName"
                          className="text-danger"
                        />
                      </div>
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="clientId"
                      >
                        <b> {t("client_id")}</b>
                      </label>
                      <Field
                        type="text"
                        id="clientId"
                        name="clientId"
                        className="w-100 "
                      />
                      <div className="text-danger mb-1">
                        <ErrorMessage name="clientId" className="text-danger" />
                      </div>
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="address"
                      >
                        <b> {t("address")}</b>
                      </label>
                      <Field
                        type="text"
                        id="address"
                        name="address"
                        className="w-100 "
                      />
                      <div className="text-danger mb-1">
                        <ErrorMessage name="address" className="text-danger" />
                      </div>
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="industry1"
                      >
                        <b> {t("industry1")}</b>
                      </label>
                      <Field
                        as="select"
                        name="industry1"
                        className="w-100 select-class"
                        id="inputGroupSelect02"
                        defaultValue={"DEFAULT"}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "industry1",
                            e.target.value || null
                          )
                        }
                        }
                      >
                        <option value="DEFAULT" selected disabled>
                          {t("select_option")}
                        </option>
                        {i18n.language === "en" ? (
                          <>
                            {industry_ja_2.map((e) => e.industry1)
                              .filter((value, index, array) => array.indexOf(value) === index)
                              .map((e) => (
                                <option key={e} value={e}>
                                  {e}
                                </option>))
                            }
                          </>
                        ) : (
                          <>
                            {industry_ja_2.map((e) => e.industry1)
                              .filter((value, index, array) => array.indexOf(value) === index)
                              .map((e) => (
                                <option key={e} value={e}>
                                  {e}
                                </option>))
                            }
                          </>
                        )}
                      </Field>
                      <div className="text-danger mb-1">
                        <ErrorMessage name="industry1" className="text-danger" />
                      </div>


                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="industry2"
                      >
                        <b> {t("industry2")}</b>
                      </label>

                      {formik.values.industry1 ? (
                        <>

                          {i18n.language === "en" ? (
                            <>
                              {industry_en_2.filter(industry => industry.industry1 === formik.values.industry1).map((element, index) => (
                                <div className="w-100">
                                  <Field
                                    type="checkbox"
                                    name="industry2"
                                    value={element.industry2}
                                  />
                                  <span className="ms-2">
                                    {" "}
                                    {element.industry2}
                                  </span>
                                </div>
                              ))}
                            </>
                          ) : (
                            <>{industry_ja_2.filter(industry => industry.industry1 === formik.values.industry1).map((element, index) => (
                              <div className="w-100">
                                <Field
                                  type="checkbox"
                                  name="industry2"
                                  value={element.industry2}
                                />
                                <span className="ms-2">
                                  {" "}
                                  {element.industry2}
                                </span>
                              </div>
                            ))}
                            </>
                          )
                          }
                        </>
                      ) : (
                        <>
                          <div className="mb-3">
                            {t("select_industry1")}
                          </div>
                        </>
                      )}

                      {/* Contract Plan */}
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="plan"
                      >
                        <b> {t("plan")}</b>
                      </label>
                      <Field
                        as="select"
                        id="plan"
                        name="plan"
                        className="w-100 "
                        onChange={(e) => {
                          formik.setFieldValue(
                            "plan",
                            e.target.value || null
                          )
                        }
                        }
                      >
                        <option value="DEFAULT" selected disabled>
                          {t("select_option")}
                        </option>
                        {i18n.language === "en" ? (
                          <>
                            {
                              contract_plan_en.map((plan, index) => (
                                <option value={plan.name} key={index}>{plan.value}</option>
                              ))
                            }
                          </>
                        ) : (
                          <>
                            {
                              contract_plan_ja.map((plan, index) => (
                                <option value={plan.value} key={index}>{plan.name}</option>
                              ))
                            }
                          </>
                        )}
                      </Field>
                      <div className="text-danger mb-1">
                        <ErrorMessage name="plan" className="text-danger" />
                      </div>



                      {/* Contract contract_range */}

                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="contractRange"
                      >
                        <b> {t("contract_range")}</b>
                      </label>

                      {formik.values.plan && formik.values.plan !== "programming" ? (
                        <>
                          <Field
                            as="select"
                            id="contractRange"
                            name="contractRange"
                            className="w-100 "
                          >
                            <option value="DEFAULT" selected disabled>
                              {t("select_option")}
                            </option>
                            {i18n.language === "en" ? (
                              <>
                                {
                                  contract_range_en.map((contract_range, index) => (
                                    <option value={contract_range.name} key={index}>{contract_range.value}</option>
                                  ))
                                }
                              </>
                            ) : (
                              <>
                                {
                                  contract_range_ja.map((contract_range, index) => (
                                    <option value={contract_range.value} key={index}>{contract_range.name}</option>
                                  ))
                                }
                              </>
                            )}
                          </Field>
                          <div className="text-danger mb-1">
                            <ErrorMessage name="contract_range" className="text-danger" />
                          </div>
                        </>
                      ) : formik.values.plan === "programming" ? (
                        <>
                          <Field
                            as="select"
                            id="contractRange"
                            name="contractRange"
                            className="w-100 "
                          >
                            <option value="DEFAULT" selected disabled>
                              {t("select_option")}
                            </option>
                            {i18n.language === "en" ? (
                              <>
                                {
                                  contract_range_new_en.map((contract_range, index) => (
                                    <option value={contract_range.name} key={index}>{contract_range.value}</option>
                                  ))
                                }
                              </>
                            ) : (
                              <>
                                {
                                  contract_range_new_ja.map((contract_range, index) => (
                                    <option value={contract_range.value} key={index}>{contract_range.name}</option>
                                  ))
                                }
                              </>
                            )}
                          </Field>
                          <div className="text-danger mb-1">
                            <ErrorMessage name="contractRange" className="text-danger" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-3">
                            {t("select_contractrange")}
                          </div>
                        </>
                      )}


                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="startAt"
                      >
                        <b> {t("start_at")}</b>
                      </label>
                      <MyDatePicker name="startAt" />
                      <div className="text-danger mb-1">
                        <ErrorMessage name="startAt" className="text-danger" />
                      </div>

                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="sendmailAt"
                      >
                        <b> {t("sendmail_at")}</b>
                      </label>
                      <MyDatePicker name="sendmailAt" />
                      <div className="text-danger mb-1">
                        <ErrorMessage name="sendmailAt" className="text-danger" />
                      </div>
                      
                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="expireAt"
                      >
                        <b> {t("expire_at")}</b>
                      </label>
                      <MyDatePicker name="expireAt" />
                      <div className="text-danger mb-1">
                        <ErrorMessage name="expireAt" className="text-danger" />
                      </div>

                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="personInCharge"
                      >
                        <b> {t("person_in_charge")}</b>
                      </label>
                      <Field
                        type="text"
                        id="personInCharge"
                        name="personInCharge"
                        className="w-100 "
                      />
                      <div className="text-danger mb-1 ">
                        <ErrorMessage
                          name="personInCharge"
                          className="text-danger"
                        />
                      </div>

                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="email"
                      >
                        <b> {t("email")}</b>
                      </label>
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        className="w-100 "
                      />
                      <div className="text-danger mb-1 ">
                        <ErrorMessage
                          name="email"
                          className="text-danger"
                        />
                      </div>

                      <label
                        type="text"
                        className="clr-14213D fnt-14"
                        htmlFor="apiRemainingTimes"
                      >
                        <b> {t("apiRemainingTimes")}</b>
                      </label>
                      <Field
                        type="text"
                        id="apiRemainingTimes"
                        name="apiRemainingTimes"
                        className="w-100 "
                      />
                      <div className="text-danger mb-1 ">
                        <ErrorMessage
                          name="apiRemainingTimes"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-end pt-2">
                      <button
                        type="button"
                        className="btn btn-E65C4F  op-100"
                        onClick={() => setShow(false)}
                      >
                        {t("cancel")}
                      </button>
                      {isEdit ? (
                        <button
                          type="button"
                          className="btn btn-green ms-3 op-100"
                          disabled={!formik.isValid || !formik.dirty}
                          onClick={() => updateCompany(formik.values)}
                        >
                          {t("update")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-green ms-3 op-100"
                          disabled={!formik.isValid || !formik.dirty}
                          onClick={() => onSubmit(formik.values)}
                        >
                          {t("save")}
                        </button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={bulkCompanyCreateshow}
        onHide={handleBulkCreateModalClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h5 className="clr-14213D mb-0 ">{t("upload_csv")}</h5>
        </Modal.Header>
        <Modal.Body className="pb-lg-5 pt-1">
          <div className="mb-4  ">
            <div className="d-flex justify-content-center">
              <div>
                <a
                  href="https://wdp-dev-bucket-tool-zone.s3.ap-northeast-1.amazonaws.com/templates/csv/client_sample.csv"
                  className="clr-14213D fnt-14"
                >
                  {t("downloadSample")}
                </a>
              </div>
            </div>

            <div className="">
              <Files
                className="d-flex files-dropzone w-75 mx-auto flex-column align-items-center justify-content-center"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={fileTypesforCsv}
                multiple={false}
                name="files"
              >
                <h6 className="mb-4">{t("drag_drop")}</h6>
                <FaCloudUploadAlt size={60} />
                <h6 className="mt-3 mb-0">{t("select_file")}</h6>
              </Files>
            </div>

            <div className="d-flex justify-content-center">
              {files && files.length
                ? files.map((ele) => {
                  return (
                    <div className="pb-2 pt-1 ps-3 text-black-50" key={ele.id}>
                      {ele.name}
                    </div>
                  );
                })
                : ""}
            </div>
            {error ? <div className="text-danger">{error}</div> : ""}
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn  btn-green   mt-2 op-100 "
                disabled={!(files &&
                  files[0]?.type === "text/csv" &&
                  parsedData.filter((d) => !!d).length)}
                onClick={postCsv}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ClientCompany;

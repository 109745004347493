import React, { useState, useEffect, useCallback } from "react";
import Layout from "./layout/Layout";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";

const NewPassword = () => {
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");
  const [cnfPwd, setCnfPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [error, setError] = useState(null);
  const [currentPwd, setCurrentPwd] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const userData = await Auth.currentUserInfo();
        setUsername(userData["username"])
        setUserId(userData["username"])
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const changepwd = async (data) => {
    if (cnfPwd === newPwd) {
      let res;
      try { 
        const currentUser = await Auth.signIn(username, currentPwd); 
        res = await Auth.changePassword(currentUser, currentPwd, newPwd);
      }catch (e) {
        let errMessage;
        console.log("e:" + JSON.stringify(e))
        if(e.code === "UserNotFoundException"){
          errMessage = t("auth_error_UserNotFoundException");
        }else if(e.code === "InvalidParameterException"){
          errMessage = t("auth_error_InvalidParameterException");
        }else if(e.code === "NotAuthorizedException" && e.message !== "Password attempts exceeded"){
          errMessage = t("auth_error_NotAuthorizedException");
        }else if(e.code === "UserNotConfirmedException"){
          errMessage = t("auth_error_UserNotConfirmedException");
        }else if(e.code === "LimitExceededException" || e.message === "Password attempts exceeded"){
          errMessage = t("auth_error_LimitExceededException");
        }else if(e.code === "InvalidPasswordException"){
          errMessage = t("auth_error_InvalidPasswordException");
        }else{
          errMessage =  e.message;
        }
        Swal.fire({
          title: errMessage,
          confirmButtonColor: "#D57B85",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        })
      } finally {
        if(res){
          Swal.fire({
            title: t("password_updated"),
            confirmButtonColor: "#D57B85",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          })
        }
      }

    }
    else {
      Swal.fire({
        title: t("password_mismatched"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    }
  };
  return (
    <Layout>
      <div className="herobg child-layout ">
        <div className="pass-headings px-3">
          <h4>{t("account_setting")}</h4>
          <p>{t("hello")}, <span>{username}</span></p>
        </div>
        <div className=" main-container ">
          <div className="login-form col-lg-4 offset-lg-3 ">
            <h5>{t("set_new_password")}</h5>
            <Form>
              <div className="col-12">
                <div className="form-data" v-if="!submitted">
                  {error && <h4 className="error">{error}</h4>}
                  <div className="forms-inputs">
                    <input
                      className="rounded-2 col-12 py-3 mb-xl-4 px-3 bg-white"
                      id="userId"
                      autoComplete="new-password"
                      placeholder={t("UserId")}
                      type="text"
                      value={userId}
                      disabled="true"
                    />
                  </div>
                  <div className="forms-inputs">
                    <input
                      className="rounded-2 col-12 py-3 mb-xl-4 px-3 bg-white"
                      id="currentPwd"
                      autoComplete="new-password"
                      placeholder={t("current_password")}
                      type="password"
                      value={currentPwd}
                      onChange={(e) => setCurrentPwd(e.target.value)}
                    />
                  </div>
                  <div className="forms-inputs">
                    <input
                      className="rounded-2 col-12 py-3 mb-xl-4 px-3 bg-white"
                      id="newPwd"
                      autoComplete="new-password"
                      placeholder={t("new_password")}
                      type="password"
                      value={newPwd}
                      onChange={(e) => setNewPwd(e.target.value)}
                    />
                  </div>
                  <div className="forms-inputs ">
                    <input
                      className="rounded-2 col-12 py-3 mb-xl-4 px-3 bg-white"
                      autoComplete="off"
                      placeholder={t("cnf_password")}
                      type="password"
                      value={cnfPwd}
                      onChange={(e) => setCnfPwd(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <button
                onClick={changepwd}
                className="btn btn-EAC21D my-1 op-100 w-100"
                type="button"
              >
                {t("update")}
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default NewPassword;

import React, { useState, useEffect, useLayoutEffect } from "react";
import { FaAngleRight, FaCloudUploadAlt } from "react-icons/fa";
import { saveAs } from "file-saver";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import "./shared/Fileupload/Analysis.scss";
import { useStore } from "../Store";
import { AnalysisService } from "../utils/services/analysis.service";

import { FileService } from "../utils/services/file.service";
import { set } from "lodash";

const analysisService = new AnalysisService();


const CsvAccessLogSettingModal = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();

  const [Exceldata, setExceldata] = useState(false);


  const [weekdays, setWeekDays] = useState([]);
  const [timeslots, setTimeSlots] = useState([]);
  const [holidays, setHolidays] = useState([]);

  const [inputFileMetaInfo, setInputFileMetaInfo] = useState([]);

  const [columnUserId, setColumnUserId] = useState(null);
  const [columnGate, setColumnGate] = useState(null);
  const [columnEnterExit, setColumnEnterExit] = useState(null);
  const [columnTimestamp, setColumnTimestamp] = useState(null);
  const [timestampFormat, setTimestampFormat] = useState(null);
  const [valuesGate, setValuesGate] = useState([]);
  const [valuesEnterExit, setValuesEnterExit] = useState([]);
  const [valuesGateTarget, setValuesGateTarget] = useState([]);
  const [valuesEnterTarget, setValuesEnterTarget] = useState([]);
  const [valuesExitTarget, setValuesExitTarget] = useState([]);


  const [selectedOptionImportData, setSelectedOptionImportData] = useState(null);

  const { tenant } = useStore();

  const time = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const holidayCount = [1, 2, 3, 4, 5, 6, 7]
  const value = ["Value1", "Value2", "Value3", "Value4", "Value5", "Value6"]
  const testTable = [1]



  const fileService = new FileService();


  const handleExceldataclose = () => setExceldata(false);

  useEffect(() => {
    getInputFileMetaInfoOperationAnalysis()
    getInputSettingOperationAnalysis()

  }, []);

  const getInputFileMetaInfoOperationAnalysis = async () => {
    const response = await analysisService.getInputFileMetaInfoOperationAnalysis(tenant, "specify_method_name_here")
    if (response) {
      setInputFileMetaInfo(response.data);
    }
  };

  const downloadInputFileOperationAnalyis = async (key) => {
    const {
      data: { url: downloadUrl },
    } = await fileService.getDownloadUrl(key);
    saveAs(downloadUrl, key);
  }

  const deleteInputFileOperationAnalyis = async (key) => {
    const response = await fileService.deleteFile(key);
    if (response) {
      getInputFileMetaInfoOperationAnalysis()
    }
  };

  const getInputSettingOperationAnalysis = async () => {
    const response = await analysisService.getInputSettingOperationAnalysis(tenant, "csvAccessLog")
    if (response.data) {
      setColumnUserId(response.data.column_userId)
      setColumnTimestamp(response.data.column_timestamp)
      setColumnGate(response.data.column_target_gates)
      setColumnEnterExit(response.data.column_enter_exit)
      setTimestampFormat(response.data.timestamp_format)
      getColumnValuesGate(response.data.column_target_gates)
      getColumnValuesEnterExit(response.data.column_enter_exit)
      setValuesGateTarget(response.data.target_gates)
      setValuesEnterTarget(response.data.enter_values)
      setValuesExitTarget(response.data.exit_values)
    }
  }

  const setResourceOperationAnalysis = async () => {
    const response = await analysisService.setResourceOperationAnalysis(tenant)
  }

  const postInputSettingOperationAnalysis = async () => {
    const setting = {
      "column_userId": columnUserId,
      "column_timestamp": columnTimestamp,
      "timestamp_format": timestampFormat,
      "column_target_gates": columnGate,
      "target_gates": valuesGateTarget,
      // "has_enter_exit_flag":
      "column_enter_exit": columnEnterExit,
      "enter_values": valuesEnterTarget,
      "exit_values": valuesExitTarget
    }

    console.log(setting)

    const response = await analysisService.postInputSettingOperationAnalysis(tenant, "csvAccessLog", setting)
    if (response) {
      getInputFileMetaInfoOperationAnalysis()
      getInputSettingOperationAnalysis()
      setResourceOperationAnalysis()
    }
    Swal.fire({
      title: t("setting_updated"),
      confirmButtonColor: "#D57B85",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    })
  };

  const getColumnValuesGate = async (column) => {
    const response = await analysisService.getColumnValuesOperationAnalysis(tenant, column)
    if (response.data) {
      setValuesGate(response.data["columns-values"]);
    }
  }

  const getColumnValuesEnterExit = async (column) => {
    const response = await analysisService.getColumnValuesOperationAnalysis(tenant, column)
    if (response.data) {
      setValuesEnterExit(response.data["columns-values"]);
    }
  }

  const handleUserIdColumnChange = (e) => {
    setColumnUserId(e.target.value);
  }

  const handleGateColumnChange = (e) => {
    setColumnGate(e.target.value);
  }

  const handleEnterExitColumnChange = (e) => {
    setColumnEnterExit(e.target.value);
  }

  const handleTimestampColumnChange = (e) => {
    setColumnTimestamp(e.target.value);
  }

  const handleTimestampFormatChange = (e) => {
    setTimestampFormat(e.target.value);
  }

  const handleGateValuesChange = (e) => {
    if (e.target.checked) {
      setValuesGateTarget([...valuesGateTarget, e.target.value])
    } else {
      setValuesGateTarget(valuesGateTarget.filter((item) => item !== e.target.value))
    }
  };

  const handleEnterValuesChange = (e) => {
    if (e.target.checked) {
      setValuesEnterTarget([...valuesEnterTarget, e.target.value])
    } else {
      setValuesEnterTarget(valuesEnterTarget.filter((item) => item !== e.target.value))
    }
  };

  const handleExitValuesChange = (e) => {
    if (e.target.checked) {
      setValuesExitTarget([...valuesExitTarget, e.target.value])
    } else {
      setValuesExitTarget(valuesExitTarget.filter((item) => item !== e.target.value))
    }
  };

  return (
    <Modal
      className="scrollbar-c9c9ca operational-setting-sub-modal"
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <h4 className="clr-14213D mb-0 "><b>{t("excelData")}</b></h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column clr-14213D operational-analysis-setting-tables" >
          <div id="operational-table">
            <table className="table clr-14213D w-100 ">
              <thead>
                <tr>
                  <th scope="col" style={{ height: "20%" }} className="d-flex justify-content-center">
                    <button
                      type="button"
                      className={` btn`}
                    >
                      {t("fileName")}
                    </button>
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    <button
                      type="button"
                      className={` btn`}
                    >
                      {t("uploadDate")}
                    </button>
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    <button
                      type="button"
                      className={` btn`}
                    >
                      {t("action")}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody  >
                {inputFileMetaInfo.files && inputFileMetaInfo.files.length > 0 && inputFileMetaInfo.files.map((item) => {
                  return <tr>
                    <td style={{ "padding-left": "5px" }}>
                      <div style={{ "height": "20px", "overflow": "hidden" }}>
                        {item.key.split('/').pop()}
                      </div>
                    </td>
                    <td style={{ "padding-left": "5px" }}>
                      <div style={{ "height": "20px", "overflow": "hidden" }}>
                        {item.updatedAt}
                      </div>
                    </td>
                    <td className="bg-transparent p-0 d-flex w-100">
                      <div>
                        <button style={{ width: "60%" }}
                          className="btn btn-green op-100 me-2 rounded-0"
                          onClick={() => downloadInputFileOperationAnalyis(item.key)}
                        >
                          <span className="pt-1">{t("DL")} </span>
                        </button>
                        <button style={{ width: "60%" }}
                          className="btn btn-E65C4F rounded-0"
                          onClick={() => deleteInputFileOperationAnalyis(item.key)}
                        >
                          <span className="pt-1">{t("delete")} </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
          <table class="table-borderless text-center csvlistTable">
            <thead>
              <tr className="bg-clrded " >
                <th scope="col" style={{ width: "25%" }} >{t("items")}</th>
                <th scope="col" style={{ width: "35%" }}>{t("csvList")}</th>
                <th scope="col">{t("Setting")} </th>
              </tr>
            </thead>
            <tbody>
              <div className="mt-2"></div>
              <tr className="p-5">
                <td>{t("userID")}</td>
                <td>
                  <select className="w-100 m-auto ps-2 csvSelect py-2" onChange={handleUserIdColumnChange}>
                    <option>{t("csvList")}</option>
                    {inputFileMetaInfo.columns && inputFileMetaInfo.columns.length > 0 && inputFileMetaInfo.columns.map((item, index) => {
                      return <option
                        key={index}
                        value={item}
                        selected={columnUserId === item}
                      >
                        {item}
                      </option>
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <td>{t("time")}</td>
                <td>
                  <select className="w-100 m-auto ps-2 csvSelect py-2" onChange={handleTimestampColumnChange}>
                    <option>{t("csvList")}</option>
                    {inputFileMetaInfo.columns && inputFileMetaInfo.columns.length > 0 && inputFileMetaInfo.columns.map((item, index) => {
                      return <option
                        key={index}
                        value={item}
                        selected={columnTimestamp === item}
                      >
                        {item}
                      </option>
                    })}
                  </select>
                </td>
                <td className="pt-3">
                  <input style={{ position: "relative", bottom: "10px" }}
                    onChange={handleTimestampFormatChange}
                    value={timestampFormat}
                    name="date"
                    className="ps-1 select-class"
                    id="date"
                    placeholder="%Y/%m/%d %H:%M:%S"
                  />
                </td>
              </tr>
              <tr>
                <td>{t("gate")}</td>
                <td>
                  <select
                    className="w-100 m-auto ps-2 csvSelect py-2"
                    onChange={(e) => { getColumnValuesGate(e.target.value); handleGateColumnChange(e) }}
                  >
                    <option>{t("csvList")}</option>
                    {inputFileMetaInfo.columns && inputFileMetaInfo.columns.length > 0 && inputFileMetaInfo.columns.map((item, index) => {
                      return <option
                        key={index}
                        value={item}
                        selected={columnGate === item}
                      >
                        {item}
                      </option>
                    })}
                  </select>
                </td>
                <td className="pt-3">
                  {/* <div className="checkbox-container d-flex justify-content-center">
                    <input type="checkbox" />
                    <label className="mb-2 ms-1">
                      {t("noMatchSelectall")}
                    </label>
                  </div> */}
                  <div className="d-flex flex-column flag scroll align-items-center" >
                    {valuesGate && valuesGate.map((item, index) => {
                      return (
                        <div className="d-flex ms-3 checkbox-container ">
                          <input
                            type="checkbox"
                            id={index}
                            value={item}
                            checked={valuesGateTarget && valuesGateTarget.includes(item)}
                            onChange={handleGateValuesChange}
                          />
                          <label htmlFor={index} className="my-auto mx-2">
                            {item}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("entryExitflag")}</td>
                <td>
                  <select
                    className="w-100 m-auto ps-2 csvSelect py-2"
                    onChange={(e) => { getColumnValuesEnterExit(e.target.value); handleEnterExitColumnChange(e) }}
                  >
                    <option>{t("csvList")}</option>
                    {inputFileMetaInfo.columns && inputFileMetaInfo.columns.length > 0 && inputFileMetaInfo.columns.map((item, index) => {
                      return <option
                        key={index}
                        value={item}
                        selected={columnEnterExit === item}
                      >
                        {item}
                      </option>
                    })}
                  </select>
                </td>
                <td className="pt-3">
                  <div className="d-flex justify-content-center">
                    <div className="me-2">
                      <h6 className="mb-2 ms-1">
                        {t("entryFlag")}
                      </h6>
                      <div className="d-flex flex-column flag scroll" >
                        {valuesEnterExit && valuesEnterExit.map((item, index) => {
                          return (
                            <div className="d-flex ms-3 checkbox-container">
                              <input
                                type="checkbox"
                                id={index}
                                value={item}
                                checked={valuesEnterTarget && valuesEnterTarget.includes(item)}
                                onChange={handleEnterValuesChange}
                              />
                              <label htmlFor={index} className="my-auto mx-2">
                                {item}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div>
                      <h6 className="mb-2 ms-1">
                        {t("exitFlag")}
                      </h6>
                      <div className="d-flex flex-column flag scroll">
                        {valuesEnterExit && valuesEnterExit.map((item, index) => {
                          return (
                            <div className="d-flex ms-3 checkbox-container">
                              <input
                                type="checkbox"
                                id={index}
                                value={item}
                                checked={valuesExitTarget && valuesExitTarget.includes(item)}
                                onChange={handleExitValuesChange}
                              />
                              <label htmlFor={index} className="my-auto mx-2">
                                {item}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
          </table>
          <button
            className=" btn btn-submit mt-4 mx-auto "
            onClick={() => postInputSettingOperationAnalysis()}
          >
            {t("update")}
            <FaAngleRight />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
};
export default CsvAccessLogSettingModal;
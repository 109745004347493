
import React, { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
export const ResponseRemarks = ({ questionData }) => {

    const { register } = useFormContext();
    return (
        <form>

            <textarea
                className="w-100 mb-2"
                rows={2}
                {...register(`${questionData.question}[0]`)}
                onChange={(e) => {
                    e.target.style.height = `${e.target.scrollHeight}px `;
                }}
            ></textarea>
            <textarea
                as="textarea"
                className="w-100 mb-2"
                rows={2}
                {...register(`${questionData.question}[1]`)}
                onChange={(e) => {
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }}
            ></textarea>
            <textarea
                className="w-100 mb-2"
                rows={2}
                {...register(`${questionData.question}[2]`)}
                onChange={(e) => {
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }}
            ></textarea>
            <textarea
                as="textarea"
                className="w-100 mb-2"
                rows={2}
                {...register(`${questionData.question}[3]`)}
                onChange={(e) => {
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }}
            ></textarea>
            <input
                className="w-100 mb-2"
                rows={2}
                {...register(`${questionData.question}[4]`)}
                onChange={(e) => {
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }}
            />
            <textarea
                className="w-100 mb-2"
                rows={2}
                {...register(`${questionData.question}[5]`)}
                onChange={(e) => {
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }}
            ></textarea>
        </form>
    )
}
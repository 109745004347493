import { ApiService } from "./api";

export class FileService extends ApiService {
  getUploadUrl(key) {
    return this.get(`/file-upload?key=${key}`);
  }
  getUploadLogoUrl(key) {
    return this.get(`/logo-upload?key=${key}`);
  }
  getDownloadUrl(key) {
    return this.get(`/file-download?key=${key}`);
  }
  deleteFile(key) {
    return this.delete(`/file-delete?key=${key}`);
  }
}

import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrashAlt, FaPlusCircle } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage, swap } from "formik";
import { Modal, Button } from "react-bootstrap";
import { AgentService } from "../utils/services/agent.service";
import * as Yup from "yup";
import jwt_decode from "jwt-decode";
import { ApiService } from "../utils/services/api";
import { ClientService } from "../utils/services/client.service";
import { RegisterService } from "../utils/services/register.service";
import { industry_en, industry_ja } from "../utils/constants/IndustryType";
import { FileService } from "../utils/services/file.service";
import { FaCloudUploadAlt } from "react-icons/fa";
import Files from "react-files";
import Papa from "papaparse";
import Loader from "../containers/Loader";
import Swal from "sweetalert2";
import { useSortableData } from "../utils/useSortableData";
import RoleType from "../utils/constants/RoleType";
import axios from "axios";


const apiService = new ApiService();
const clientService = new ClientService();
const registerService = new RegisterService();
const fileTypesforCsv = [".csv"];
const agentService = new AgentService();
const Agent = ({
  setActiveTab,
  setActiveAgent,
  bulkAgentCreateshow,
  setBulkAgentCreateShow,
  setActiveTenant,
  activeTab,
  setActiveConsultants,
  setUploadError
}) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [tenant, setTenant] = useState("");
  const [agents, setAgents] = useState([]);
  const [lang, setLang] = useState("");
  const [agentId, setAgentId] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const handleBulkCreateModalClose = () => setBulkAgentCreateShow(false);
  const [bulkCreateshow, setBulkCreate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [parsedAgent, setParsedAgent] = useState([]);
  const [editLogo, setEditLogo] = useState(false);
  const [logo, setLogoData] = useState();
  const [logoGetUrl, setlogoGetUrl] = useState(null);
  const { sortItems, requestSort, sortConfig } = useSortableData(agents || null);
  const fileService = new FileService();
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [logoUrl, setLogoUrl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setTenant(decoded["cognito:groups"][0]);
    } catch (err) { }
  };

  const initialValues = {
    agentName: "",
    billing: null,
    logoUrl: "",
    personInCharge1: "",
    email1: "",
    personInCharge2: "",
    email2: "",
    personInCharge3: "",
    email3: "",
    personInCharge4: "",
    email4: "",
    personInCharge5: "",
    email5: "",
    personInCharge6: "",
    email6: "",
    personInCharge7: "",
    email7: "",
    personInCharge8: "",
    email8: "",
    personInCharge9: "",
    email9: "",
    personInCharge10: "",
    email10: "",
  };

  const listAgent = async (data) => {
    setIsLoadingTableData(true);
    try {
      const response = await agentService.listAgent(data);
      setAgents(response["data"]);
    } catch (err) { console.log(err) } finally {
      setIsLoadingTableData(false);
    }
  };

  const validationSchema = Yup.object({
    agentName: Yup.string().required(t("agent_name_is_required")),
    email1: Yup.string()
      .required(t("email_is_required"))
      .email(t("not_proper_email")),
    personInCharge1: Yup.string().required(t("PIC_is_required")),
    billing: Yup.string().required(t("billing_is_required")).typeError(t("billing_is_required")),
  }
  );

  const addAgentModal = async () => {
    setShow(true);
    setFormValues(initialValues);
    setIsEdit(false);
    setEditLogo(false);
    setSelectedImage(null);
    setLogoUrl(null);
  };

  useEffect(() => {
    if (activeTab == 'agent') {
      listAgent()
      getVerifier();
    }
  }, [setActiveTab]);

  useEffect(() => {
    if (activeTab == 'agent') {
      setActiveTenant("")
      setActiveAgent("")
      setActiveConsultants("")
      listAgent()
    }
  }, [activeTab]);

  const handleUpload = async () => {
    const {
      data: { url: uploadUrl },
    } = await fileService.getUploadLogoUrl(
      `agent/${selectedImage.name}`
    );
    setlogoGetUrl(uploadUrl);
    const blob = new Blob([selectedImage], { type: selectedImage.type });
    const response = await axios.put(uploadUrl, blob, {
      headers: {
        "Content-Type": selectedImage.type,
      },
    });
    if (response.status == 200) {
      Swal.fire({
        title: t("data_uploaded"),
        text: t("file_has_been_uploaded"),
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#D57B85",
      });
    }
  }

  const handleActiveTabConsultant = async (data) => {
    setActiveAgent(data)
    setActiveTab("consultants")
  }

  const handleActiveTabTenant = async (data) => {
    setActiveTab("client_company")
    const response = await agentService.getConsultantTenants({ agentId: data })
    setActiveTenant(response.data.length > 0 ? response.data : ["default"]);
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const onSubmit = async (e) => {
    try {
      setLoader(true);
      // const email_list = [...Array(10)].map((_, i) => "email" + (i + 1))
      // email_list.forEach((email) => {
      //   if (e[email]) {
      //     const cognitoResponse = registerService.createUser({
      //       email: e[email],
      //       username: e[email],
      //       name: e["personInCharge" + (email.split("email")[1])],
      //       groupNamePrefix: "Reader",
      //       userType: RoleType.AGENT,
      //       tenant: "_agent",
      //       list_tenant_dashboard: []
      //     });
      //     if (!cognitoResponse) {
      //       throw new Error(t("failed_to_register_agent"));
      //     }
      //   }
      // }
      // )

      if (logoGetUrl) {
        e["logoUrl"] = logoGetUrl.split('?')[0];
      }
      const response = await agentService.createAgent(e);
      if (response) {
        setShow(false);
        setLoader(false);
        listAgent();
        setEditLogo(false);
      }
      setLoader(false);

    } catch (err) {
      Swal.fire({
        title: "ERROR!",
        icon: "error",
        buttonsStyling: false,
        text: t("user_already_exists"),
        confirmButtonText: "Close",
        customClass: {
          confirmButton: "btn btn-danger op-100 ",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      setLoader(false);
    }
  };

  const editAgent = async (data) => {
    const logoUrl = data.logoUrl;
    setLogoUrl(logoUrl)
    if (agents) {
      setAgentId(data.agentId);
      agents.map((ele) => {
        if (ele.agentId === data.agentId) {
          setShow(true);
          setIsEdit(true);
          const data = {
            agentName: ele.agentName,
            billing: ele.billing,
          };

          ele.emails.forEach((email, index) => {
            const key = "email" + (index + 1)
            data[key] = email
          }
          )
          ele.personsInCharge.forEach((personInCharge, index) => {
            const key = "personInCharge" + (index + 1)
            data[key] = personInCharge
          }
          )
          const savedValues = data;
          setFormValues(savedValues);
        }
      });
    }
  };

  const updateAgent = async (e) => {
    e["agentId"] = agentId;
    if (logoGetUrl) {
      e["logoUrl"] = logoGetUrl.split('?')[0];
    }
    if (e.agentId !== undefined) {
      const response = [];
      const res = await agentService.createAgent(e)
      if (res) {
        listAgent();
        handleClose(true);
        setAgents(
          agents.map((item) =>
            item.agentId === e.agentId ? { ...item } : item
          )
        );
      }
    }
  };

  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };

  useEffect(() => {
    if (i18n.language) {
      changeLang(i18n.language);
    }

    listAgent();
  }, [i18n.language]);

  useEffect(() => {
    listAgent();
  }, []);

  const onFilesChange = (files) => {
    if (files && files[0].type === "text/csv") {
      try {
        let uploadError = [];
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (results.data.length === 0) {
              Swal.fire({
                icon: "error",
                text: t("none_data_csv"),
              });
              return;
            }
            setParsedAgent(
              results.data.map((c,index) => {
                if (!(c.agentName)) {
                  uploadError.push({type:"required", index:index, item:"agent_name", column: "agentName"})
                }
                if (!(c.billing)) {
                  uploadError.push({type:"required", index:index, item:"billing", column: "billing"})
                }
                if (!(c.personInCharge1)) {
                  uploadError.push({type:"required", index:index, item:"personInCharge1", column: "personInCharge1"})
                }
                c.email1 = c.email1.trim()
                c.email2 = c.email2.trim()
                c.email3 = c.email3.trim()
                c.email4 = c.email4.trim()
                c.email5 = c.email5.trim()
                c.email6 = c.email6.trim()
                c.email7 = c.email7.trim()
                c.email8 = c.email8.trim()
                c.email9 = c.email9.trim()
                c.email10 = c.email10.trim()
                if (!(c.email1)) {
                  uploadError.push({type:"required", index:index, item:"email1", column: "email1"})
                }
                if (uploadError.length > 0) {
                  Swal.fire({
                    icon: "error",
                    text: t("invalid_csv"),
                  });
                  return;
                }
                return { ...c };
              })
            );
            setUploadError(uploadError);
          },
        });
        setFiles(files);
      } catch (e) {
        Swal.fire({
          icon: "error",
          text: t("csv_system_err"),
        });
      }
    }
  };
  const onFilesError = (error, file) => {
    setError(error.message);
  };

  const openBukkCreationModal = () => {
    setBulkCreate(true);
    if (files.length) {
      setFiles(null);
    }
  };

  const postCsv = async () => {
    try {
      setBulkCreate(false);
      parsedAgent.forEach((c) => {
        if (c) {
          onSubmit(c);
        }
      });
      Swal.fire({
        title: t("csv_request_submitted"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    } catch (err) { }
  };

  const onDelete = async (row) => {
    const data = {
      username: row.email,
      groupNamePrefix: "Reader",
      tenant: "_agent",
    };
    const deleted = await agentService.deleteAgent(row.agentId);
    if (deleted) {
      listAgent();
    }
  };

  const confirmDelete = (row) => {
    Swal.fire({
      title: t("are_u_Sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D57B85",
      cancelButtonColor: "#66AB79",
      confirmButtonText: "削除する",
      cancelButtonText: "キャンセル",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(row);
        Swal.fire({
          title: "削除しました",
          confirmButtonColor: "#D57B85",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        })
      }
    });
  };

  const handleSearch = (event) => {
    listAgent({ search: searchValue.trim() });
  };

  const fieldCount = 10

  return (
    <>
      <div className="col-12 px-2">
        <div className="d-flex justify-content-between"></div>
        <div>
          <div className="d-flex align-items-center justify-content-between ">
            <label htmlFor="search" className="text-dark mb-2 d-flex align-items-center">
              <input
                id="search"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="form-control rounded-0 mr-2"
                placeholder=""
              />
              <button className="btn btn-14213D w-50 m-3" onClick={handleSearch}>
                {t("search_data")}
              </button>
            </label>
            <div className="d-flex">
              <button
                className="btn add-btn op-100"
                onClick={addAgentModal}
              >
                <FaPlusCircle className="align-text-middle" /> {t("add_agent")}{" "}
              </button>
            </div>
          </div>
          {isLoadingTableData ? (
            <Loader></Loader>
          ) : sortItems ? (
            <Card className="border-0">
              <Card.Body className="card-body-height p-0 m-2">
                <Card.Text className=" px-2">
                  <table className="table table-striped clr-14213D w-auto">
                    <thead>
                      <tr className="py-1">
                        <th scope="col">
                          <p className="m-2">No.</p>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("agentName")}
                            className={`${getClassNamesFor("agentName")} btn`}
                          >
                            {t("agent_name")}
                          </button>
                        </th>


                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("personInCharge")}
                            className={`${getClassNamesFor("personInCharge")} btn`}
                          >
                            {t("person_in_charge")}
                          </button>
                        </th>

                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("email")}
                            className={`${getClassNamesFor("email")} btn`}
                          >
                            {t("email")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("consultants")}
                            className={`${getClassNamesFor("consultants")} btn`}
                          >
                            {t("consultants")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("consultant_count")}
                            className={`${getClassNamesFor("consultant_count")} btn`}
                          >
                            {t("num_consultants")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("client")}
                            className={`${getClassNamesFor("client")} btn`}
                          >
                            {t("client")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("client_count")}
                            className={`${getClassNamesFor("client_count")} btn`}
                          >
                            {t("num_clients")}
                          </button>
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("billing")}
                            className={`${getClassNamesFor("billing")} btn`}
                          >
                            {t("billing")}
                          </button>
                        </th>
                        <th scope="col" className="col-2">
                          <p className="m-2"> {t("action")}</p>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {sortItems &&
                        sortItems?.map((row, i) => (
                          <tr key={i}>
                            <td scope="row" className="col-2 align-middle">
                              {" "}
                              {i + 1}
                            </td>
                            <td>{row.agentName}</td>
                            <td>{row.personsInCharge.join(", ")}</td>
                            <td>{row.emails.join(", ")}</td>
                            <td className="btn-contain">
                              <button
                                className="btn btn-14213D border-0"
                                onClick={() => handleActiveTabConsultant(row.agentId)}
                              >
                                {t("list")}
                              </button>
                            </td>
                            <td>{row.consultant_count}</td>
                            <td className="btn-contain">
                              <button
                                className="btn btn-14213D border-0"
                                onClick={() => handleActiveTabTenant(row.agentId)}
                              >
                                {t("list")}
                              </button>
                            </td>
                            <td>{row.client_count}</td>
                            <td>{row.billing}</td>
                            <td className="bg-transparent p-0 d-flex w-100">
                              <button
                                className="btn btn-green op-100 me-2 rounded-0"
                                onClick={() => {
                                  editAgent(row);
                                }}
                              >
                                <FaEdit className="align-text-middle" />{" "}
                                <span className="pt-1">{t("edit")} </span>
                              </button>
                              <button
                                className="btn btn-E65C4F rounded-0"
                                onClick={() => {
                                  confirmDelete(row);
                                }}
                              >
                                <FaTrashAlt /> <span></span>
                                <span className="pt-1">{t("delete")} </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <h4 className="clr-14213D text-center">{t("no_data_available")}</h4>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-agent-popup"
      >
        <Modal.Header closeButton>
          <h5 className="clr-14213D mb-0 ">
            <b> {isEdit ? t("edit") : t("add_agent")}</b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Formik
              initialValues={formValues || initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
              validateOnMount={true}
            >
              {(formik) => {
                return (
                  <Form className="text-body" onSubmit={formik.handleSubmit}>
                    <div className="d-flex justify-content-between">
                      <div className="col-12">
                        <label
                          type="text"
                          className="clr-14213D fnt-14"
                          htmlFor="agent_name"
                        >
                          <b> {t("agent_name")}</b>
                        </label>
                        <Field
                          name="agentName"
                          className="w-100 select-class"
                          id="agentName"
                          onChange={(e) =>
                            formik.setFieldValue("agentName", e.target.value)
                          }
                        >
                        </Field>
                        <div className="text-danger mb-1">
                          <ErrorMessage
                            name="agentName"
                            className="text-danger"
                          />
                        </div>
                        <label
                          type="text"
                          className="clr-14213D fnt-14"
                          htmlFor="billing"
                        >
                          <b> {t("billing")}</b>
                        </label>
                        <Field
                          as="select"
                          name="billing"
                          className="w-100 select-class"
                          id="billing"
                          defaultValue={"DEFAULT"}
                          onChange={(e) =>
                            formik.setFieldValue("billing", e.target.value)
                          }
                        >
                          <option value="DEFAULT" selected disabled>
                            {t("select_option")}
                          </option>
                          <option value="前金">前金</option>
                          <option value="後金">後金</option>
                          <option value="半金半金">半金半金</option>
                          <option value="3分割（343）">3分割（343）</option>
                          <option value="その他">その他</option>
                        </Field>
                        <div className="text-danger mb-1">
                          <ErrorMessage
                            name="billing"
                            className="text-danger"
                          />
                        </div>

                        <div>
                          {[...Array(fieldCount)].map((_, index) => (
                            <div key={index} className="d-flex justify-content-start">
                              <div className="">
                                <label type="text" className="clr-14213D fnt-14" htmlFor={`personInCharge${index + 1}`}>
                                  <b>{t(`personInCharge${index + 1}`)}</b>
                                </label>
                                <Field
                                  type="text"
                                  id={`personInCharge${index + 1}`}
                                  name={`personInCharge${index + 1}`}
                                  className="input-box"
                                />
                                <div className="text-danger mb-1">
                                  <ErrorMessage name={`personInCharge${index + 1}`} className="text-danger" />
                                </div>
                              </div>
                              <div className="mx-1">
                                <label type="text" className="clr-14213D fnt-14" htmlFor={`email${index + 1}`}>
                                  <b>{t(`email${index + 1}`)}</b>
                                </label>
                                <Field
                                  type="text"
                                  id={`email${index + 1}`}
                                  name={`email${index + 1}`}
                                  className="input-box"
                                />
                                <div className="text-danger mb-1">
                                  <ErrorMessage name={`email${index + 1}`} className="text-danger" />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Logo Url */}
                        <div className="d-flex justify-content-start">
                          <div>
                            <label type="text" className="clr-14213D fnt-14">
                              <b> {t("logo_mark")}</b>
                            </label>
                            <div className="d-flex">
                              {!logoUrl && !selectedImage && (
                                <div className="uploadlogoImage">
                                  <label className="clr-14213D fnt-14" htmlFor="logoUrl">
                                    <div className="logo-upload">
                                      <FaCloudUploadAlt size={30} />
                                      <h6 className="mt-2 mb-2">{t("select_file")}</h6>
                                    </div>
                                  </label>
                                </div>
                              )}
                              {selectedImage && (
                                <div className="uploadlogoImage">
                                  <img
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="image"
                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                  />
                                </div>
                              )}
                              {isEdit && logoUrl && !selectedImage && (
                                <div className="uploadlogoImage">
                                  <img src={logoUrl} alt="image" style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%"
                                  }} />
                                </div>
                              )
                              }
                            </div>
                            <input
                              type="file"
                              id="logoUrl"
                              name="logoUrl"
                              className="w-100"
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                            <div className="upladedLogoName">
                              {selectedImage && <p>{selectedImage.name}</p>}
                            </div>
                          </div>

                          <div className="d-flex flex-column align-items-start mt-4">
                            <button
                              type="button"
                              className="btn btn-14213D op-100 pt-1 pb-1"
                              onClick={() => {
                                document.getElementById('logoUrl').click();
                                setEditLogo(true);
                              }
                              }
                              disabled={!logoUrl && !selectedImage}
                            >
                              {t("change")}
                            </button>

                            <button
                              type="button"
                              className="btn btn-14213D op-100 mb-2 pt-1 pb-1 mt-2"
                              onClick={handleUpload}
                              disabled={!selectedImage}
                            >
                              {t("upload")}
                            </button>
                          </div>

                        </div>

                        <div className="text-danger mb-1">
                          <ErrorMessage name="logoUrl" className="text-danger" />
                        </div>

                      </div>
                    </div>

                    <div className="d-flex justify-content-center pt-2">
                      <button
                        type="button"
                        className="btn btn-E65C4F  op-100"
                        onClick={() => setShow(false)}
                      >
                        {t("cancel")}
                      </button>
                      {isEdit ? (
                        <button
                          type="button"
                          className="btn btn-green ms-3 op-100"
                          disabled={!formik.isValid}
                          onClick={() => updateAgent(formik.values)}
                        >
                          {t("update")}
                        </button>
                      ) : loader ? (
                        <Loader />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-green ms-3 op-100"
                          disabled={!formik.isValid || !formik.dirty}
                          onClick={() => onSubmit(formik.values)}
                        >
                          {t("save")}
                        </button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal >
      <Modal
        show={bulkAgentCreateshow}
        onHide={handleBulkCreateModalClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-0">
          <h5 className="clr-14213D mb-0 ">{t("upload_csv")}</h5>
        </Modal.Header>
        <Modal.Body className="pb-lg-5 pt-1">
          <div className="mb-4 ">
            <div className="d-flex justify-content-center">
              <div>
                <a
                  href="https://wdp-dev-bucket-tool-zone.s3.ap-northeast-1.amazonaws.com/templates/csv/agent_sample.csv"
                  className="clr-14213D fnt-14"
                >
                  {t("downloadSample")}
                </a>
              </div>
            </div>
            <div className="">
              <Files
                className="d-flex files-dropzone w-75 mx-auto flex-column align-items-center justify-content-center"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={fileTypesforCsv}
                multiple={false}
                name="files"
              >
                <h6 className="mb-4">{t("drag_drop")}</h6>
                <FaCloudUploadAlt size={60} />
                <h6 className="mt-3 mb-0">{t("select_file")}</h6>
              </Files>
            </div>

            <div className="d-flex justify-content-center">
              {files && files.length
                ? files.map((ele) => {
                  return (
                    <div className="pb-2 pt-1 ps-3 text-black-50" key={ele.id}>
                      {ele.name}
                    </div>
                  );
                })
                : ""}
            </div>

            {error ? <div className="text-danger">{error}</div> : ""}
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn  btn-green   mt-2 op-100 "
                disabled={!(files &&
                  files[0]?.type === "text/csv" &&
                  parsedAgent.filter((d) => !!d).length)}
                onClick={postCsv}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
export default Agent;

export const japanTranslation = {
  present: "現在の分析",
  Operation: "稼働分析",
  Space: "空間分析",
  CommuteSimulation: "エリアシミュレーション",
  RoomSimulation: "空間シミュレーション",
  CostSimulation: "コストシミュレーション",
  summarySetting: "サマリ設定",
  Simulation: "シミュレーション",
  survey: "サーベイ",
  answer_survey: "サーベイ回答をする",
  workshopInterview: "定性リサーチ",
  summary: "サマリ",
  officeExample: "オフィス事例",
  userSetting: "ユーザー設定",
  adminSetting: "管理者設定",
  manageSurvey: "サーベイ管理",
  listSurvey: "サーベイ一覧",
  addSurvey: "追加",
  surveyTitle: "サーベイタイトル",
  action: "",
  edit: "編集",
  delete: "削除",
  surveys: "サーベイ",
  createJsonTextCopy: "をクリックしてサーベイの編集方法を確認してください。リンク先のJSONエディタからJSON文字列をコピーして、下に貼り付けてください。",
  editJsonTextXCopy: "をクリックしてサーベイの編集方法を確認してください。リンク先のJSONエディタからJSON文字列をコピーして、下に貼り付けてください。",
  link: "リンク ",
  andPasteBelow: " を入力し、下のサーベイ用テキストエリアに貼り付けて下さい。",
  update: "更新",
  placeYourJsonData: "JSON形式のテキストを貼り付けて下さい",
  createSurvey: "サーベイを作成する",
  editSurvey: "サーベイを編集する",
  takeSurvey: "サーベイに回答する",
  viewOldSurvey: "旧サーベイを見る",
  followThis: "",
  submit: "保存",
  save: "保存",
  dropYourImage: "ファイルを選択する",
  downloadSampleCsv: "サンプルCSVのダウンロード",
  dragfiles: "ファイルをドラッグ＆ドロップしてください",
  uploadyourFilesText: "ファイルのアップロード",
  dragyourFileText: "ファイルをドラッグ＆ドロップしてください",
  source_select: "ファイル選択",
  username: "ユーザー名",
  password: "パスワード",
  password_updated: "パスワードの更新",
  password_mismatched: "パスワードの不一致",
  incorrect_password: "不正なパスワード",
  new_password: "新しいパスワード",
  cnf_password: "パスワードの確認",
  current_password: "現在のパスワード",
  click_here: "ここをクリック",
  keep_me_sign: "サインインしたままにする",
  create_work_for_future: "The Future of Work",
  creat_for_future_japan: "",
  fgt_pwd: "パスワードを忘れた方はこちら",
  login: "ログイン",
  keep_signed_in: "サインインしたままにする",
  tenant: "クライアント名",
  add: "追加",
  add_new_user: "ユーザーを追加する",
  user_email: "ユーザーメール",
  register: "登録",
  tenantname: "クライアント名",
  usertype: "ユーザー種別",
  user_registration: "ユーザー登録",
  admin_comments: "",
  office_address: "現住所",
  new_office_address: "移転先住所",
  enter_new_office_address: "移転先住所を入力してください（例：東京都港区芝公園4-2-8）",
  user: "ユーザー",
  admin: "管理",
  utilization_info: "空間利用状況（データ）",
  utilization_map: "空間利用状況（画像）",
  attendance_info: "出社・在席情報",
  employee_info: "従業員情報",
  branch_info: "支店情報",
  data_analysis: "分析",
  data_upload: "データアップロード",
  office_sample: "オフィスサンプル",
  analysis_operation: "従業員の出社状況・オフィスの滞在状況・座席の利用状況を分析します",
  analysis_space: "オフィス空間の利用状況を可視化します",
  commute_simulation_title: "従業員の居住地の可視化と、通勤時間・片道運賃のシミュレーションを行います",
  room_simulation_title: "働き方タイプに応じた空間の利用イメージをシミュレーションします",
  cost_simulation: "コストシミュレーション",
  cost_simulation_title: "オフィス移転時のコスト発生をシミュレーションします",
  survey_results_title: "サーベイ回答を分析・可視化します",
  place_style_survey: "プレイススタイルサーベイ",
  surveyNumberofRespondents: "回答者数",
  surveyResponseRate: "回答率",
  close: "閉じる",
  save_tenant: "クライアントを保存",
  add_tenant: "クライアントを追加",
  author: "Author",
  reader: "Reader",
  please_wait_for_tenant_response: "クライアントを登録しています。しばらくお待ちください",
  user_is_required: "ユーザー名は必須項目です。",
  tenant_is_required: "クライアント名は必須項目です",
  type_is_required: "ユーザータイプは必須項目です",
  email_is_required: "メールアドレスは必須項目です",
  not_proper_email: "不適切なメールアドレスです。入力内容を確認してください",
  edit_address: "アドレスの編集",
  save_address: "アドレスの保存",
  add_new_tenant_name: "新しいクライアントを追加する",
  floor_select: "フロア選択",
  analysis: "定量リサーチ",
  survey_setting: "サーベイ設定",
  top_interview_setting: "トップインタビュー設定",
  workshop_setting: "ワークショップ設定",
  administration_menu: "管理メニュー",
  workshop_survey: "定性リサーチ",
  workshop: "ワークショップ",
  top_interview: "トップインタビュー",
  survey_response: "サーベイ回答",
  client_management: "クライアントユーザー",
  top_interview_manage: "トップインタビュー一覧",
  consultant_management: "コンサルタント管理",
  client_company: "クライアント",
  client_integration: "クライアント統合",
  add_new_consultant: "コンサルタントを追加する",
  add_new_client_integration: "クライアント統合企業を追加する",
  division: "所属",
  name: "ユーザー名",
  client_in_charge: "担当クライアント",
  consultant_in_charge: "担当コンサルタント",
  account_info: "アカウント情報",
  email1: "メールアドレス 1",
  email2: "メールアドレス 2",
  email3: "メールアドレス 3",
  email4: "メールアドレス 4",
  email5: "メールアドレス 5",
  email6: "メールアドレス 6",
  email7: "メールアドレス 7",
  email8: "メールアドレス 8",
  email9: "メールアドレス 9",
  email10: "メールアドレス 10",
  cancel: "キャンセル",
  name_is_required: "氏名は必須項目です",
  division_is_required: "所属部門は必須項目です",
  client_in_charge_is_required: "担当企業は必須項目です",
  add_new_tenant: "クライアントを追加する",
  company_name: "クライアント名",
  address_changed: "移転先住所が更新されました。",
  comments_submitted_successfuly: "管理者によるコメントが変更されました",
  select_tenant: "企業を検索",
  tenantId: "Tenant Id",
  industry: "業種",
  address: "本社所在地",
  scale: "規模",
  tsubo: "坪数",
  tenant_isrequired: "テナントIDは必須項目です",
  company_name_is_required: "企業名は必須項目です",
  industry_is_required: "業種は必須項目です",
  plan_is_required: "契約プランは必須項目です",
  contractRange_is_required: "契約レンジは必須項目です",
  contractRange_err_required: "現在の契約プランではこれ以上アカウント登録ができません。\n 契約プラン名：{0}",
  contractRange_is_required: "契約レンジは必須項目です",
  start_at_is_required: "開始日は必須項目です",
  contractRange_is_required: "契約レンジは必須項目です",
  expire_at_is_required: "更新日は必須項目です",
  scale_is_requied: "規模は必須項目です",
  address_is_required: "本社所在地は必須項目です",
  tsubo_is_required: "坪数は必須項目です",
  client_user: "クライアントユーザー",
  tenant_name_is_required: "企業名は必須項目です",
  clients_is_required: "担当企業は必須項目です",
  number_is_required: "数値を入力してください",
  user_name: "ユーザー名",
  usertype_is_required: "ユーザー種別は必須項目です",
  locale_is_required: "ロケールは必須項目です",
  locale: "ロケール",
  workshop_results: "ワークショップ",
  top_interview_results: "トップインタビュー結果",
  edit_consultant: "コンサルタント編集",
  edit_client_integration: "クライアント統合企業編集",
  input_by_consultant: "コンサルタントによる記載",
  consultants: "コンサルタント",
  clientIntegration: "クライアント統合",
  contractPlan: "統合企業契約プラン",
  contractPlanStartDate: "開始日",
  contractPlanStartDate_is_required: "開始日は必須項目です",
  contractPlanEndDate: "終了日",
  contractPlanEndDate_is_required: "終了日は必須項目です",
  start_endDate_is_required: "終了日時は開始日時より後にしてください",
  select: "選択",
  select_option: "選択してください",
  floor: "フロア",
  select_a_response: "実施結果を選択してください",
  by: " 回答者:",
  on: " 更新日時:",
  new_tenant_request_registered: "クライアントの登録処理を開始しました。登録には5分程度かかりますので、画面右上のボックスに企業名が表示されるまでお待ちください",
  welcome: "WDP へようこそ",
  must_change_password: "最初のログイン時にパスワードを変更する必要があります。",
  change_password: "パスワードを変更する",
  current_password: "現在のパスワード",
  new_password: "新しいパスワード",
  confirm_new_password: "新しいパスワードを確認",
  upload_csv: "CSVのアップロード",
  import_usr_csv: "CSVからユーザーをインポートする",
  top_interview_list: "トップインタビュー一覧",
  top_interview_theme: "トップインタビューテーマ",
  workshop_list: "ワークショップ一覧",
  workshop_title: "ワークショップタイトル",
  conduct_interview: "インタビューを実施する",
  conduct_workshop: "ワークショップを実施する",
  reset_your_pwd: "パスワードをリセット",
  survey_analysis: "サーベイ",
  csv_download: "Csvダウンロード",
  result_csv_download: "Csv出力(サーベイ結果)",
  status_csv_download: "Csv出力(回答状況)",
  create_Workshop: "ワークショップを作成する",
  create_topInterview: "トップインタビューを作成する",
  edit_Workshop: "ワークショップ編集",
  edit_topInterview: "トップインタビュー編集",
  survey_manage: "サーベイ一覧",
  workshop_manage: "ワークショップ一覧",
  survey_response_list: "アンケート回答一覧",
  survey_response_list_id: "ダウンロードするアンケート名",
  user_already_exists: "ユーザーの登録に失敗しました。システムの管理者までお問い合わせください",
  tenants_refreshed: "クライアント名を更新しました",
  send_verification: "パスワードを忘れた方はこちら",
  enter_verification_received: "登録メールアドレスに認証コードを送信しました",
  verification_code: "検証コード",
  proceed: "次へ進む",
  reset_success: "パスワードをリセットしました",
  none_data_csv: "アップロードファイルにデータが存在しません。入力内容を確認する上で、再度アップロードしてください",
  invalid_csv: "CSVの入力に不備があります。CSVアップロードの画面を閉じて画面下部に表示されたメッセージをご確認ください。",
  update_item_err: "・データの{{line_number}}行目：{{item_name}}が空欄になっています",
  update_item_duplicated_err: "・データの{{index1}}行目、{{index2}}行目：重複メールアドレス登録によるエラー",
  email_in_use_err:  "・データの{{line_number}}行目：メールアドレスは既に使用されています。{{email}}",
  user_type_content_err: "・データの{{line_number}}行目：ユーザー種別(userType)に「CLIENT_LEADER」「CLIENT_MEMBER」以外のユーザーが指定されています",
  tenant_content_err: "・データの{{line_number}}行目：クライアント名(tenant_name)に未登録のクライアントが指定されています",
  email_content_err: "・データの{{line_number}}行目：メールアドレス(email)の形式が不正です",
  table_title_err: "1行目の表のタイトル行が変更されています",
  update_err_title: "以下の項目でエラーが発生しました。",
  update_err_total: "失敗件数：{{errRowCount}}件",
  csv_system_err: "データアップロード中に想定外のエラーが発生しました。管理者にお問い合わせください。",
  csv_request_submitted: "リクエストを受け付けました。全てのユーザーが登録されるまでしばらくお待ちください",
  no_survyes: "アンケートなし",
  image_not_found: "イメージがありません",
  select_floor: "フロア選択",
  user_deleted_successfully: "削除しました",
  user_not_deleted: "削除を中止しました",
  are_u_Sure: "削除します。\n 本当に宜しいですか？",
  select_usertype: "ユーザータイプの選択",
  AccessLog: "アクセスログ",
  ItemsRequired: "必要な項目",
  CorrespondingColumns: "対応する列",
  Setting: "設定",
  UserId: "ユーザーID",
  ColumnList: "csvの列名",
  Datetime: "日時",
  SetJapaneseHoliday: "日本の休日を設定する",
  TargetGate: "集計対象とするゲート",
  AllDataWillBeUsed: "全てのデータを使用する",
  TargetDateSetting: "日時の設定",
  Weekday: "平日",
  Slot: "スロット",
  Holiday: "休日",
  InputSetting: "入力設定",
  CSVDataOfWSP: "WifiアクセスポイントのCSVデータ",
  CSVDataOfAL: "入退室ログのExcelデータ",
  ManuallyECD: "手入力のExcelデータ",
  WifiAccessPoint: "Wifiアクセスポイント",
  Secret: "secret",
  endpoint: "endpoint",
  URL: "URL",
  WifiManual: "Wifiマニュアル",
  NoColumnsApplicable: "該当する欄はありません",
  Monday: "月曜日",
  Tuesday: "火曜日",
  Wednesday: "水曜日",
  Thursday: "木曜日",
  Friday: "金曜日",
  Saturday: "土曜日",
  Sunday: "日曜日",
  operation_analysis_setting: "稼働分析設定",
  inputSettingStep1: "Step1: 以下のURLからMeraki設定画面にログインし、上記の値を登録してください",
  inputSettingStep2: "Step2: 登録後、以下のボタンを押して、アプリケーションの設定を完了します",
  num_employee: "社員数",
  rate_attendance: "平均出社率",
  tsubo: "坪数",
  unit_price_tsubo_per_tsubo_monthly: "坪単価(坪/月) ※千円",
  unit_price_admin_expense_per_tsubo_monthly: "管理費単価(坪/月) ※千円",
  contract_months: "契約期間(月)",
  initial_work_months: "初期工事期間(月)",
  seating_capacity: "座席数",
  interior_construction_cost_per_tsubo: "内装工事費用(坪) ※千円",
  interior_furniture_fee_per_tsubo: "内装家具費用(坪) ※千円",
  insitu_restoration_cost_per_tsubo: "原状回復工事費用(坪) ※千円",
  electricity_and_utilities_per_tsubo_montly: "電気光熱費単価(坪/月) ※千円",
  seat_occupancy_rate: "座席稼働率(率)",
  clk_here: "こちらをご覧ください",
  send: "送信",
  email_verified: "メールアドレスを入力してください",
  back_login: "ログインに戻る",
  top_page: "トップページ",
  management: "管理",
  account_management: "アカウント管理",
  research_management: "リサーチ管理",
  as_is: "現状把握",
  answer_survey: "サーベイ回答をする",
  qualitative_research: "定性的調査",
  space_simulation: "空間シミュレーション",
  commute_simulation: "エリアシミュレーション",
  comparative_review: "比較検討",
  add_agent: "エージェントを追加する",
  edit_agent: "エージェントを編集する",
  agent_name: "エージェント企業名",
  person_char: "担当者名",
  num_consultants: "コンサルタント合計",
  client: "クライアント",
  num_clients: "クライアント数",
  plan: "契約プラン",
  contract_range: "契約レンジ",
  billing: "請求タイプ",
  agent: "エージェント",
  select_file: "ファイルを選択",
  drag_drop: "ファイルをドラッグ＆ドロップしてください",
  num_of_acc: "アカウント数",
  logo_mark: "ロゴマーク",
  status: "初回ログイン",
  process: "処理中",
  duplication: "複製",
  set_new_password: "新しいパスワードの設定",
  csv_upload: "CSVアップロード",
  interviews: "インタビュー",
  workshops: "ワークショップ",
  search_data: "検索",
  room_analysis: "空間分析",
  updated_at: "更新日時",
  data: "分析データ",
  surveu_summary: "プレイススタイルサーベイ",
  senarios: "シナリオ",
  room_simulation: "空間シミュレーション",
  check_office_sample: "オフィスサンプルを見る",
  new_password: "新しいパスワード",
  admin_comment: "管理者コメント",
  submitted_surveys: "サーベイ回答",
  account_setting: "アカウント設定",
  hello: "こんにちは",
  Description: "WorkPlace解説",
  floor_map: "フロアマップ",
  select_floor: "フロア選択",
  save_pattern: "パターンを保存する",
  pattern_name: "パターン名",
  mode: "ワークモード",
  num_private_room: "個室の数",
  Simulate: "シミュレーション",
  Save: "設定を保存",
  select_pattern: "パターンを選択",
  pattern: "パターン",
  params: "パラメータ",
  utilization_info: "空間分析情報",
  utilization_map: "図面色分けデータ",
  attendance_info: "出社滞在情報",
  employee_info: "従業員情報",
  commute_info: "社員最寄り駅情報",
  branch_info: "支店住所",
  operation_analysis_setting: "稼働分析情報",
  completed: "完了",
  not_yet: "未完了",
  need_update: "要更新",
  short_survey_summary: "ワークデザインサーベイ（パルス）",
  deep_survey_summary: "ワークデザインサーベイ",
  select_param: "を選択",
  input_param: "を入力",
  from_0_to_1: " ※0～1",
  total_work_point: "ワークデザインスコア",
  respondent_ratio: "回答数",
  total_square_measure: "総面積",
  total_tsubo: "総面積(坪)",
  num_seat_touch_point: "タッチポイント",
  num_seat_work: "執務デスク",
  num_seat_meeting: "MTGポイント",
  rate_operation: "平均稼働率",
  num_attendance: "平均出社人数",
  commuting_time: "平均片道通勤時間",
  transportation_fee: "平均月額交通費(人)",
  comprehensive_evaluation: "オフィス総合評価",
  workability_index: "働きやすさ指数",
  improvement_index: "オフィス改善指数",
  cost_per_person: "一人あたり月額",
  cost_per_seat: "一席あたり月額(フル稼働)",
  cost_per_seat_substantial: "一席あたり月額(実働)",
  work_mode: "ワークモード",
  num_employess: "社員数",
  total_area: "総面積",
  area_per_person: "一人あたり面積",
  num_touch_point: "タッチポイント",
  num_seat_work: "執務デスク",
  person: "人",
  square_meters: "㎡",
  tsubo_measure: "坪",
  seat: "席",
  percent: "％",
  minute: "分",
  yen: "円",
  point: "pt",
  date_and_time_zone_for_aggregation: "集計対象の日付と時間帯",
  select_pattern_A: "シナリオAを選択",
  select_pattern_B: "シナリオBを選択",
  search: "企業名を検索",
  operation_plan: "運用計画",
  rental_plan: "賃貸計画",
  construction_plan: "工事計画",
  data_uploaded: "Completed",
  file_has_been_uploaded: "ファイルが正常にアップロードされました",
  upload_file: "ファイルのアップロード",
  date_and_time_zone_of_aggregation: "平均値の集計対象とする曜日と時間帯、集計対象外とする祝日を設定します",
  Dayofweek: "集計対象の曜日",
  timeSlot: "集計対象の時間帯",
  nationalHolidays: "集計対象から除く祝日",
  OperationalAnalysisSetting: "稼働分析設定",
  importData: "",
  csvData: "WifiアクセスポイントからのCSVデータ",
  excelData: "入退室ログのExcelデータ",
  manulaInput: "手入力のExcelデータ",
  automaticallyCollected: "Merakiアクセスポイントからの自動収集",
  downloadSample: "サンプルをダウンロード",
  update: "更新",
  select_survey: "サーベイを選択",
  first_week: "第1",
  second_week: "第2",
  third_week: "第3",
  fourth_week: "第4",
  last_week: "最終",
  mon: "月曜日",
  tue: "火曜日",
  wed: "水曜日",
  thu: "木曜日",
  fri: "金曜日",
  sat: "土曜日",
  sun: "日曜日",
  survey_notification_setting: "サーベイ配信設定",
  monthly_interval: "カ月ごとの",
  start_at: "開始日",
  sendmail_at: "メール配信日",
  end_at: "終了日",
  expire_at: "更新日",
  deep_survey: "ワークデザインサーベイ",
  short_survey: "ワークデザインサーベイ（パルス）",
  select_week: "週",
  select_day_of_week: "曜日",
  survey_results: "サーベイ結果",
  address_not_found: "入力された住所を特定できませんでした。入力内容を変えて再度お試しください。",
  remaining_time_expired: "エリアシミュレーション利用回数の上限に達しました。",
  comparative_review_title: "全分析結果を比較検討します",
  upload: "アップロード",
  logout: "ログアウト",
  industry1: "業種（大分類）",
  industry2: "業種（中分類）",
  industry1_is_required: "業種1は必須項目です",
  industry2_is_required: "業種2は必須項目です",
  person_in_charge: "担当者名",
  PIC_is_required: "担当者名は必須項目です",
  apiRemainingTimes: "エリアシミュレーション 残り時間",
  consultant_name: "コンサルタント名",
  client_integration_name: "クライアント統合企業名",
  client_integration_name_is_required: "クライアント統合企業名は必須項目です",
  client_integration_email: "統合管理者メールアドレス",
  client_integration_email_is_required: "統合管理者メールアドレスは必須項目です",
  client_integration_Address: "クライアント統合企業所在地",
  client_integration_Address_is_required: "クライアント統合企業所在地は必須項目です",
  integration_manager_name: "統合管理者名",
  client_integration_manager_name: "クライアント統合管理者",
  start: "開始",
  start_at_is_required: "開始日は必須項目です",
  expire_at_is_required: "更新日は必須項目です",
  area_composition_ratio: "面積構成比率",
  API_remaining_time_is_required: "エリアシミュレーション残回数は必須項目です",
  valid_email_is_required: "有効なメールアドレスを入力してください",
  survey_notification_setting_saved: "サーベイ配信設定を\n保存しました",
  input_number: "数値を入力してください",
  pleaseRegisterSettingURL: "SecretKeyとEndpointをMeraki管理画面に登録の上、Validationを実行して接続を確認してください",
  pleaseCompleteSettingURL: "Meraki管理画面よりValidationを下記に転記し、「登録」ボタンを押してAWSのスタックを作成してください",
  openSetting: "Meraki管理画面を開く",
  EnterMaterialKey: "merakiキーを入力",
  downloadSampleFile: "サンプルファイルをダウンロード",
  billing_is_required: "請求タイプは必須項目です",
  agent_not_found: "エージェントが見つかりませんでした。正しいエージェント名が入力されているか確認してください。",
  csv_request_failed: "CSVデータのアップロードに失敗しました",
  user_registration_failed: "登録に失敗しました",
  tenant_not_found: "クライアント企業が見つかりませんでした。正しい企業名が入力されているか確認してください。",
  WORK: "チームワーク型",
  PROJECT: "プロジェクト型",
  COMMUNITY: "コミュニティ型",
  pt: "pt",
  settingMoreDetails: "詳細設定",
  items: "項目",
  userID: "ユーザーIDの列名",
  time: "Timestampの列名",
  csvList: "CSVのカラムリスト",
  fileName: "ファイル名",
  uploadDate: "アップロード日",
  action: "アクション",
  gate: "入退室ゲートの列名",
  noMatchSelectall: "該当なし（すべて選択）",
  entryExitflag: "入退室フラグの列名",
  entryFlag: "入室フラグ",
  exitFlag: "退室フラグ",
  list: "一覧表示",
  personInCharge1: "担当者名 1",
  personInCharge2: "担当者名 2",
  personInCharge3: "担当者名 3",
  personInCharge4: "担当者名 4",
  personInCharge5: "担当者名 5",
  personInCharge6: "担当者名 6",
  personInCharge7: "担当者名 7",
  personInCharge8: "担当者名 8",
  personInCharge9: "担当者名 9",
  personInCharge10: "担当者名 10",
  clientIntegrationPersonsInCharge1: "統合管理者名1",
  clientIntegrationPersonsInCharge2: "統合管理者名2",
  clientIntegrationPersonsInCharge3: "統合管理者名3",
  clientIntegrationPersonsInCharge4: "統合管理者名4",
  clientIntegrationPersonsInCharge5: "統合管理者名5",
  clientIntegrationPersonsInCharge6: "統合管理者名6",
  clientIntegrationPersonsInCharge7: "統合管理者名7",
  clientIntegrationPersonsInCharge8: "統合管理者名8",
  clientIntegrationPersonsInCharge9: "統合管理者名9",
  clientIntegrationPersonsInCharge10: "統合管理者名10",
  clientIntegrationPersonsInCharge: "統合管理者",
  clientIntegrationPersonsInCharge_is_required: "統合管理者は必須項目です",
  new_clientIntegration_registered: "クライアント統合企業が登録されました",
  cannot_select_more_than_3: "3つまで選択してください",
  email: "メールアドレス",
  clientIntegrationEmail: "メールアドレス",
  emails: "メールアドレス",
  new_consultants_registered: "コンサルタントが登録されました",
  personsInCharge: "担当者名",
  agent_is_required: "エージェント名は必須項目です",
  no_data_available: "データがありません",
  client_leader: "クライアントリーダー",
  client_member: "クライアントメンバー",
  workPlace: "WorkPlace解説",
  new_meraki_stack_created: "Merakiスタックの作成を開始しました",
  setting_updated: "設定が更新されました",
  survey_setting_comment: "と同一の週・曜日を設定してください。",
  select_industry1: "業種（大分類）を選択してください",
  select_contractrange: "契約プランを選択してください",
  invalid_type: "無効な値です",
  save_progress: "途中保存",
  client_company_updated: "クライアント企業が更新されました。しばらくしてから画面を更新してデータの反映を確認してください",
  data_solution: "データソリューション",
  filter_condition: "絞り込み条件",
  occupation: "職種（企業ごと）",
  establieshed_year: "設立年",
  area: "エリア",
  do: "実行",
  responded: "回答済",
  no_notification: "未配信",
  temporary_response_saved: "回答を途中保存しました",
  back_to_list: "一覧に戻る",
  client_id: "クライアントID（データソリューション用）",
  industry1_1: "業種（大分類）１",
  industry2_1: "業種（中分類）１",
  industry1_2: "業種（大分類）２",
  industry2_2: "業種（中分類）２",
  establieshed_year_from: "設立年（開始）",
  establieshed_year_to: "設立年（終了）",
  area1: "エリア（都道府県）",
  area2: "エリア（市区町村）",
  area3: "エリア（主要都市の区部）",
  scaleFrom: "規模（最小） ※単位：坪",
  scaleTo: "規模（最大） ※単位：坪",
  click_white_space: "クリックして選択肢を追加",
  work_design_survey: "ワークデザインサーベイ",
  work_design_pulse_survey: "ワークデザインサーベイ(パルス)",
  ranking: "ランキング",
  project: "プロジェクト",
  change: "変更",
  new_user_registered: "ユーザーが登録されました",
  agent_name_is_required: "エージェント名は必須項目です",
  thousand_yen: "千円",
  auth_error_UserNotFoundException: "ユーザー名またはパスワードが正しくありません。",
  auth_error_InvalidParameterException: "ユーザー名またはパスワードが正しくありません。",
  auth_error_NotAuthorizedException: "ユーザー名またはパスワードが正しくありません。",
  auth_error_UserNotConfirmedException: "ユーザが認証されていないため、要求された操作は実行されませんでした。",
  auth_error_LimitExceededException: "パスワードの入力を連続で失敗しております。しばらく待ってからログインしてください。（最長15分）",
  auth_error_InvalidPasswordException: "パスワードは6文字以上で設定してください。",
  auth_error_PasswordIncludeSpace: "パスワードにスペースが含まれております。",
  auth_system_err: "ログインに想定外のエラーが発生しました。管理者にお問い合わせください。",
  space_analysis_save_title: "空間分析データの保存を行います。",
  space_analysis_save_message: "現在のデータ保管数：{{count}}/10",
  space_analysis_beyond_limit_title: "空間分析データ保管数の上限に達しました。 ",
  space_analysis_beyond_limit_message: "保存ボタンを押した場合、古いデータは削除されますが、本当によろしいでしょうか？<br/>削除対象：{{deleteHistory}}",
  space_analysis_history: "空間分析情報履歴",
  simulation_failed: "シミュレーションに失敗しました。管理者にお問い合わせください。",
  get_map_failed: "マップの取得に失敗しました。管理者にお問い合わせください。",
  create_err_title: "以下のユーザーの登録に失敗しました",
  create_err_tenant: "クライアント名：{{company_name}}",
  create_err_content: "・ユーザー名：{{user_name}}  メールアドレス：{{email}}",
  create_err: "挿入に失敗したユーザーがいます。CSVアップロードの画面を閉じて画面下部に表示されたメッセージをご確認ください。",
  register_processing_title: "登録処理中",
  register_processing_text: "登録処理が完了するまでしばらくお待ちください。<br>処理中はブラウザを閉じたり戻るボタンを押さないでください。",
  register_complete_title: "登録完了",
  register_complete_text: "ユーザー登録処理が正常に完了しました。",
  register_complete_fail_title: "登録失敗",
  register_complete_fail_text: "登録に失敗したユーザーが存在します。<br>登録に失敗したユーザーは入力内容を確認の上でもう一度お試しください。",
  serch_user_result_empty: "検索対象のユーザーが見つかりませんでした。",
  unexcepted_error: "予期せぬエラーが発生しました。時間を置いても解決しない場合は担当者へお問い合わせください。",
  resource_error: "応答時間が長すぎます。時間を置いても解決しない場合は担当者へお問い合わせください。",
  update_user_not_exit: "更新対象のユーザーが見つかりませんでした",
  client_not_exit: "クライアントが存在しません",
  not_match_err: "新しいパスワードと確認用のパスワードが一致しません",
  over_256_err: "パスワードは256文字以下で設定してください",
  under_6_err: "パスワードは6文字以上で設定してください",
  invalid_code_err: "認証コードが正しくありません",
  temp_pwd_expired_err: "初回ログインパスワードの有効期限が切れております。担当者へご連絡ください",
  mail_not_found: "対象のメールアドレスが存在しません",
  login_timeout: "セッションがタイムアウトしました。再度ログインしてください。",
  cant_forget_password: "このアカウントはまだ初期パスワードが設定されていません。管理者に連絡してパスワードをリセットしてください",
  same_as_temp_password_err: "仮パスワードを新しいパスワードとして利用することはできません",
};

import React, { useEffect, useState, useCallback } from "react";
import { Amplify, Auth } from "aws-amplify";
import { AuthState } from "@aws-amplify/ui-components";
import { useCookies } from "react-cookie";
import { HashRouter, Route, Routes, useNavigate } from "react-router-dom";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ClientService } from "./utils/services/client.service";
import ChangePassword from "./containers/ChangePassword";
import SurveyList from "./containers/SurveyList";
import TakeSurvey from "./containers/TakeSurvey";
import LoginPage from "./containers/LoginPage";
import { ApiService } from "./utils/services/api";
import RoomSimulation from "./containers/RoomSimulation";
import CostSimulation from "./containers/CostSimulation";
import OfficeSample from "./containers/OfficeSample";
import SurveyResults from "./containers/SurveyResults";
import SurveySetting from "./containers/SurveySetting";
import WorkshopResult from "./containers/WorkshopResult";
import TopInterviewResult from "./containers/TopInterviewResult";
import RoleType from "./utils/constants/RoleType";
import OperationAnalysis from "./containers/OperationAnalysis";
import CommuteSimulation from "./containers/CommuteSimulation";
import SpaceAnalysis from "./containers/SpaceAnalysis";
import AccountManagement from "./containers/AccountManagement";
import ResearchManagement from "./containers/ResearchManagement";
import NewPassword from "./containers/NewPassword";
import TopPage from "./containers/TopPage";
import { useStore } from "./Store";
import ComparativeReview from "./containers/ComparativeReview";
import DataSolutionOperationAnalysis from "./containers/DataSolutionOperationAnalysis";
import DataSolutionSpaceAnalysis from "./containers/DataSolutionSpaceAnalysis";
import DataSolutionPlaceStyleSurvey from "./containers/DataSolutionPlaceStyleSurvey";
import DataSolutionWorkDesignSurvey from "./containers/DataSolutionWorkDesignSurvey";
import DataSolutionWorkDesignPulseSurvey from "./containers/DataSolutionWorkDesignPulseSurvey";
import DataSolutionRanking from "./containers/DataSolutionRanking";
import DataSolutionProject from "./containers/DataSolutionProject";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

// import { HashRouter } from 'react-router-dom';

const apiService = new ApiService();

Amplify.configure({
  Auth: {
    region: "ap-northeast-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    advancedSecurityDataCollectionFlag: true,
    remember: false,
    storage: sessionStorage,
  },
  API: {
    endpoints: [
      {
        name: "wdp-api",
        endpoint: process.env.REACT_APP_API_URL,
        region: "ap-northeast-1",
      },
    ],
  },
});

const drawerWidth = 240;

// color theme setting
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#1EA1F2",
      contrastText: "#fff",
    },
    background: {
      default: "#495057 ",
      paper: "#495057 ",
    },
    divider: "#37444C",
  },
  overrides: {
    MuiButton: {
      color: "white",
    },
  },
  typography: {
    fontFamily: ["Arial"].join(","),
  },
  status: {
    danger: "orange",
  },
});

// display style setting
const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    height: "100%",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  appBar: {
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));


const App = () => {
  const { t, i18n } = useTranslation();
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const [userType, setUserType] = React.useState(null);
  const [sessionTimeout, setSessionTimeout] = useState(1800);
  const [cookies, setCookie, removeCookie] = useCookies(["idtoken"]);
  const handlerSubmit = (value) => {
    setCookie("idtoken", value, { sameSite: false, secure: true });
  };

  const getVerifier = async () => {
    try {
      await apiService.getToken();
    } catch (err) { }
  };
  const classes = useStyles();
  const [plan, setPlan] = useState(null);

  const clientService = new ClientService();
  const { tenant } = useStore();

  useEffect(() => {
    const loginTime = sessionStorage.getItem('loginTime');
    if (loginTime) {
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - parseInt(loginTime, 10);

      if (elapsedTime > sessionTimeout) {
        handleSessionTimeout();
      } else {
        const remainingTime = sessionTimeout - elapsedTime;
        setTimeout(() => handleSessionTimeout(), remainingTime * 1000);
      }
    }

    const interval = setInterval(() => {
      const loginTime = sessionStorage.getItem('loginTime');
      if (loginTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        const elapsedTime = currentTime - parseInt(loginTime, 10);

        if (elapsedTime > sessionTimeout) {
          handleSessionTimeout();
        }
      }
    }, 1000);
    const events = ['click', 'scroll', 'keydown'];
    events.forEach(event => {
      window.addEventListener(event, resetSessionTimeout);
    });
    return () => {
      clearInterval(interval);
      events.forEach(event => {
        window.removeEventListener(event, resetSessionTimeout);
      });
    };  }, [sessionTimeout])

  const resetSessionTimeout = () => {
    const loginTime = sessionStorage.getItem('loginTime');
    if (loginTime) {
      const currentTime = Math.floor(Date.now() / 1000);
      sessionStorage.setItem('loginTime', currentTime.toString());
    }
  };

  const handleSessionTimeout =  () => {
    const bc = new BroadcastChannel('auth-channel');
    const idToken = sessionStorage.getItem('idToken');
    Auth.signOut();
    sessionStorage.removeItem('loginTime');

    Swal.fire({
      title: t("login_timeout"),
      confirmButtonColor: '#D57B85',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
    }).then(() => {
      window.location.href = "/";
      bc.postMessage({ action: 'logout', token: idToken });
    });
  };
  useEffect(() => {
    Auth.currentUserInfo().then((data) => {

      // attributes属性が何故かない

      setUserType(data.attributes["custom:role"]);
    });
  }, []);

  useEffect(() => {
    getVerifier();

    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          handleLoginSuccess(user);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const getCompany = async () => {
    if (tenant) {
      const response_company = await clientService.getCompany(tenant);
      if (response_company) {
        setPlan(response_company.data.plan);
      }
    }
  }

  useEffect(() => {
    getCompany();
  }, [tenant]);

  const handleLoginSuccess = async (user) => {
    const userData = await Auth.currentUserInfo();
    setUserType(userData.attributes["custom:role"]);
    setAuthState(AuthState.SignedIn);
    setUser(user);
  };

  if (authState === AuthState.SignedIn && user?.signInUserSession) {
    const jwt = user.signInUserSession.idToken.jwtToken;
    handlerSubmit(jwt);

    return (
      <div className={classes.root}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HashRouter>
            <Routes>
              <Route
                exact
                path="/"
                element={userType == RoleType.CLIENT_MEMBER ? <SurveyList title={"surveys"} />
                  : userType == RoleType.CLIENT_LEADER && plan === "free_survey" ? <SurveyResults title={"surveys"} />
                    : <TopPage />} />

              <Route
                exact
                path="/account-management"
                element={<AccountManagement />}
              />

              <Route
                exact
                path="/research-management"
                element={<ResearchManagement />}
              />

              <Route
                exact
                path="/operation-analysis"
                element={<OperationAnalysis />}
              />

              <Route
                exact
                path="/space-analysis"
                element={<SpaceAnalysis />}
              />

              <Route
                exact
                path="/survey-results"
                element={<SurveyResults title={"surveys"} />}
              />

              <Route
                exact
                path="/survey-list"
                element={<SurveyList title={"surveys"} />}
              />

              <Route
                exact
                path="/survey-list/:survey_id/:notification_id"
                element={<TakeSurvey title={"surveys"} />}
              />

              <Route
                exact
                path="/room-simulation"
                element={<RoomSimulation />}
              />

              <Route
                exact
                path="/cost-simulation"
                element={<CostSimulation />}
              />

              <Route
                exact
                path="/commute-simulation"
                element={<CommuteSimulation />}
              />

              <Route
                exact
                path="/comparative-review"
                element={<ComparativeReview />}
              />

              <Route
                exact
                path="/workshop-setting"
                element={<SurveySetting title={"workshops"} />}
              />

              <Route
                exact
                path="/workshop-list"
                element={<SurveyList title={"workshops"} />}
              />

              <Route
                exact
                path="/workshop-list/:survey_id"
                element={<TakeSurvey title={"workshops"} />}
              />

              <Route
                exact
                path="/workshop-results"
                element={<WorkshopResult title={"workshops"} />}
              />

              <Route
                exact
                path="/top-interview-setting"
                element={<SurveySetting title={"topinterviews"} />}
              />

              <Route
                exact
                path="/list-top-interview"
                element={<SurveyList title={"topinterviews"} />}
              />

              <Route
                exact
                path="/list-top-interview/:survey_id"
                element={<TakeSurvey title={"topinterviews"} />}
              />

              <Route
                exact
                path="/top-interview-results"
                element={<TopInterviewResult title={"topinterviews"} />}
              />


              {/* TODO パスを追加 */}
              <Route
                exact
                path="/datasolution/operation-analysis"
                element={<DataSolutionOperationAnalysis />}
              />

              <Route
                exact
                path="/datasolution/space-analysis"
                element={<DataSolutionSpaceAnalysis />}
              />

              <Route
                exact
                path="/datasolution/place-style-survey"
                element={<DataSolutionPlaceStyleSurvey />}
              />

              <Route
                exact
                path="/datasolution/work-design-survey"
                element={<DataSolutionWorkDesignSurvey />}
              />

              <Route
                exact
                path="/datasolution/work-design-pulse-survey"
                element={<DataSolutionWorkDesignPulseSurvey />}
              />

              <Route
                exact
                path="/datasolution/ranking"
                element={<DataSolutionRanking />}
              />

              <Route
                exact
                path="/datasolution/project"
                element={<DataSolutionProject />}
              />


              <Route
                exact
                path="/office-sample"
                element={<OfficeSample />}
              />

              <Route
                exact
                path="/user/change-password"
                element={<NewPassword />}
              />

            </Routes>
          </HashRouter>
        </ThemeProvider>
      </div>
    );
  } else {
    return (
      <HashRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={<LoginPage handleLoginSuccess={handleLoginSuccess} />}
          />
          <Route
            exact
            path="/change-password"
            element={<ChangePassword handleLoginSuccess={handleLoginSuccess} />}
          />
        </Routes>
      </HashRouter>
    );
  }
};
export default App;

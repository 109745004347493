import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  Nav,
  Navbar,
  Form,
  NavDropdown,
  NavLink,
} from "react-bootstrap";
import logo_editable from "../assets/img/Logo1_Menu.svg";
import fixedLogo from "../assets/img/Logo2_Menu.svg";
import Search from "../assets/img/search.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "../../src/Store";
import { ClientService } from "../utils/services/client.service";
import { AgentService } from "../utils/services/agent.service";
import { FaRedo, FaUserCircle } from "react-icons/fa";
import RoleType from "../utils/constants/RoleType";
import Auth from "@aws-amplify/auth";
import "../App.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ApiService } from "../../src/utils/services/api";
import jwt_decode from "jwt-decode";
import useCompaniesInfo from "../../src/useDataFeching";

// export default function Header({ companies, setCompanies, isLoading }) {
  export default function Header() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(sessionStorage.getItem("lang") || "ja");
  const [messageShown, setMessageShown] = useState(false);
  const { tenant, setTenant } = useStore();
  const [dynamicLogoUrl, setDynamicLogoUrl] = useState();
  const [userType, setUserType] = useState();
  const [options, setOptions] = useState([]);

  const apiService = new ApiService();
  const agentService = new AgentService();
  const clientService = new ClientService();

  const { companies, setCompanies, isLoading } = useCompaniesInfo();

  const getManagingAgent = async () => {
    if (tenant) {
      const response_company = await clientService.getCompany(tenant);
      const managingAgentId = response_company.data.agentId;
      if (managingAgentId) {
        const response_agent = await agentService.getAgent(managingAgentId);
        if (response_agent.data.logoUrl) {
          setDynamicLogoUrl(response_agent.data.logoUrl)
        }
      }
    }
  }

  // tenantのリストをバックで持っておくための処理
  const listCompany = (async () => {

    if (userType === RoleType.CLIENT_LEADER || userType === RoleType.CLIENT_MEMBER) {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setTenant(decoded["cognito:groups"][0]);
    }

    if (userType === RoleType.ADMIN || userType === RoleType.AGENT || userType === RoleType.CONSULTANT) {
      const arr = (companies && companies.map((item) => {
        return {
          label: item.companyName,
          value: item.tenantId,
        };
      })) || [];
      setOptions(arr);
    }
  });

  useEffect(() => {
    if (companies.length > 0 && userType) {
      listCompany()
    }
  }, [companies, userType]);

  useEffect(() => {
    (async () => {
      const data = await Auth.currentUserInfo();
      if (data) {
        setUserType(data.attributes["custom:role"]);
      }
    })();
  }, [userType]);

  // useEffect(() => {
  //   const filteredTenant = tenant && Companies ? Companies.filter(item => item.tenantId.includes(tenant)) : {};
  //   setDynamicLogoUrl(filteredTenant[0]?.logoUrl)
  // }, [tenant])

  // TODO
  useEffect(() => {
    getManagingAgent()
  }, [tenant])


  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };

  const filterOption = (option, searchValue) => {
    if (searchValue === "") {
      return false;
    } else {
      const parsedValue = searchValue.trim().toLowerCase();
      return (
        option.value.toLowerCase().includes(parsedValue) ||
        option.label.toLowerCase().includes(parsedValue)
      );
    }
  };

  const selectboxCustomStyles = {
    // control represent the select component
    control: (provided) => ({
      ...provided,
      width: '300px'
    })
  }

  return (
    <Navbar className="fixed-top" bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand>
          <div class="d-flex">
            <img src={dynamicLogoUrl} height="40px" className="w-auto mx-2 align-self-end" />
            <img src={fixedLogo} height="25px" className="w-auto align-self-end" />
          </div>
        </Navbar.Brand>
        <Nav className="justify-content-center align-items-center" >
          {(userType === RoleType.ADMIN ||
            userType === RoleType.AGENT ||
            userType === RoleType.CONSULTANT) && (
              <div>
                <div className="d-flex align-items-center tenant-option">
                  {messageShown && (
                    <p className="align-self-end fnt-16 fw-bold mx-3 text-success text-blink">
                      {t("tenants_refreshed")}
                    </p>
                  )}

                  <button
                    className="bg-white border-0 bg-transparent py-3 me-2"
                    onClick={async () => {
                      await listCompany();
                      setMessageShown(true);
                      setTimeout(() => setMessageShown(false), 3000);
                    }}
                  >
                  </button>
                  <NavLink className="mx-2 pe-2 test">
                    {!!options?.length && (
                      <Select
                        styles={selectboxCustomStyles}
                        value={options.find((option) => option.value === tenant)}
                        className="me-2"
                        options={options}
                        isClearable={true}
                        placeholder={t("search")}
                        onChange={(e) => {
                          setTenant(e?.value || "");
                        }}
                        filterOption={filterOption}
                        isSearchable={true}
                      />
                    )}
                  </NavLink>
                </div>
                <>
                  <Form>
                    <div className="d-flex lang-switch container-main mt-2 me-4">
                      <div className="single-switch">
                        <input
                          type="radio"
                          className="d-none"
                          id="lang-1"
                          name="language"
                          value={lang}
                          checked={lang === "ja"}
                          onChange={() => {
                            changeLang("ja");
                          }}
                        />
                        <label htmlFor="lang-1">日本語</label>
                      </div>
                      <div className="single-switch">
                        <input
                          type="radio"
                          id="lang-2"
                          className="d-none"
                          name="language"
                          value={lang}
                          checked={lang === "en"}
                          onChange={() => {
                            changeLang("en");
                          }}
                        />
                        <label htmlFor="lang-2">English</label>
                      </div>
                    </div>
                  </Form>
                </>
              </div>
            )}
          <>
            <Form>
              <div className="d-flex lang-switch container-main mt-2 me-4">
                <div className="single-switch">
                  <input
                    type="radio"
                    className="d-none"
                    id="lang-1"
                    name="language"
                    value={lang}
                    checked={lang === "ja"}
                    onChange={() => {
                      changeLang("ja");
                    }}
                  />
                  <label htmlFor="lang-1">日本語</label>
                </div>
                <div className="single-switch">
                  <input
                    type="radio"
                    id="lang-2"
                    className="d-none"
                    name="language"
                    value={lang}
                    checked={lang === "en"}
                    onChange={() => {
                      changeLang("en");
                    }}
                  />
                  <label htmlFor="lang-2">English</label>
                </div>
              </div>
            </Form>
          </>

          <div className="mt-2">
            <Link to="/user/change-password">
              <FaUserCircle size={30} color="#44403f" />
            </Link>
          </div>

        </Nav>
      </Container>
    </Navbar>
  )
}

import React, { useState, useEffect } from "react";
import { FileService } from "../../utils/services/file.service";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { ApiService } from "../../utils/services/api";
import { useStore } from "../../Store";
import * as Yup from "yup";
import axios from "axios";
import { SurveyService } from "../../utils/services/survey.service";
import Loader from "../Loader";
import { FaAngleRight, FaCloudUploadAlt } from "react-icons/fa";
import DatePicker, { registerLocale } from 'react-datepicker'
import { Formik, Field, Form, useField, useFormikContext, ErrorMessage } from 'formik';
import moment from 'moment';
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja'
registerLocale('ja', ja)

const apiService = new ApiService();
const fileService = new FileService();
const surveyService = new SurveyService();

const SurveyNotification = ({ survey_type }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("");
  const { tenant } = useStore();
  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };
  const [formValues, setFormValues] = useState(null);
  const initialValues = {
    "monthly_interval": null,
    "week": null,
    "day_of_week": null,
    "date": null,
  }

  const validationSchema = Yup.object({
    monthly_interval: Yup.number().required(t("monthly_interval_is_required")).typeError(t("monthly_interval_is_required")),
    week: Yup.number().required(t("week_is_required")).typeError(t("week_is_required")),
    day_of_week: Yup.number().required(t("day_of_week_is_required")).typeError(t("day_of_week_is_required")),
    date: Yup.string().required(t("date_is_required")).typeError(t("plan_is_required")),
  });


  useEffect(() => {
    if (tenant) {
      getSurveyNotificationSetting()
    }
  }, [tenant]);

  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9)
  }

  const toUtcIso8601str = (momentInstance) => {
    return momentInstance
      .clone()
      .utc()
      .format('YYYY-MM-DDTHH:mm:00Z')
  }

  const MyDatePicker = ({ name = "" }) => {
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    return (
      <div>
        <DatePicker
          {...field}
          className="survey-notification-modal-input-area"
          selected={value}
          locale="ja"
          dateFormat="yyyy/MM/dd"
          onChange={(date) => setValue(date)}
        />
      </div>
    );
  };

  const getSurveyNotificationSetting = async () => {
    const response = await surveyService.getSurveyNotificationSetting(tenant, survey_type);
    if (response && response.data.length > 0) {
      console.log(response)
      let new_data = response.data[0]
      console.log(new_data)
      new_data["date"] = new Date(new_data["date"])
      setFormValues(new_data)
    } else {
      setFormValues(initialValues)
    }
  }

  const postSurveyNotificationSetting = async (e) => {
    const setting = {
      date: e.date,
      monthly_interval: +e.monthly_interval,
      day_of_week: +e.day_of_week,
      week: +e.week,
    };
    const response = await surveyService.postSurveyNotificationSetting(setting, tenant, survey_type);
    if (response) {
      getSurveyNotificationSetting()
    }
  };

  return (
    <div>
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {(formik) => {
          return (
            <Form className="clr-14213D p-1 h-100 ">
              <Grid container spacing={4} >
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="flex-start">
                    <p className="survey-notification-modal-title">{t(survey_type)}</p>
                  </Grid>

                  {survey_type === "deep_survey" && (
                    <Grid container spacing={2} justifyContent="flex-start">
                      <p className="survey-notification-modal-comment">{t("short_survey")}{t("survey_setting_comment")}</p>
                    </Grid>
                  )}

                  {survey_type === "short_survey" && (
                    <Grid container spacing={2} justifyContent="flex-start">
                      <p className="survey-notification-modal-comment">{t("deep_survey")}{t("survey_setting_comment")}</p>
                    </Grid>
                  )}

                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={1}>
                      <Field
                        as="select"
                        type="text"
                        name="monthly_interval"
                        className="survey-notification-modal-input-area"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "monthly_interval",
                            e.target.value || ""
                          )
                        }
                        placeholder={t("monthly_interval")}
                      >
                        <option value="DEFAULT" selected disabled>{""}</option>
                        <option value={1}>{"1"}</option>
                        <option value={2}>{"2"}</option>
                        <option value={3}>{"3"}</option>
                        <option value={6}>{"6"}</option>
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <p className="survey-notification-modal-text-area">{t("monthly_interval")}</p>
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        as="select"
                        type="text"
                        name="week"
                        className="survey-notification-modal-input-area"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "week",
                            e.target.value || ""
                          )
                        }
                        placeholder={t("week")}
                      >
                        <option value="DEFAULT" selected disabled>{t("select_week")}</option>
                        <option value={1}>{t("first_week")}</option>
                        <option value={2}>{t("second_week")}</option>
                        <option value={3}>{t("third_week")}</option>
                        <option value={4}>{t("fourth_week")}</option>
                        <option value={0}>{t("last_week")}</option>
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        as="select"
                        type="text"
                        name="day_of_week"
                        className="survey-notification-modal-input-area"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "day_of_week",
                            e.target.value || ""
                          )
                        }
                        placeholder={t("day_of_week")}
                      >
                        <option value="DEFAULT" selected disabled>{t("select_day_of_week")}</option>
                        <option value={0} >{t("mon")}</option>
                        <option value={1} >{t("tue")}</option>
                        <option value={2} >{t("wed")}</option>
                        <option value={3} >{t("thu")}</option>
                        <option value={4} >{t("fri")}</option>
                        <option value={5} >{t("sat")}</option>
                        <option value={6} >{t("sun")}</option>
                      </Field>
                    </Grid>
                    <Grid item xs={1}>
                      <p className="survey-notification-modal-text-area">／</p>
                    </Grid>
                    <Grid item xs={2}>
                      <MyDatePicker name="date" />
                    </Grid>

                    <Grid item xs={1.5}>
                      <p className="survey-notification-modal-text-area">{t("start")}</p>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="center">
                    <button
                      type="button"
                      className="btn btn-green op-100"
                      disabled={!formik.isValid}
                      onClick={() => {
                        postSurveyNotificationSetting(formik.values)
                        Swal.fire({
                          title: t("survey_notification_setting_saved"),
                          confirmButtonColor: "#D57B85",
                          confirmButtonText: "OK",
                          allowOutsideClick: false,
                        })
                      }
                      }
                    >
                      {t("save")}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div >
  );
};

export default SurveyNotification;

import React, { useState, useEffect } from "react";
import Layout from "./layout/Layout";
import { ApiService } from "../utils/services/api";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { AnalysisService } from "../utils/services/analysis.service";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import Auth from "@aws-amplify/auth";
import Swal from "sweetalert2";
import "./shared/Fileupload/Analysis.scss";
import { useStore } from "../Store";
import { FaRedo } from "react-icons/fa";
import RoleType from "../utils/constants/RoleType";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import { industry_en, industry_en_2, industry_ja, industry_ja_2 } from "../utils/constants/IndustryType";
import CustomSelect from "../components/CustomSelect";
import { Row, Col } from "react-flexbox-grid";
import { IconContext } from 'react-icons'
import { AiOutlineRight } from 'react-icons/ai'
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja'

registerLocale('ja', ja)

const apiService = new ApiService();
const analysisService = new AnalysisService();
const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

// # TODO
export default function DataSolutionProject(formik) {
  const [User, setUser] = useState("");
  const [_, setLang] = useState("");
  const [formValues, setFormValues] = useState({});
  const { t, i18n } = useTranslation();
  const [userType, setUserType] = React.useState("user");
  const { tenant } = useStore();
  const location = useLocation();
  const [editAdmin, setEditAdmin] = useState(false);
  const [cognitoTenant, setCognitoTenant] = useState('')
  const [adminLoader, setAdminLoader] = useState(false)
  const [area1Options, setArea1Options] = useState([]);
  const [area2Options, setArea2Options] = useState([]);
  const [area3Options, setArea3Options] = useState([]);
  const [isloadingArea1Options, setIsloadingArea1Options] = useState(false);
  const [isloadingArea2Options, setIsloadingArea2Options] = useState(false);
  const [isloadingArea3Options, setIsloadingArea3Options] = useState(false);
  const [isLoadingOccupations, setIsLoadingOccupations] = useState(false);
  const validationSchema = Yup.object({
    startAt: Yup.string().nullable().typeError(t("invalid_type")),
    endAt: Yup.string().nullable().typeError(t("invalid_type")),
    establishedYearFrom: Yup.string().nullable()
      .matches(/^\d+$/, t("invalid_type")) // 数字のみを許可し、それ以外の場合にエラーメッセージを表示
      .test("positive", t("invalid_type"), (value) => {
        // 追加のカスタムバリデーションルールを適用
        if (value && parseInt(value, 10) >= 1800 && parseInt(value, 10) <= 2100) {
          return true;
        }
        if (!value) {
          return true;
        }
        return false;
      }),
    establishedYearTo: Yup.string().nullable()
      .matches(/^\d+$/, t("invalid_type")) // 数字のみを許可し、それ以外の場合にエラーメッセージを表示
      .test("positive", t("invalid_type"), (value) => {
        // 追加のカスタムバリデーションルールを適用
        if (value && parseInt(value, 10) >= 1800 && parseInt(value, 10) <= 2100) {
          return true;
        }
        if (!value) {
          return true;
        }
        return false;
      }),
    scaleFrom: Yup.string().nullable()
      .matches(/^\d+$/, t("invalid_type")) // 数字のみを許可し、それ以外の場合にエラーメッセージを表示
      .test("positive", t("invalid_type"), (value) => {
        // 追加のカスタムバリデーションルールを適用
        if (value && parseInt(value, 10) > 0) {
          return true;
        }
        if (!value) {
          return true;
        }
        return false;
      }),
    scaleTo: Yup.string().nullable()
      .matches(/^\d+$/, t("invalid_type")) // 数字のみを許可し、それ以外の場合にエラーメッセージを表示
      .test("positive", t("invalid_type"), (value) => {
        // 追加のカスタムバリデーションルールを適用
        if (value && parseInt(value, 10) > 0) {
          return true;
        }
        if (!value) {
          return true;
        }
        return false;
      }),
  });

  const MyDatePicker = ({ name = "" }) => {
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    // 空の文字列値をnullに変換する
    const processedValue = value === "" ? undefined : value;

    return (
      <div>
        <DatePicker
          {...field}
          className="param-input"
          selected={processedValue}
          locale="ja"
          dateFormat="yyyy/MM/dd"
          placeholderText="yyyy/mm/dd"
          onChange={(date) => setValue(date)}
        />
      </div>
    );
  };

  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };


  const embed_options = {
    url: "",
    container: "",
    // scrolling: "yes",
    // height: "AutoFit",
    // resizeHeightOnSizeChangedEvent: false,
    // iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
    // width: "100%",
    // withIframePlaceholder: true,
    // // locale: "en-US",
    footerPaddingEnabled: true,
    // // sheetId: 'YOUR_SHEETID',  // use this option to specify initial sheet id to load for the embedded dashboard
    // sheetTabsDisabled: true, // use this option to enable or disable sheet tab controls in dashboard embedding
    // printEnabled: true, // use this option to enable or disable print option for dashboard embedding
    // undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
    // resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
    // // defaultEmbeddingVisualType: TABLE // this option only applies to experience embedding and will not be used for dashboard embedding
  };

  const getAreaOptions = async (area1, area2) => {
    if (area1.length === 1) {
      area1 = area1[0];
    } else {
      area1 = '';
    }

    if (area2.length === 1) {
      area2 = area2[0];
    } else {
      area2 = '';
    }

    const response = await analysisService.getAreaOptions(area1, area2);
    if (response) {
      return response.data.options;
    }
  };

  const handleFormikChange = async (e) => {
    const { name, value } = e.target;

    if (name === 'area1') {
      setIsloadingArea2Options(true);
      const options = await getAreaOptions([value], []);
      setArea2Options(options);
      setIsloadingArea2Options(false);
    } else if (name === 'area2') {
      setIsloadingArea3Options(true);
      const options = await getAreaOptions([], [value]);
      setArea3Options(options);
      setIsloadingArea3Options(false);
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      setIsloadingArea1Options(true);
      const options = await getAreaOptions([], []);
      setArea1Options(options);
      setIsloadingArea1Options(false);
    };
    fetchOptions();
  }, []);

  const getQuickSightAnalysis = async (e) => {
    console.log("form input")
    console.log(e)

    document.querySelector('#experience-container').innerHTML = ``;
    const {
      createEmbeddingContext,
    } = QuickSightEmbedding;

    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata);
      },
    });

    const params = {
      user: User,
      analysis: location.pathname.split("/")[1] + "-" + location.pathname.split("/")[2],
      tenant: cognitoTenant,
      tenant_dashboard: tenant,
      userType: userType,
    };

    console.log("params")
    console.log(params)

    const res = await analysisService.getQuickSightDashboardAnalysis(params);

    const frameOptions = Object.assign({}, embed_options);
    frameOptions["url"] = res.data.EmbedUrl
    frameOptions["container"] = document.getElementById("experience-container");

    const parameters = [
      {
        Name: 'tenant',
        Values: [tenant]
      },
      {
        Name: 'startAt',
        Values: [e.startAt],
      },
      {
        Name: 'endAt',
        Values: [e.endAt]
      },
      {
        Name: 'industry1',
        Values: [e.industry1_1, e.industry1_2]
      },
      {
        Name: 'industry2',
        Values: [
          ...(Array.isArray(e.industry2_1) ? e.industry2_1 : []),
          ...(Array.isArray(e.industry2_2) ? e.industry2_2 : [])
        ]
      },
      {
        Name: 'establishedYearFrom',
        Values: [e.establishedYearFrom]
      },
      {
        Name: 'establishedYearTo',
        Values: [e.establishedYearTo]
      },
      {
        Name: 'area1',
        Values: (Array.isArray(e.area1) ? e.area1 : [])
      },
      {
        Name: 'area2',
        Values: (Array.isArray(e.area2) ? e.area2 : [])
      },
      {
        Name: 'area3',
        Values: (Array.isArray(e.area3) ? e.area3 : [])
      },
      {
        Name: 'scaleFrom',
        Values: [e.scaleFrom]
      },
      {
        Name: 'scaleTo',
        Values: [e.scaleTo]
      }
    ]

    const contentOptions = {
      parameters: parameters.filter((param) => {
        // Remove if all elements of the list are undefined or null
        // QuickSight returns no data if we send empty parameters
        return !param.Values.every((value) => value === undefined || value === null);
      }),
    }

    console.log("contentOptions")
    console.log(contentOptions)

    const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);

  }

  useEffect(() => {
    if (i18n.language) {
      changeLang(i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    getVerifier();
  }, [location]);

  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setUserType(decoded['custom:role'])
      setUser(decoded["cognito:username"])
      setCognitoTenant(decoded['cognito:groups'][0])
    } catch (err) { }
  };

  const getAdminComments = async () => {
    const params = {
      analysis: location.pathname.split("/")[1],
    };
    const response = await analysisService.getAdminComments(params, tenant);
    if (response) {
      formValues["admin_comments"] = response.data.comments;
      setFormValues(formValues);
    }
  };

  const saveAdminComments = async (e) => {
    setAdminLoader(true);
    const data = {
      comments: e.admin_comments,
      analysis: location.pathname.split("/")[1],
    };
    const response = await analysisService.postAdminComments(data, tenant);
    if (response) {
      setAdminLoader(false);
      getAdminComments();
      Swal.fire({
        title: t("comments_submitted_successfuly"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
      setEditAdmin(false);
    }
  };

  const onEdit = async (formikType) => {
    if (formikType === "comments") {
      setEditAdmin(true);
    }
  };

  return (
    <Layout>
      <div className="col-12 px-2 child-layout">
        {/* TODO */}
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(formik) => {
            return (
              <Form className="text-purple p-1 h-100">
                <Grid container >

                  {/* TODO　タイトルと説明書き変更 */}
                  {/* TITLE */}
                  <Grid item xs={4} className="grid-padding">
                    <div className="d-flex justify-content-between" >
                      <div className="p-1 d-flex flex-column">
                        <div className="d-flex">
                          <h3 className="mb-0 text-body">
                            <b>{t("project")}</b>
                          </h3>
                        </div>
                        <p className="text-body mb-0">{t("project")}</p>
                      </div>
                    </div>
                  </Grid>

                  {/* ADMIN COMMENT */}
                  <Grid item xs={7} className="grid-padding">
                    <label
                      type="text-in"
                      className="clr-14213D pt-1"
                      htmlFor="admin_comments"
                    >
                      <h4>
                        <b>{t("admin_comments")}</b>
                      </h4>
                    </label>
                    <Field
                      as="textarea"
                      id="admin_comments"
                      name="admin_comments"
                      className="admin_comments"
                      rows={3}
                      disabled={
                        ![RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                          userType
                        ) || !editAdmin
                      }
                      placeholder={t("admin_comments")}
                    />
                  </Grid>

                  {/* EDIT BUTTON */}
                  <Grid item xs={1} className="grid-padding">
                    {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                      userType
                    ) && (
                        <div className=" align-self-end text-nowrap">
                          {!editAdmin ? (
                            <button
                              className="btn btn-14213D w-100"
                              onClick={() => onEdit("comments")}
                            >
                              {t("edit")}
                            </button>
                          ) : adminLoader ? (
                            <Loader />
                          ) : (
                            <button
                              className="btn btn-14213D w-100"
                              onClick={() => saveAdminComments(formik.values)}
                            >
                              {t("save")}
                            </button>
                          )}
                        </div>
                      )}
                    <div className="text-danger">
                      <ErrorMessage
                        name="admin_comments"
                        className="text-danger"
                      />
                    </div>
                    <button
                      className="mt-3 mb-0 bg-white border-0"
                      onClick={(e) => {
                        getQuickSightAnalysis(formik.values);
                        getAdminComments();
                      }}
                    >
                      <h4 className="mt-0 mb-0 text-dark p-0">
                        <FaRedo />
                      </h4>
                    </button>
                  </Grid>

                  <Grid item xs={4} className="grid-padding" >
                    <Grid container className="gap-2" justifyContent="flex-start" alignItems="flex-start">

                      {/* TODO */}
                      {/* PARAMS */}
                      <Grid item xs={12} >
                        <Grid container className="container-params-room-simulation pb-3" direction="row" >
                          <Grid item xs={12} >
                            <h5 className="mt-1 ms-1">
                              <b style={{ "color": "#454648" }}>{t("filter_condition")}</b>
                            </h5>
                          </Grid>


                          {/* 開始日 */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="start_at"
                            >
                              <b> {t("start_at")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            <MyDatePicker name="startAt" />
                            <div className="text-danger mb-1 ">
                              <ErrorMessage
                                name="start_at"
                                className="text-danger"
                              />
                            </div>
                          </Grid>

                          {/* 終了日 */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="end_at"
                            >
                              <b> {t("end_at")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            <MyDatePicker name="endAt" />
                            <div className="text-danger mb-1 ">
                              <ErrorMessage
                                name="end_at"
                                className="text-danger"
                              />
                            </div>
                          </Grid>

                          {/* 業種(大分類)_1 */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="industry1_1"
                            >
                              <b> {t("industry1_1")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            <Field
                              as="select"
                              type="text"
                              name="industry1_1"
                              className="param-input"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "industry1_1",
                                  e.target.value || ""
                                )
                              }
                              placeholder={t("industry1_1")}
                            >
                              <option value="" selected disabled>{t("industry1_1")}{t("select_param")}</option>
                              <>
                                {industry_ja_2.map((e) => e.industry1)
                                  .filter((value, index, array) => array.indexOf(value) === index)
                                  .map((e) => (
                                    <option key={e} value={e}>
                                      {e}
                                    </option>))
                                }
                              </>
                            </Field>
                            <div className="text-danger mb-1 ">
                              <ErrorMessage
                                name="industry1_1"
                                className="text-danger"
                              />
                            </div>
                          </Grid>

                          {/* 業種（中分類）1 */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="industry2_1"
                            >
                              <b> {t("industry2_1")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            {formik.values.industry1_1 ? (
                              <>
                                {i18n.language === "en" ? (
                                  <>
                                    {industry_en_2.filter(industry => industry.industry1 === formik.values.industry1_1).map((element, index) => (
                                      <div className="w-100">
                                        <Field
                                          type="checkbox"
                                          name="industry2_1"
                                          value={element.industry2}
                                        />
                                        <span className="ms-2 clr-14213D fnt-14">
                                          {" "}
                                          {element.industry2}
                                        </span>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>{industry_ja_2.filter(industry => industry.industry1 === formik.values.industry1_1).map((element, index) => (
                                    <div className="w-100">
                                      <Field

                                        type="checkbox"
                                        name="industry2_1"
                                        value={element.industry2}
                                      />
                                      <span className="ms-2 clr-14213D fnt-14">
                                        {" "}
                                        {element.industry2}
                                      </span>
                                    </div>
                                  ))}
                                  </>
                                )
                                }
                              </>
                            ) : (
                              <>
                                <div className="clr-14213D fnt-14 ">
                                  {t("select_industry1")}
                                </div>
                              </>
                            )}
                          </Grid>


                          {/* 業種大分類(2) */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="industry1_2"
                            >
                              <b> {t("industry1_2")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            <Field
                              as="select"
                              type="text"
                              name="industry1_2"
                              className="param-input"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "industry1_2",
                                  e.target.value || ""
                                )
                              }
                              placeholder={t("industry1_2")}
                            >
                              <option value="" selected disabled>{t("industry1_2")}{t("select_param")}</option>
                              <>
                                {industry_ja_2.map((e) => e.industry1)
                                  .filter((value, index, array) => array.indexOf(value) === index)
                                  .map((e) => (
                                    <option key={e} value={e}>
                                      {e}
                                    </option>))
                                }
                              </>
                            </Field>
                            <div className="text-danger mb-1 ">
                              <ErrorMessage
                                name="industry1_2"
                                className="text-danger"
                              />
                            </div>
                          </Grid>


                          {/* 業種中分類2 */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="industry2_2"
                            >
                              <b> {t("industry2_2")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            {formik.values.industry1_2 ? (
                              <>
                                {i18n.language === "en" ? (
                                  <>
                                    {industry_en_2.filter(industry => industry.industry1 === formik.values.industry1_2).map((element, index) => (
                                      <div className="w-100">
                                        <Field
                                          type="checkbox"
                                          name="industry2_2"
                                          value={element.industry2}
                                        />
                                        <span className="ms-2 clr-14213D fnt-14">
                                          {" "}
                                          {element.industry2}
                                        </span>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>{industry_ja_2.filter(industry => industry.industry1 === formik.values.industry1_2).map((element, index) => (
                                    <div className="w-100">
                                      <Field

                                        type="checkbox"
                                        name="industry2_2"
                                        value={element.industry2}
                                      />
                                      <span className="ms-2 clr-14213D fnt-14">
                                        {" "}
                                        {element.industry2}
                                      </span>
                                    </div>
                                  ))}
                                  </>
                                )
                                }
                              </>
                            ) : (
                              <>
                                <div className="clr-14213D fnt-14 ">
                                  {t("select_industry1")}
                                </div>
                              </>
                            )}
                          </Grid>

                          {/* 設立年（最小） */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="establieshed_year_from"
                            >
                              <b> {t("establieshed_year_from")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            <Field
                              type="text"
                              id="establishedYearFrom"
                              name="establishedYearFrom"
                              className="param-input"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "establishedYearFrom",
                                  e.target.value || ""
                                )
                              }
                              placeholder={t("establieshed_year_from") + t("input_param")}
                            />
                            <div className="text-danger mb-1 ">
                              <ErrorMessage
                                name="establishedYearFrom"
                                className="text-danger"
                              />
                            </div>
                          </Grid>

                          {/* 設立年（最大） */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="establieshed_year_to"
                            >
                              <b> {t("establieshed_year_to")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            <Field
                              type="text"
                              id="establishedYearTo"
                              name="establishedYearTo"
                              className="param-input"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "establishedYearTo",
                                  e.target.value || ""
                                )
                              }
                              placeholder={t("establieshed_year_to") + t("input_param")}
                            />
                            <div className="text-danger mb-1 ">
                              <ErrorMessage
                                name="establishedYearTo"
                                className="text-danger"
                              />
                            </div>
                          </Grid>

                          {/* エリア１ */}
                          <Grid container style={{ height: '100px' }}>
                            <Grid item xs={5}>
                              <label className="clr-14213D fnt-14 param" htmlFor="area1">
                                <b> {t('area1')}</b>
                              </label>
                            </Grid>
                            <Grid item xs={7}>
                              {isloadingArea1Options ? (
                                <Loader />
                              ) : (
                                <CustomSelect
                                  name="area1"
                                  options={area1Options.map(option => ({ label: t(option), value: option }))}
                                  isMulti={true}
                                  value={formik.values.area1}
                                  onChange={(value) => {
                                    formik.setFieldValue("area1", value);
                                    handleFormikChange({ target: { name: "area1", value: value } });
                                  }}
                                  placeholder={t('area1') + t('select_param')}
                                  className="param-input"
                                />
                              )}
                              <div className="text-danger mb-1">
                                <ErrorMessage name="area1" className="text-danger" />
                              </div>
                            </Grid>
                          </Grid>

                          {/* エリア２ */}
                          {formik.values.area1 && formik.values.area1.length === 1 && (
                            <Grid container style={{ height: '100px' }}>
                              <Grid item xs={5}>
                                <label type="text" className="clr-14213D fnt-14 param" htmlFor="area2">
                                  <b> {t('area2')}</b>
                                </label>
                              </Grid>
                              <Grid item xs={7}>
                                {isloadingArea2Options ? (
                                  <Loader /> // 条件がtrueの場合にLoaderを表示
                                ) : (
                                  <CustomSelect
                                    name="area2"
                                    options={area2Options.map(option => ({ label: t(option), value: option }))}
                                    isMulti={true}
                                    value={formik.values.area2}
                                    onChange={(value) => {
                                      formik.setFieldValue("area2", value);
                                      handleFormikChange({ target: { name: "area2", value: value } });
                                    }}
                                    placeholder={t('area2') + t('select_param')}
                                    className="param-input"
                                  />
                                )}
                                <div className="text-danger mb-1 ">
                                  <ErrorMessage name="area2" className="text-danger" />
                                </div>
                              </Grid>
                            </Grid>
                          )}

                          {/* エリア３ */}
                          {formik.values.area2 && formik.values.area2.length === 1 && (
                            <Grid container style={{ height: '100px' }}>
                              <Grid item xs={5}>
                                <label type="text" className="clr-14213D fnt-14 param" htmlFor="area3">
                                  <b> {t('area3')}</b>
                                </label>
                              </Grid>
                              <Grid item xs={7}>
                                {isloadingArea3Options ? (
                                  <Loader /> // 条件がtrueの場合にLoaderを表示
                                ) : (
                                  <CustomSelect
                                    name="area3"
                                    options={area3Options.map(option => ({ label: t(option), value: option }))}
                                    isMulti={true}
                                    value={formik.values.area3}
                                    onChange={(value) => {
                                      formik.setFieldValue("area3", value);
                                      handleFormikChange({ target: { name: "area3", value: value } });
                                    }}
                                    placeholder={t('area3') + t('select_param')}
                                    className="param-input"
                                  />
                                )}
                                <div className="text-danger mb-1 ">
                                  <ErrorMessage name="area3" className="text-danger" />
                                </div>
                              </Grid>
                            </Grid>
                          )}

                          {/* 規模（最小） */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="scaleFrom"
                            >
                              <b> {t("scaleFrom")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            <Field
                              type="text"
                              name="scaleFrom"
                              className="param-input"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "scaleFrom",
                                  e.target.value || ""
                                )
                              }
                              placeholder={t("scaleFrom") + "　" + t("input_param")}
                            />
                            <div className="text-danger mb-1 ">
                              <ErrorMessage
                                name="scaleFrom"
                                className="text-danger"
                              />
                            </div>
                          </Grid>

                          {/* 規模（最大） */}
                          <Grid item xs={5} >
                            <label
                              type="text"
                              className="clr-14213D fnt-14 param"
                              htmlFor="scaleTo"
                            >
                              <b> {t("scaleTo")}</b>
                            </label>
                          </Grid>
                          <Grid item xs={7} >
                            <Field
                              type="text"
                              name="scaleTo"
                              className="param-input"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "scaleTo",
                                  e.target.value || ""
                                )
                              }
                              placeholder={t("scaleTo") + "　" + t("input_param")}
                            />
                            <div className="text-danger mb-1 ">
                              <ErrorMessage
                                name="scaleTo"
                                className="text-danger"
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* ACTION BUTTONS */}
                      <Grid item xs={12} >
                        <Grid container direction="row" justifyContent="center" spacing={1}>

                          <Grid item xs={12} >
                            <Grid container direction="row" justifyContent="center">
                              <button
                                type="button"
                                className="btn-simulate"
                                disabled={!formik.isValid}
                                onClick={() => getQuickSightAnalysis(formik.values)}
                              >
                                <b>{t("do")}</b>
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>

                  {/* DASHBOARD EMBEDDING */}
                  <Grid item xs={8} className="grid-padding" >
                    <div id="iframeBlock">
                      <div className="iframe-withoutTab">
                        <div className="iframeBody">
                          <div id="experience-container" className="container-dashboard-embedding"></div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div >
    </Layout >
  )
}
import React, { useState, useEffect, useLayoutEffect } from "react";
import { FaAngleRight, FaCloudUploadAlt } from "react-icons/fa";
import { saveAs } from "file-saver";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./shared/Fileupload/Analysis.scss";
import { useStore } from "../Store";
import { AnalysisService } from "../utils/services/analysis.service";
import TimestampFormatDescription from "../assets/img/TimestampFormatDescription.png";
import Swal from "sweetalert2";
import { FileService } from "../utils/services/file.service";

const analysisService = new AnalysisService();

const CsvManualSettingModal = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();

  const [columnUserId, setColumnUserId] = useState(null);
  const [columnTimestamp, setColumnTimestamp] = useState(null);
  const [timestampFormat, setTimestampFormat] = useState(null);

  const [inputFileMetaInfo, setInputFileMetaInfo] = useState([]);
  const { tenant } = useStore();

  const fileService = new FileService();

  useEffect(() => {
    getInputFileMetaInfoOperationAnalysis()
    getInputSettingOperationAnalysis()
  }, []);

  const getInputFileMetaInfoOperationAnalysis = async () => {
    const response = await analysisService.getInputFileMetaInfoOperationAnalysis(tenant, "specify_method_name_here")
    if (response) {
      setInputFileMetaInfo(response.data);
    }
  };

  const downloadInputFileOperationAnalyis = async (key) => {
    const {
      data: { url: downloadUrl },
    } = await fileService.getDownloadUrl(key);
    saveAs(downloadUrl, key);
  }

  const deleteInputFileOperationAnalyis = async (key) => {
    const response = await fileService.deleteFile(key);
    if (response) {
      getInputFileMetaInfoOperationAnalysis()
    }
  };

  const getInputSettingOperationAnalysis = async () => {
    const response = await analysisService.getInputSettingOperationAnalysis(tenant, "csvManual")
    if (response.data) {
      setColumnUserId(response.data.column_userId)
      setColumnTimestamp(response.data.column_timestamp)
      setTimestampFormat(response.data.timestamp_format)
    }
  }

  const setResourceOperationAnalysis = async () => {
    const response = await analysisService.setResourceOperationAnalysis(tenant)
  }

  const postInputSettingOperationAnalysis = async () => {
    const setting = {
      "column_userId": columnUserId,
      "column_timestamp": columnTimestamp,
      "timestamp_format": timestampFormat
    }

    const response = await analysisService.postInputSettingOperationAnalysis(tenant, "csvManual", setting)
    if (response) {
      getInputFileMetaInfoOperationAnalysis()
      getInputSettingOperationAnalysis()
      setResourceOperationAnalysis()
    }
    Swal.fire({
      title: t("setting_updated"),
      confirmButtonColor: "#D57B85",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    })
  };

  const handleUserIdColumnChange = (e) => {
    setColumnUserId(e.target.value);
  }

  const handleTimestampColumnChange = (e) => {
    setColumnTimestamp(e.target.value);
  }

  const handleTimestampFormatChange = (e) => {
    setTimestampFormat(e.target.value);
  }

  return (
    <Modal
      className="scrollbar-c9c9ca operational-setting-sub-modal"
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <h4 className="clr-14213D mb-0 "><b>{t("manulaInput")}</b></h4> :
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column clr-14213D operational-analysis-setting-tables">
          <div id="operational-table">
            <table className="table clr-14213D w-100 ">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "60%", height: "20%" }}>
                    <button
                      type="button"
                      className={` btn`}
                    >
                      {t("fileName")}
                    </button>
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    <button
                      type="button"
                      className={` btn`}
                    >
                      {t("uploadDate")}
                    </button>
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    <button
                      type="button"
                      className={` btn`}
                    >
                      {t("action")}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody  >
                {inputFileMetaInfo.files && inputFileMetaInfo.files.length > 0 && inputFileMetaInfo.files.map((item) => {
                  return <tr>
                    <td style={{ "padding-left": "5px" }}>
                      <div style={{ "height": "20px", "overflow": "hidden" }}>
                        {item.key.split('/').pop()}
                      </div>
                    </td>
                    <td style={{ "padding-left": "5px" }}>
                      <div style={{ "height": "20px", "overflow": "hidden" }}>
                        {item.updatedAt}
                      </div>
                    </td>
                    <td className="bg-transparent p-0 d-flex w-100">
                      <div>
                        <button style={{ width: "60%" }}
                          className="btn btn-green op-100 me-2 rounded-0"
                          onClick={() => downloadInputFileOperationAnalyis(item.key)}
                        >
                          <span className="pt-1">{t("DL")} </span>
                        </button>
                        <button style={{ width: "60%" }}
                          className="btn btn-E65C4F rounded-0"
                          onClick={() => deleteInputFileOperationAnalyis(item.key)}
                        >
                          <span className="pt-1">{t("delete")} </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex">
            <table class="table-borderless text-center csvlistTable w-100" >
              <thead>
                <tr className="bg-clrded " >
                  <th scope="col" style={{ width: "25%" }} >{t("items")}</th>
                  <th scope="col" style={{ width: "35%" }}>{t("csvList")}</th>
                  <th scope="col" >{t("setting")} </th>
                </tr>
              </thead>
              <tbody className=" mt-4 ">
                <div className="mt-3"></div>
                <tr className="p-5">
                  <td>{t("userID")}</td>
                  <td>
                    <select className="w-100 m-auto ps-2 csvSelect py-2" onChange={handleUserIdColumnChange}>
                      <option>{t("csvList")}</option>
                      {inputFileMetaInfo.columns && inputFileMetaInfo.columns.length > 0 && inputFileMetaInfo.columns.map((item, index) => {
                        return <option
                          key={index}
                          value={item}
                          selected={columnUserId === item}
                        >
                          {item}
                        </option>
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>{t("time")}</td>
                  <td>
                    <select className="w-100 m-auto ps-2 csvSelect py-2" onChange={handleTimestampColumnChange}>
                      <option>{t("csvList")}</option>
                      {inputFileMetaInfo.columns && inputFileMetaInfo.columns.length > 0 && inputFileMetaInfo.columns.map((item, index) => {
                        return <option
                          key={index}
                          value={item}
                          selected={columnTimestamp === item}
                        >
                          {item}
                        </option>
                      })}
                    </select>
                  </td>
                  <td className="pt-3">
                    <input style={{ position: "relative", bottom: "10px" }}
                      onChange={handleTimestampFormatChange}
                      value={timestampFormat}
                      name="date"
                      className="ps-1 select-class"
                      id="date"
                      placeholder="%Y/%m/%d %H:%M:%S"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ height: "400px" }} className="mt-4 border d-flex align-items-center justify-content-center">
            <img src={TimestampFormatDescription} alt="Image not availabe" height={300} />
          </div>

          <button
            className=" btn btn-submit mt-4 mx-auto "
            onClick={() => postInputSettingOperationAnalysis()}
          >
            {t("update")}
            <FaAngleRight />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
};
export default CsvManualSettingModal;
import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "./layout/Layout";
import { ApiService } from "../../src/utils/services/api";
import { FaAngleRight, FaCloudUploadAlt } from "react-icons/fa";
import { saveAs } from "file-saver";
import { Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import RoleType from "../utils/constants/RoleType";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./shared/Fileupload/Analysis.scss";
import { useStore } from "../../src/Store";
import { AnalysisService } from "../../src/utils/services/analysis.service";
import { StackedBarChart } from "../../src/components/StackedBarChart";
import { RadarChart } from "../../src/components/RadarChart";
import { LineChart } from "../../src/components/LineChart";
import { KpiCard } from "../../src/components/KpiCard";
import { KpiCardFlex } from "../../src/components/KpiCardFlex";
import Loader from "./Loader";
import SmallLoader from "./SmallLoader";
import FileUpload from "./shared/Fileupload/FileUpload";
import SurveyNotification from "./shared/SurveyNotification";
import Calendar from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Swal from "sweetalert2";
import { ClientService } from "../utils/services/client.service";
import { holidays_in_japanese, holidays_in_english, days_in_english, days_in_japanese } from "../utils/constants/japaneseHolidays";
import { Container, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { FileService } from "../utils/services/file.service";
import CsvAccessPointSettingModal from "./CsvAccessPointSettingModal";
import CsvAccessLogSettingModal from "./CsvAccessLogSettingModal";
import CsvManualSettingModal from "./CsvManualSettingModal";
import WifiAccessPointSettingModal from "./WifiAccessPointSettingModal";
import { each } from "lodash";

const sampleData = {
  work_mode: { title: "work_mode", value: "WORK" },
  num_employess: { title: "num_employess", value: "99,999" },
  rate_attendance: { title: "rate_attendance", value: 50 },
  total_area: { title: "total_area", value: "99,999" },
  area_per_person: { title: "area_per_person", value: 12.4 },
  num_touch_point: { title: "num_touch_point", value: "179" },
  num_seat_work: { title: "num_seat_work", value: "85" },
  num_private_room: { title: "num_private_room", value: "5" },
};
const fileTypesforCsv = [".csv", ".xlsx"];
const fileTypesforJpg = [".jpeg", ".png", ".jpg", ".svg", ".gif", ".bmp", ".tiff", ".jfif", ".pjpeg", ".pjp", ".pdf"];
const apiService = new ApiService();
const analysisService = new AnalysisService();

const surveyDataSample = {
  data_deep_survey: {
    respondent_ratio: {
      title: "respondent_ratio",
      value: "126",
    },
    data_radar: {
      labels: [
        "\u30d7\u30ec\u30a4\u30b9",
        "\u30b9\u30bf\u30a4\u30eb",
        "\u30ab\u30eb\u30c1\u30e3\u30fc",
        "\u30a6\u30a7\u30eb\u30d3\u30fc\u30a4\u30f3\u30b0",
        "\u30a8\u30f3\u30b2\u30fc\u30b8\u30e1\u30f3\u30c8",
        "\u3084\u308a\u304c\u3044",
      ],
      datasets: [
        {
          backgroundColor: "#6793bd80",
          borderColor: "#6793bd80",
          label: "\u4eca\u56de\u8abf\u67fb",
          data: [0.6349206349206349, 0.5, 0.5857142857142857, 0.546031746031746, 0.6301587301587303, 0.6873015873015873],
          borderWidth: 1.0,
        },
      ],
    },
    data_line: {
      labels: ["2023/06"],
      datasets: [
        {
          borderColor: "#6793bd",
          backgroundColor: "#6793bd",
          label: "\u30d7\u30ec\u30a4\u30b9",
          data: [0.6349206349206349],
        },
        {
          borderColor: "#66ab79",
          backgroundColor: "#66ab79",
          label: "\u30b9\u30bf\u30a4\u30eb",
          data: [0.5],
        },
        {
          borderColor: "#d57b85",
          backgroundColor: "#d57b85",
          label: "\u30ab\u30eb\u30c1\u30e3\u30fc",
          data: [0.5857142857142857],
        },
        {
          borderColor: "#c9caca",
          backgroundColor: "#c9caca",
          label: "\u30a6\u30a7\u30eb\u30d3\u30fc\u30a4\u30f3\u30b0",
          data: [0.546031746031746],
        },
        {
          borderColor: "#a5890c",
          backgroundColor: "#a5890c",
          label: "\u30a8\u30f3\u30b2\u30fc\u30b8\u30e1\u30f3\u30c8",
          data: [0.6301587301587303],
        },
        {
          borderColor: "#da9100",
          backgroundColor: "#da9100",
          label: "\u3084\u308a\u304c\u3044",
          data: [0.6873015873015873],
        },
      ],
    },
    measurement_period: "2023\u5e746\u6708\uff5e2023\u5e746\u6708",
    total_work_point: {
      title: "total_work_point",
      value: 59.7,
    },
  },
};

const TopPage = () => {
  const { t, i18n } = useTranslation();
  const time = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [csvDataUpload, setCsvDataUpload] = useState(false);
  const [analysisStatus, setAnalysisStatus] = useState("");
  const [operationAnalysisStatus, setOperationAnalysisStatus] = useState("");
  const [operationAnalysisUpdatedAt, setOperationAnalysisUpdatedAt] = useState("");
  const [operationalAnalysisSetting, setOperationalAnalysisSetting] = useState(false);
  const [placeStyleSurveyNotificationSetting, setPlaceStyleSurveyNotificationSetting] = useState(false);
  const [deepSurveyNotificationSetting, setDeepSurveyNotificationSetting] = useState(false);
  const [shortSurveyNotificationSetting, setShortSurveyNotificationSetting] = useState(false);
  const [CSVdata, setCSVdata] = useState(false);
  const [automaticWifiAccess, setAutomaticWifiAccess] = useState(false);
  const [Exceldata, setExceldata] = useState(false);
  const [Manualdata, setManualdata] = useState(false);
  const [spaceAnalysisStatus, setSpaceAnalysisStatus] = useState("");
  const [spaceAnalysisUpdatedAt, setSpaceAnalysisUpdatedAt] = useState("");
  const [commuteSimulationStatus, setCommuteSimulationStatus] = useState("");
  const [commuteSimulationUpdatedAt, setCommuteSimulationUpdatedAt] = useState("");
  const [weekdays, setWeekDays] = useState([]);
  const [timeslots, setTimeSlots] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [customHolidays, setCustomHolidays] = useState([]);
  const [inputFileMetaInfo, setInputFileMetaInfo] = useState([]);
  const [spaceAnalysisData, setSpaceAnalysisData] = useState(null);
  const [operationAnalysisData, setOperationAnalysisData] = useState(null);
  const [commuteSimulationData, setCommuteSimulationData] = useState(null);
  const [costSimulationData, setCostSimulationData] = useState(null);
  const [roomSimulationData, setRoomSimulationData] = useState(null);
  const [placeStyleSurveyData, setPlaceStyleSurveyData] = useState(null);
  const [deepSurveyData, setDeepSurveyData] = useState(null);
  const [shortSurveyData, setShortSurveyData] = useState(null);
  const [inputMethod, setInputMethod] = useState(null);
  const [branch, setBranch] = useState(null);
  const [historyList, setHistoryList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [User, setUser] = useState("");
  const [cognitoTenant, setCognitoTenant] = useState("");

  const [adminComment, setAdminComment] = useState(null);
  const [adminLoader, setAdminLoader] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);

  const [formValues, setFormValues] = useState(null);
  const { tenant } = useStore();
  const location = useLocation();
  const [userType, setUserType] = useState(null);
  const [_, setLang] = useState("");
  const [plan, setPlan] = useState(null);

  const clientService = new ClientService();

  const getCompany = async () => {
    if (tenant) {
      const response_company = await clientService.getCompany(tenant);
      if (response_company) {
        setPlan(response_company.data.plan);
      }
    }
  };

  const handleImportDataCheckboxChange = (event) => {
    if (event.target.checked) {
      setInputMethod(event.target.value);
    } else {
      setInputMethod(null);
    }
  };
  const handleBulkCreateModalClose = () => setCsvDataUpload(false);
  const handleOperationalAnalysisSettingClose = () => setOperationalAnalysisSetting(false);

  const handlePlaceStyleSurveyNotificationSettingClose = () => setPlaceStyleSurveyNotificationSetting(false);
  const handleDeepSurveyNotificationSettingClose = () => setDeepSurveyNotificationSetting(false);
  const handleShortSurveyNotificationSettingClose = () => setShortSurveyNotificationSetting(false);

  const handleCSVdataClose = () => setCSVdata(false);
  const handleExceldataClose = () => setExceldata(false);
  const handleManualDataClose = () => setManualdata(false);
  const handleAutomaticWifiAccessClose = () => setAutomaticWifiAccess(false);

  const saveSampleFile = async () => {
    let fileName = "";
    switch (inputMethod) {
      case "csvAccessPoint": {
        fileName = "sample-operation-analysis-attendance-info-access-point.csv";
        break;
      }
      case "csvAccessLog": {
        fileName = "sample-operation-analysis-attendance-info-access-log.xlsx";
        break;
      }
      case "csvManual": {
        const response = await analysisService.createManualSampleOperationAnalysis();
        if (response) {
          fileName = "sample-operation-analysis-attendance-info-manual.xlsx";
        }
        break;
      }
    }
    saveAs(`${process.env.REACT_APP_CSV_BASE_URL}/${fileName}`, fileName);
  };

  const getInputMethodOperationAnalysis = async () => {
    const response = await analysisService.getInputMethodOperationAnalysis(tenant);
    if (response) {
      setInputMethod(response.data);
    }
  };

  const getTargetDateSlotsOperationAnalysis = async () => {
    const response = await analysisService.getTargetDateSlotsOperationAnalysis(tenant);
    if (response) {
      if (response.data.weekDays) {
        setWeekDays(response.data.weekDays);
      } else {
        setWeekDays([]);
      }
      if (response.data.timeSlots) {
        setTimeSlots(response.data.timeSlots);
      } else {
        setTimeSlots([]);
      }
      if (response.data.holidays) {
        setHolidays(response.data.holidays);
      } else {
        setHolidays([]);
      }
      if (response.data.customHolidays) {
        setCustomHolidays(response.data.customHolidays);
      } else {
        setCustomHolidays([]);
      }
    }
  };

  const onSubmit = async (e) => {
    const response_method = await analysisService.postInputMethodOperationAnalysis(tenant, inputMethod);

    const data = {
      weekDays: weekdays,
      timeSlots: timeslots,
      holidays: holidays,
      customHolidays: customHolidays,
    };
    const response_slots = await analysisService.postTargetDateSlotsOperationAnalysis(tenant, data);
    if (response_slots) {
      Swal.fire({
        title: t("setting_updated"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      });
    }
  };

  const handleChangeWeekdays = (e) => {
    if (e.target.checked) {
      setWeekDays([...weekdays, +e.target.value]);
    } else {
      setWeekDays(weekdays.filter((item) => item !== +e.target.value));
    }
  };

  const handleChangeTimeSlots = (e) => {
    if (e.target.checked) {
      setTimeSlots([...timeslots, +e.target.value]);
    } else {
      setTimeSlots(timeslots.filter((item) => item !== +e.target.value));
    }
  };

  const handleChangeHolidays = (e) => {
    console.log(e.target.value);
    if (e.target.checked) {
      setHolidays([...holidays, e.target.value]);
    } else {
      setHolidays(holidays.filter((item) => item !== e.target.value));
    }
  };

  useLayoutEffect(() => {
    if (tenant && tenant !== "") {
      getInputMethodOperationAnalysis();
      getTargetDateSlotsOperationAnalysis();
    }
  }, [tenant, roomSimulationData]);

  useEffect(() => {
    if (location.pathname === "/") {
      getVerifier();
      if (tenant) {
        getCompany();
        getAdminComments();
      }
      getAnalysisStatus();
      if (tenant && tenant !== "") {
        getAnalysisData();
      }
    }
  }, [location, tenant, userType]);

  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setUser(decoded["cognito:username"]);
      setCognitoTenant(decoded["cognito:groups"][0]);
      setUserType(decoded["custom:role"]);
    } catch (err) {}
  };

  const getAdminComments = async () => {
    const params = {
      analysis: "top-page",
    };
    const key = tenant;
    try {
      const response = await analysisService.getAdminComments(params, key);
      if (response) {
        setAdminComment(response.data.comments);
      }
    } catch (err) {
      setAdminComment("");
    }
  };

  const saveAdminComments = async () => {
    setAdminLoader(true);
    const data = {
      comments: adminComment,
      analysis: "top-page",
    };
    const response = await analysisService.postAdminComments(data, tenant);
    if (response) {
      setAdminLoader(false);
      setAdminComment(adminComment);
      getAdminComments();
      Swal.fire({
        title: t("comments_submitted_successfuly"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      });
      setEditAdmin(false);
    }
  };

  const handleAdminCommentChange = (e) => {
    setAdminComment(e.target.value);
  };

  const getAnalysisStatus = async () => {
    const response = await analysisService.getAnalysisStatus(tenant);
    if (response) {
      setOperationAnalysisStatus(response.data["operation-analysis"]["status"]);
      setOperationAnalysisUpdatedAt(response.data["operation-analysis"]["updated_at"]);
      setSpaceAnalysisStatus(response.data["space-analysis"]["status"]);
      setSpaceAnalysisUpdatedAt(response.data["space-analysis"]["updated_at"]);
      setCommuteSimulationStatus(response.data["commute-simulation"]["status"]);
      setCommuteSimulationUpdatedAt(response.data["commute-simulation"]["updated_at"]);
      setAnalysisStatus(true);
    }
  };

  const getAnalysisData = async () => {
    getSpaceAnalysisData();
    getOperationAnalysisData();
    getCommuteSimulationData();
    getRoomSimulationData();
    getCostSimulationData();
    getPlaceStyleSurveyData();
    getDeepSurveyData();
    getShortSurveyData();
  };

  const getSpaceAnalysisData = async () => {
    try {
      const response_space_analysis = await analysisService.getAnalysisData(tenant, "space-analysis");
      if (response_space_analysis) {
        setSpaceAnalysisData(response_space_analysis.data["data_space_analysis"]);
      }
    } catch (err) {
      setSpaceAnalysisData("empty");
    }
  };

  const getOperationAnalysisData = async () => {
    try {
      const response_operation_analysis = await analysisService.getAnalysisData(tenant, "operation-analysis");
      if (response_operation_analysis) {
        setOperationAnalysisData(response_operation_analysis.data["data_operation_analysis"]);
      }
    } catch (err) {
      setOperationAnalysisData("empty");
    }
  };

  const getCommuteSimulationData = async () => {
    try {
      const response_commute_simulation = await analysisService.getAnalysisData(tenant, "location-analysis");
      if (response_commute_simulation) {
        setCommuteSimulationData(response_commute_simulation.data["data_location_analysis"]);
      }
    } catch (err) {
      setCommuteSimulationData("empty");
    }
  };

  const getRoomSimulationData = async () => {
    try {
      const response_room_simulation = await analysisService.getAnalysisData(tenant, "room-simulation");
      if (response_room_simulation) {
        setRoomSimulationData(response_room_simulation.data["data_room_simulation"]);
      }
    } catch (err) {
      setRoomSimulationData("empty");
    }
  };

  const getCostSimulationData = async () => {
    try {
      const response_cost_simulation = await analysisService.getAnalysisData(tenant, "cost-simulation");
      if (response_cost_simulation) {
        setCostSimulationData(response_cost_simulation.data["data_cost_simulation"]);
      }
    } catch (err) {
      setCostSimulationData("empty");
    }
  };

  const getPlaceStyleSurveyData = async () => {
    try {
      const response_place_style_survey = await analysisService.getAnalysisData(tenant, "place-style-survey");
      if (response_place_style_survey) {
        setPlaceStyleSurveyData(response_place_style_survey.data["data_place_style_survey"]);
      }
    } catch (err) {
      setPlaceStyleSurveyData("empty");
    }
  };

  const getDeepSurveyData = async () => {
    try {
      const response_deep_survey = await analysisService.getAnalysisData(tenant, "deep-survey");
      if (response_deep_survey) {
        const data_deep_survey = response_deep_survey.data["data_deep_survey"];
        const data_line = data_deep_survey.data_line;

        // DEV_WDT-334 start: 小数値をパーセントに変換
        data_line.datasets = data_line.datasets.map((dataset) => {
          dataset.data = dataset.data.map((value) => Math.round(value * 1000) / 10);
          return dataset;
        });
        // DEV_WDT-334 end: 小数値をパーセントに変換

        setDeepSurveyData(data_deep_survey);
      }
    } catch (err) {
      setDeepSurveyData("empty");
    }
  };

  const getShortSurveyData = async () => {
    try {
      const response_short_survey = await analysisService.getAnalysisData(tenant, "short-survey");
      if (response_short_survey) {
        const data_short_survey = response_short_survey.data["data_short_survey"];
        const data_line = data_short_survey.data_line;

        // DEV_WDT-334 start: 小数値をパーセントに変換
        data_line.datasets = data_line.datasets.map((dataset) => {
          dataset.data = dataset.data.map((value) => Math.round(value * 1000) / 10);
          return dataset;
        });
        // DEV_WDT-334 end: 小数値をパーセントに変換

        setShortSurveyData(data_short_survey);
      }
    } catch (err) {
      setShortSurveyData("empty");
    }
  };

  const getSpaceutitlizationHistoryList = async () => {
    const res = await analysisService.getSpaceutitlizationHistoryList(tenant);
    const historyList = res.data.historys;
    setHistoryList(historyList);
  };

  return userType && tenant ? (
    <Layout>
      <div className="col-12 px-3">
        <Grid container direction="row">
          <div className="quick-sight-dashboard-container">
            <Grid container direction="row" spacing={0} className="child-layout">
              {/* Adminコメント */}
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <div className=" quick-sight-dashboard mb-3">
                    <textarea
                      id="admin_comment"
                      name="admin_comment"
                      value={adminComment}
                      className="top-page-text-area"
                      placeholder={t("admin_comment")}
                      disabled={userType === RoleType.CLIENT_LEADER || userType === RoleType.CLIENT_MEMBER}
                      onChange={handleAdminCommentChange}
                    ></textarea>
                  </div>
                </Grid>
                <Grid item xs={2} className="d-flex align-items-end">
                  {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(userType) && (
                    <div className="text-end mb-3">
                      <button className="btn btn-EAC21D op-100 rounded-1" onClick={saveAdminComments}>
                        {t("update")}
                      </button>
                    </div>
                  )}
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
              <Grid container direction="row" spacing={2}>
                {/* 左半分 */}
                <Grid item xs={6}>
                  {/* ワークデザインサーベイ */}
                  <Grid item xs={12}>
                    <div className="quick-sight-dashboard mb-3">
                      <Grid container spacing={0} justifyContent="space-between">
                        <Grid item xs={12}>
                          <div className="top-page-analysis-title">{t("deep_survey_summary")}</div>
                        </Grid>
                        <Grid item xs={5}>
                          <Grid item xs={12}>
                            <KpiCard data={deepSurveyData} data_key="total_work_point" unit="pt"></KpiCard>
                          </Grid>
                          <Grid item xs={12}>
                            <KpiCard data={deepSurveyData} data_key="respondent_ratio"></KpiCard>
                          </Grid>
                        </Grid>
                        <Grid item xs={7}>
                          <RadarChart data={deepSurveyData} data_key="data_radar"></RadarChart>
                        </Grid>

                        {/* 折れ線 */}
                        <Grid item xs={12}>
                          <LineChart data={deepSurveyData} data_key="data_line"></LineChart>
                        </Grid>
                      </Grid>

                      {/* notification setting */}
                      <div className="card-footer-action d-flex  flex-wrap flex-column">
                        <div className="data-btn mt-xxl-0 mt-3">
                          <div className="d-flex">
                            <button className="px-3 bg-info" onClick={() => setDeepSurveyNotificationSetting(true)}>
                              {t("survey_notification_setting")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  {/* 空間分析 */}
                  {plan !== "free_survey" && plan !== "light" && (
                    <Grid item xs={12}>
                      <div className="quick-sight-dashboard mb-3">
                        <Grid container spacing={0} justifyContent="space-between">
                          <Grid item xs={12}>
                            <div className="top-page-analysis-title">{t("room_analysis")}</div>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={0} justifyContent="space-between">
                              <Grid item xs={6}>
                                <KpiCard data={spaceAnalysisData} data_key="total_square_measure" unit="square_meters"></KpiCard>
                              </Grid>
                              <Grid item xs={6}>
                                <KpiCard data={spaceAnalysisData} data_key="total_tsubo" unit="tsubo_measure"></KpiCard>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <StackedBarChart data={spaceAnalysisData} data_key="data_stacked_bar" title={t("area_composition_ratio")}></StackedBarChart>
                          </Grid>

                          <Grid item xs={12}>
                            <div>
                              <Grid container spacing={0} justifyContent="space-between">
                                <Grid item xs={4}>
                                  <KpiCard data={spaceAnalysisData} data_key="num_seat_touch_point" unit="seat"></KpiCard>
                                </Grid>
                                <Grid item xs={4}>
                                  <KpiCard data={spaceAnalysisData} data_key="num_seat_work" unit="seat"></KpiCard>
                                </Grid>
                                <Grid item xs={4}>
                                  <KpiCard data={spaceAnalysisData} data_key="num_seat_meeting" unit="seat"></KpiCard>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>

                        {/* status and update at */}
                        <div className="card-footer-action d-flex  flex-wrap flex-row">
                          <div className="d-flex mb-2">
                            <div className="status-btn me-2">
                              <h6>{t("status")}</h6>
                              <button className={spaceAnalysisStatus}>{t(spaceAnalysisStatus)}</button>
                            </div>
                            <div className="updated-btn me-2">
                              <h6>{t("updated_at")}</h6>
                              <button>{t(spaceAnalysisUpdatedAt)}</button>
                            </div>
                          </div>

                          <div className="data-btn mt-xxl-0 mt-3">
                            <h6>{t("data")}</h6>
                            <div className="d-flex">
                              <button
                                className="me-2 px-3"
                                onClick={() => {
                                  // getSpaceutitlizationHistoryList()
                                  setBranch({ analysis: "space-analysis", type: "utilization-info" });
                                  setCsvDataUpload(true);
                                }}
                              >
                                {t("utilization_info")}
                              </button>
                              <button
                                className="px-3"
                                onClick={() => {
                                  // getSpaceutitlizationHistoryList()
                                  setBranch({ analysis: "space-analysis", type: "utilization-map" });
                                  setCsvDataUpload(true);
                                }}
                              >
                                {t("utilization_map")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}

                  {/* 稼働分析 */}

                  {plan !== "free_survey" && plan !== "light" && (
                    <Grid item xs={12}>
                      <div className=" quick-sight-dashboard mb-3">
                        <Grid container spacing={0} justifyContent="space-between">
                          <Grid item xs={12}>
                            <div className="top-page-analysis-title">{t("Operation")}</div>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={0} justifyContent="space-between">
                              <Grid item xs={4}>
                                <KpiCard data={operationAnalysisData} data_key="rate_attendance" unit="percent"></KpiCard>
                              </Grid>
                              <Grid item xs={4}>
                                <KpiCard data={operationAnalysisData} data_key="rate_operation" unit="percent"></KpiCard>
                              </Grid>
                              <Grid item xs={4}>
                                <KpiCard data={operationAnalysisData} data_key="num_attendance" unit="person"></KpiCard>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* status and update at */}
                        <div className="card-footer-action d-flex  flex-wrap flex-row">
                          <div className="d-flex mb-2">
                            <div className="status-btn me-2">
                              <h6>{t("status")}</h6>
                              <button className={operationAnalysisStatus}>{t(operationAnalysisStatus)}</button>
                            </div>
                            <div className="updated-btn me-2">
                              <h6>{t("updated_at")}</h6>
                              <button>{operationAnalysisUpdatedAt}</button>
                            </div>
                          </div>
                          <div className="data-btn mt-xxl-0 mt-3">
                            <h6>{t("data")}</h6>
                            <div className="d-flex">
                              <button
                                className="me-2 px-3"
                                onClick={() => {
                                  setBranch({ analysis: "operation-analysis", type: "attendance-info" });
                                  setCsvDataUpload(true);
                                }}
                              >
                                {t("attendance_info")}
                              </button>
                              <button
                                className="me-2 px-3"
                                onClick={() => {
                                  setBranch({ analysis: "operation-analysis", type: "employee-info" });
                                  setCsvDataUpload(true);
                                }}
                              >
                                {t("employee_info")}
                              </button>
                              <button className="px-3 bg-info" onClick={() => setOperationalAnalysisSetting(true)}>
                                {t("operation_analysis_setting")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}

                  {/* 通勤シミュレーション */}
                  {plan !== "free_survey" && plan !== "light" && (
                    <Grid item xs={12}>
                      <div className="quick-sight-dashboard mb-3">
                        <Grid container spacing={0} justifyContent="space-between">
                          <Grid item xs={12}>
                            <div className="top-page-analysis-title">{t("CommuteSimulation")}</div>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={0} justifyContent="space-between">
                              <Grid item xs={6}>
                                <KpiCard data={commuteSimulationData} data_key="commuting_time" unit="minute"></KpiCard>
                              </Grid>
                              <Grid item xs={6}>
                                <KpiCard data={commuteSimulationData} data_key="transportation_fee" unit="yen"></KpiCard>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* status and update at */}
                        <div className="card-footer-action d-flex flex-wrap flex-row">
                          <div className="d-flex mb-2">
                            <div className="status-btn me-2">
                              <h6>{t("status")}</h6>
                              <button className={commuteSimulationStatus}>{t(commuteSimulationStatus)}</button>
                            </div>
                            <div className="updated-btn me-2">
                              <h6>{t("updated_at")}</h6>
                              <button>{commuteSimulationUpdatedAt}</button>
                            </div>
                          </div>
                          <div className="data-btn mt-xxl-0 mt-3">
                            <h6>{t("data")}</h6>
                            <div className="d-flex">
                              <button
                                className="me-2 px-3"
                                onClick={() => {
                                  setBranch({ analysis: "location-analysis", type: "employee-info" });
                                  setCsvDataUpload(true);
                                }}
                              >
                                {t("commute_info")}
                              </button>
                              <button
                                className="me-2 px-3"
                                onClick={() => {
                                  setBranch({ analysis: "location-analysis", type: "branch-info" });
                                  setCsvDataUpload(true);
                                }}
                              >
                                {t("branch_info")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>

                {/* 右半分 */}
                <Grid item xs={6}>
                  {/* ショートサーベイサマリ */}
                  <Grid item xs={12}>
                    <div className=" quick-sight-dashboard mb-3">
                      <Grid container spacing={0} justifyContent="space-between">
                        <Grid item xs={12}>
                          <div className="top-page-analysis-title">{t("short_survey_summary")}</div>
                        </Grid>
                        <Grid item xs={5}>
                          <Grid item xs={12}>
                            <KpiCard data={shortSurveyData} data_key="total_work_point" unit="pt"></KpiCard>
                          </Grid>
                          <Grid item xs={12}>
                            <KpiCard data={shortSurveyData} data_key="respondent_ratio" unit=""></KpiCard>
                          </Grid>
                        </Grid>
                        <Grid item xs={7}>
                          <RadarChart data={shortSurveyData} data_key="data_radar"></RadarChart>
                        </Grid>

                        <Grid item xs={12}>
                          <LineChart data={shortSurveyData} data_key="data_line"></LineChart>
                        </Grid>
                      </Grid>
                      {/* notification setting */}
                      <div className="card-footer-action d-flex  flex-wrap flex-column">
                        <div className="data-btn mt-xxl-0 mt-3">
                          <div className="d-flex">
                            <button className="px-3 bg-info" onClick={() => setShortSurveyNotificationSetting(true)}>
                              {t("survey_notification_setting")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  {/* Adminコメント */}
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <Grid item xs={2} className="d-flex align-items-end">
                        {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(userType) && (
                          <div className="text-end mb-3">
                            <button className="btn btn-EAC21D op-100 rounded-1" onClick={saveAdminComments}>
                              {t("update")}
                            </button>
                          </div>
                        )}
                      </Grid>
                      <div className=" quick-sight-dashboard mb-3">
                        <textarea
                          id="admin_comment"
                          name="admin_comment"
                          value={adminComment}
                          className="top-page-text-area"
                          placeholder={t("admin_comment")}
                          disabled={userType === RoleType.CLIENT_LEADER || userType === RoleType.CLIENT_MEMBER}
                          onChange={(e) => setAdminComment(e.target.value)}
                        ></textarea>
                      </div>
                    </Grid>
                  </Grid>

                  {/* プレイススタイルサーベイサマリ */}
                  <Grid item xs={12}>
                    <div className=" quick-sight-dashboard mb-3">
                      <Grid container spacing={0} justifyContent="space-between">
                        <Grid item xs={12}>
                          <div className="top-page-analysis-title">{t("surveu_summary")}</div>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={0} justifyContent="space-between">
                            <Grid item xs={4}>
                              <KpiCard data={placeStyleSurveyData} data_key="comprehensive_evaluation" unit="point"></KpiCard>
                            </Grid>
                            <Grid item xs={4}>
                              <KpiCard data={placeStyleSurveyData} data_key="workability_index" unit="point"></KpiCard>
                            </Grid>
                            <Grid item xs={4}>
                              <KpiCard data={placeStyleSurveyData} data_key="improvement_index" unit="point"></KpiCard>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* notification setting */}
                      <div className="card-footer-action d-flex  flex-wrap flex-column">
                        <div className="data-btn mt-xxl-0 mt-3">
                          <div className="d-flex">
                            <button className="px-3 bg-info" onClick={() => setPlaceStyleSurveyNotificationSetting(true)}>
                              {t("survey_notification_setting")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  {/* シナリオ構築 */}
                  {plan !== "free_survey" && plan !== "light" && <div className="top-page-analysis-title-senarios">{t("senarios")}</div>}

                  {/* コストシミュレーション */}
                  {plan !== "free_survey" && plan !== "light" && (
                    <Grid item xs={12}>
                      <div className=" top-page-analysis-container mb-3">
                        <Grid container spacing={0} justifyContent="center">
                          <Grid item xs={12}>
                            <div className="top-page-analysis-title">{t("CostSimulation")}</div>
                          </Grid>

                          <Grid item xs={4}>
                            <div></div>
                          </Grid>
                          <Grid item xs={6}>
                            <KpiCard data={costSimulationData} data_key="cost_per_person" unit="thousand_yen"></KpiCard>
                          </Grid>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={6}>
                            <KpiCard data={costSimulationData} data_key="cost_per_seat" unit="thousand_yen"></KpiCard>
                          </Grid>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={4}></Grid>
                          <Grid item xs={6}>
                            <KpiCard data={costSimulationData} data_key="cost_per_seat_substantial" unit="thousand_yen"></KpiCard>
                          </Grid>
                          <Grid item xs={2}></Grid>
                        </Grid>
                      </div>
                    </Grid>
                  )}

                  {/* 空間シミュレーション */}
                  {plan !== "free_survey" && plan !== "light" && (
                    <Grid item xs={12}>
                      <div className=" top-page-analysis-container mb-3">
                        <Grid container spacing={0} justifyContent="center">
                          <Grid item xs={12}>
                            <div className="top-page-analysis-title">{t("room_simulation")}</div>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={0} justifyContent="space-between">
                              <Grid item xs={3}>
                                <KpiCardFlex data={roomSimulationData} data_key="work_mode" unit=""></KpiCardFlex>
                              </Grid>
                              <Grid item xs={3}>
                                <KpiCard data={roomSimulationData} data_key="num_employess" unit="person"></KpiCard>
                              </Grid>
                              <Grid item xs={3}>
                                <KpiCard data={roomSimulationData} data_key="rate_attendance" unit="percent"></KpiCard>
                              </Grid>
                              <Grid item xs={3}>
                                <KpiCard data={roomSimulationData} data_key="total_area" unit="square_meters"></KpiCard>
                              </Grid>
                              <Grid item xs={3}>
                                <KpiCard data={roomSimulationData} data_key="area_per_person" unit="square_meters"></KpiCard>
                              </Grid>
                              <Grid item xs={3}>
                                <KpiCard data={roomSimulationData} data_key="num_touch_point" unit="seat"></KpiCard>
                              </Grid>
                              <Grid item xs={3}>
                                <KpiCard data={roomSimulationData} data_key="num_seat_work" unit="seat"></KpiCard>
                              </Grid>
                              <Grid item xs={3}>
                                <KpiCard data={roomSimulationData} data_key="num_private_room" unit="seat"></KpiCard>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Modal show={csvDataUpload} onHide={handleBulkCreateModalClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton className="border-0">
            <h5 className="clr-14213D mb-0 ">{t("upload_file")}</h5>
          </Modal.Header>
          <Modal.Body>
            <FileUpload fileTypesforCsv={fileTypesforCsv} fileTypesforJpg={fileTypesforJpg} branch={branch} />
          </Modal.Body>
        </Modal>

        <Modal show={placeStyleSurveyNotificationSetting} onHide={handlePlaceStyleSurveyNotificationSettingClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton className="border-0">
            <h5 className="clr-14213D mb-0 ">{t("survey_notification_setting")}</h5>
          </Modal.Header>
          <Modal.Body>
            <SurveyNotification survey_type={"place_style_survey"} />
          </Modal.Body>
        </Modal>

        <Modal show={deepSurveyNotificationSetting} onHide={handleDeepSurveyNotificationSettingClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton className="border-0">
            <h5 className="clr-14213D mb-0 ">{t("survey_notification_setting")}</h5>
          </Modal.Header>
          <Modal.Body>
            <SurveyNotification survey_type={"deep_survey"} />
          </Modal.Body>
        </Modal>

        <Modal show={shortSurveyNotificationSetting} onHide={handleShortSurveyNotificationSettingClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton className="border-0">
            <h5 className="clr-14213D mb-0 ">{t("survey_notification_setting")}</h5>
          </Modal.Header>
          <Modal.Body>
            <SurveyNotification survey_type={"short_survey"} />
          </Modal.Body>
        </Modal>

        {/* 稼働分析設定 */}
        <Modal className="scrollbar-c9c9ca" show={operationalAnalysisSetting} onHide={handleOperationalAnalysisSettingClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton className="border-0">
            <h4 className="clr-14213D mb-0 ">
              <b>{t("OperationalAnalysisSetting")}</b>
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4 clr-14213D">
              <div>
                <h5 className="">
                  <b>{t("date_and_time_zone_of_aggregation")}</b>
                </h5>
                <div className="d-flex bg-clr9fa p-3" style={{ gap: "10px" }}>
                  <div className="w-25 d-flex flex-column  bg-clrded">
                    <h6 className="text-center mt-2">
                      <b>{t("Dayofweek")}</b>
                    </h6>
                    {i18n.language === "en"
                      ? days_in_english.map((item, index) => {
                          return (
                            <div className="d-flex ms-3 checkbox-container">
                              <input type="checkbox" className="m-1 " id={index} value={index} checked={weekdays && weekdays.includes(index)} onChange={handleChangeWeekdays} />
                              <label htmlFor={index} className="my-auto ms-1">
                                {item}
                              </label>
                            </div>
                          );
                        })
                      : days_in_japanese.map((item, index) => {
                          return (
                            <div className="d-flex ms-3 checkbox-container">
                              <input type="checkbox" className="m-1 " value={index} checked={weekdays && weekdays.includes(index)} onChange={handleChangeWeekdays} />
                              <label htmlFor={index} className="my-auto ms-1">
                                {item}
                              </label>
                            </div>
                          );
                        })}
                  </div>
                  <div className=" bg-clrded">
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        <h6 className="text-center mt-2">
                          <b>{t("timeSlot")}</b>
                        </h6>
                      </Grid>
                      <Grid item xs={6} className="d-grid justify-content-center mb-3">
                        {time.map((item, index) => {
                          return (
                            <div className="d-flex checkbox-container">
                              <input type="checkbox" className="m-1 " name={item} value={index} checked={timeslots.length > 0 && timeslots.includes(index)} onChange={handleChangeTimeSlots} />
                              <label className="my-auto ms-1">
                                {item}:00 ~ {item + 1}:00
                              </label>
                            </div>
                          );
                        })}
                      </Grid>
                      <Grid item xs={6} className="d-grid justify-content-center mb-3">
                        {time.map((item, index) => {
                          return (
                            <div className="d-flex checkbox-container">
                              <input type="checkbox" className="m-1 " name={item + 12} value={index + 12} checked={timeslots && timeslots.includes(index + 12)} onChange={handleChangeTimeSlots} />
                              <label className="my-auto ms-1">
                                {item + 12}:00 ~ {item + 12 + 1}:00
                              </label>
                            </div>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </div>
                  <div className="d-flex flex-column align-items-center bg-clrded holidays pb-2">
                    <h6 className="mt-2">
                      <b>{t("nationalHolidays")}</b>
                    </h6>
                    {i18n.language === "en" ? (
                      <>
                        {holidays_in_english.map((item, index) => {
                          return (
                            <div className="d-flex checkbox-container col-12 ps-1">
                              <input
                                type="checkbox"
                                className="m-1 col-1"
                                name={item}
                                value={item.split(",")[0]}
                                checked={holidays && holidays.includes(item.split(",")[0])}
                                onChange={handleChangeHolidays}
                              />
                              <label className="my-auto ms-1 col-11 pe-2">{item}</label>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {holidays_in_japanese.map((item, index) => {
                          return (
                            <div className="d-flex checkbox-container col-12 ps-1">
                              <input
                                type="checkbox"
                                className="m-1 col-1 "
                                name={item}
                                value={item.split(",")[0]}
                                checked={holidays && holidays.includes(item.split(",")[0])}
                                onChange={handleChangeHolidays}
                              />
                              <label className="my-auto ms-1 col-11 pe-2">{item}</label>
                            </div>
                          );
                        })}
                      </>
                    )}
                    <Calendar
                      // inputClass="select-box-custom-holidays"
                      multiple
                      format="YYYY/MM/DD"
                      plugins={[<DatePanel />]}
                      value={customHolidays}
                      onChange={(date) => setCustomHolidays(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 pt-2 bg-clr9fa">
                <h5 className="ps-1">
                  <b>{t("importData")}</b>
                </h5>
                <div className="d-flex">
                  <div className="w-75 ps-2">
                    <div className="d-flex p-1 checkbox-container align-bottom">
                      <input type="checkbox" value={"csvAccessPoint"} checked={inputMethod === "csvAccessPoint"} onChange={handleImportDataCheckboxChange} className="m-1 " />
                      <label className="ms-2 my-auto">{t("csvData")}</label>
                      <button
                        className=" btn-44403f ms-auto "
                        style={{ marginRight: "30px" }}
                        onClick={() => {
                          setCSVdata(true);
                        }}
                        disabled={inputMethod !== "csvAccessPoint"}
                      >
                        {t("settingMoreDetails")}
                      </button>
                      {loading && <SmallLoader></SmallLoader>}
                    </div>
                    <div className="d-flex p-1 checkbox-container">
                      <input type="checkbox" value={"csvAccessLog"} checked={inputMethod === "csvAccessLog"} onChange={handleImportDataCheckboxChange} className="m-1 " />
                      <label className="ms-2 my-auto">{t("excelData")}</label>
                      <button
                        className=" btn-44403f ms-auto "
                        style={{ marginRight: "30px" }}
                        onClick={() => {
                          setExceldata(true);
                        }}
                        disabled={inputMethod !== "csvAccessLog"}
                      >
                        {t("settingMoreDetails")}
                      </button>
                    </div>
                    <div className="d-flex p-1 checkbox-container">
                      <input type="checkbox" value={"csvManual"} checked={inputMethod === "csvManual"} onChange={handleImportDataCheckboxChange} className="m-1 " />
                      <label className="ms-2 my-auto">{t("manulaInput")}</label>
                      <button className=" btn-44403f ms-auto " style={{ marginRight: "30px" }} onClick={() => setManualdata(true)} disabled={inputMethod !== "csvManual"}>
                        {t("settingMoreDetails")}
                      </button>
                    </div>
                    <div className="d-flex p-1 checkbox-container">
                      <input type="checkbox" value={"wifiAccessPoint"} checked={inputMethod === "wifiAccessPoint"} onChange={handleImportDataCheckboxChange} className="m-1 " />
                      <label className="ms-2 my-auto">{t("automaticallyCollected")}</label>
                      <button
                        className=" btn-44403f ms-auto "
                        style={{ marginRight: "30px" }}
                        onClick={() => {
                          setAutomaticWifiAccess(true);
                        }}
                        disabled={inputMethod !== "wifiAccessPoint"}
                      >
                        {t("settingMoreDetails")}
                      </button>
                    </div>
                  </div>
                  <div className="w-25 d-flex flex-column p-1">
                    <button className=" btn-6793b6 p-1 " onClick={saveSampleFile} disabled={inputMethod !== "csvAccessPoint"}>
                      {t("downloadSample")}
                    </button>
                    <button className=" btn-6793b6 p-1 mt-2" onClick={saveSampleFile} disabled={inputMethod !== "csvAccessLog"}>
                      {t("downloadSample")}
                    </button>
                    <button className=" btn-6793b6 p-1 mt-2" onClick={saveSampleFile} disabled={inputMethod !== "csvManual"}>
                      {t("downloadSample")}
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "auto", textAlign: "right" }}>
                <button className=" btn btn-submit mt-2" onClick={onSubmit}>
                  {t("update")}

                  <FaAngleRight />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <CsvAccessPointSettingModal show={CSVdata} handleClose={handleCSVdataClose}></CsvAccessPointSettingModal>
        <CsvAccessLogSettingModal show={Exceldata} handleClose={handleExceldataClose}></CsvAccessLogSettingModal>
        <CsvManualSettingModal show={Manualdata} handleClose={handleManualDataClose}></CsvManualSettingModal>
        <WifiAccessPointSettingModal show={automaticWifiAccess} handleClose={handleAutomaticWifiAccessClose}></WifiAccessPointSettingModal>
      </div>
    </Layout>
  ) : tenant ? (
    <Layout>
      <div className="col-12 px-3 child-layout">
        <Loader></Loader>
      </div>
    </Layout>
  ) : (
    <Layout>
      <div className="col-12 px-3 child-layout"></div>
    </Layout>
  );
};
export default TopPage;

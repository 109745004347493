import React, { useState, useEffect } from "react";
import Layout from "./layout/Layout";
import { Tab, Tabs, Carousel, Button, Modal } from "react-bootstrap";
import "./shared/Fileupload/Analysis.scss";
import { useTranslation } from "react-i18next";
import { AnalysisService } from "../../src/utils/services/analysis.service";
import { ApiService } from "../../src/utils/services/api";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Auth from "@aws-amplify/auth";
import Swal from "sweetalert2";
import { useStore } from "../../src/Store";
import RoleType from "../utils/constants/RoleType";
import { FaRedo } from "react-icons/fa";
import { SurveyService } from "../utils/services/survey.service";
import Loader from "../containers/Loader";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";

const initialValues = {
  admin_comments: "",
};
const savedValues = {
  admin_comments: "",
};

const apiService = new ApiService();
const analysisService = new AnalysisService();
const fileTypesforCsv = [".csv"];
const fileTypesforJpg = [".png", ".jpeg"];

export default function ComparativeReview({ title }) {
  const [key, setKey] = useState("data-analysis");
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("");
  const { tenant } = useStore();
  const [User, setUser] = useState("");
  const [cognitoTenant, setCognitoTenant] = useState("");
  const location = useLocation();
  const [formValues, setFormValues] = useState(null);
  const [paramValuesA, setParamValuesA] = useState(null);
  const [paramValuesB, setParamValuesB] = useState(null);
  const [userType, setUserType] = React.useState("user");
  const [editAdmin, setEditAdmin] = useState(false);
  const [index, setIndex] = useState(0);
  const [surveys, setSurveys] = React.useState();
  const [surveyId, setSurveyId] = React.useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(false);
  const [adminLoader, setAdminLoader] = useState(false)
  const [patternA, setPatternA] = useState(null);
  const [patternB, setPatternB] = useState(null);
  const [patternNameA, setPatternNameA] = useState(null);
  const [patternNameB, setPatternNameB] = useState(null);
  const [patternNameList, setPatternNameList] = useState("");
  const [allPatternParams, setAllPatternParams] = useState(null);

  const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");
  const embed_options = {
    url: "",
    container: "",
    // scrolling: "yes",
    // height: "AutoFit",
    // resizeHeightOnSizeChangedEvent: false,
    // iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
    // width: "100%",
    // withIframePlaceholder: true,
    // // locale: "en-US",
    footerPaddingEnabled: true,
    // // sheetId: 'YOUR_SHEETID',  // use this option to specify initial sheet id to load for the embedded dashboard
    // sheetTabsDisabled: true, // use this option to enable or disable sheet tab controls in dashboard embedding
    // printEnabled: true, // use this option to enable or disable print option for dashboard embedding
    // undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
    // resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
    // // defaultEmbeddingVisualType: TABLE // this option only applies to experience embedding and will not be used for dashboard embedding
  };

  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };

  useEffect(() => {
    getVerifier();
    if (tenant) {
      getParamsSimulation();
    }
    if (key === "data-analysis" && tenant && User && location && paramValuesA && paramValuesB) {
      getQuickSightAnalysis(paramValuesA, paramValuesB);
    }
    if (i18n.language) {
      changeLang(i18n.language);
    }

    setTypeOfUser();
  }, [i18n.language, User, tenant, location, userType, surveyId, paramValuesA, paramValuesB]);

  useEffect(() => {
    if (tenant && location && location.pathname) {
      getAdminComments();
    }
  }, [location, tenant]);

  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setUser(decoded["cognito:username"]);
      setCognitoTenant(decoded["cognito:groups"][0]);
    } catch (err) { }
  };

  const getQuickSightAnalysis = async (a, b) => {
    document.querySelector('#experience-container').innerHTML = ``;
    const {
      createEmbeddingContext,
    } = QuickSightEmbedding;

    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata);
      },
    });

    if (tenant) {
      const params = {
        user: User,
        analysis: location.pathname.split("/")[1],
        tenant: cognitoTenant,
        tenant_dashboard: tenant,
        userType: userType,
      };
      const response = analysisService.getQuickSightDashboardAnalysis(params);
      const res = await response;
      const frameOptions = Object.assign({}, embed_options);
      frameOptions["url"] = res.data.EmbedUrl
      frameOptions["container"] = document.getElementById("experience-container");

      const contentOptions = {
        parameters: [
          // in common
          { Name: 'rateAttendanceA', Values: [a.rate_attendance] },
          { Name: 'rateAttendanceB', Values: [b.rate_attendance], },
          { Name: 'numEmployeeA', Values: [a.num_employee] },
          { Name: 'numEmployeeB', Values: [b.num_employee] },
          // room simulation
          { Name: 'modeA', Values: [a.mode] },
          { Name: 'modeB', Values: [b.mode] },
          { Name: 'numPrivateRoomA', Values: [a.num_private_room] },
          { Name: 'numPrivateRoomB', Values: [b.num_private_room] },
          // cost simulation
          { Name: 'tsuboA', Values: [a.tsubo] },
          { Name: 'tsuboB', Values: [b.tsubo] },
          { Name: 'unitPriceTsuboPerTsuboMonthlyA', Values: [a.unit_price_tsubo_per_tsubo_monthly] },
          { Name: 'unitPriceTsuboPerTsuboMonthlyB', Values: [b.unit_price_tsubo_per_tsubo_monthly] },
          { Name: 'unitPriceAdminExpensePerTsuboMonthlyA', Values: [a.unit_price_admin_expense_per_tsubo_monthly] },
          { Name: 'unitPriceAdminExpensePerTsuboMonthlyB', Values: [b.unit_price_admin_expense_per_tsubo_monthly] },
          { Name: 'contractMonthsA', Values: [a.contract_months] },
          { Name: 'contractMonthsB', Values: [b.contract_months] },
          { Name: 'initialWorkMonthsA', Values: [a.initial_work_months] },
          { Name: 'initialWorkMonthsB', Values: [b.initial_work_months] },
          { Name: 'seatingCapacityA', Values: [a.seating_capacity] },
          { Name: 'seatingCapacityB', Values: [b.seating_capacity] },
          { Name: 'interiorConstructionCostPerTsuboA', Values: [a.interior_construction_cost_per_tsubo] },
          { Name: 'interiorConstructionCostPerTsuboB', Values: [b.interior_construction_cost_per_tsubo] },
          { Name: 'interiorFurnitureFeePerTsuboA', Values: [a.interior_furniture_fee_per_tsubo] },
          { Name: 'interiorFurnitureFeePerTsuboB', Values: [b.interior_furniture_fee_per_tsubo] },
          { Name: 'insituRestorationCostPerTsuboA', Values: [a.insitu_restoration_cost_per_tsubo] },
          { Name: 'insituRestorationCostPerTsuboB', Values: [b.insitu_restoration_cost_per_tsubo] },
          { Name: 'electricityAndUtilitiesPerTsuboMontlyA', Values: [a.electricity_and_utilities_per_tsubo_montly] },
          { Name: 'electricityAndUtilitiesPerTsuboMontlyB', Values: [b.electricity_and_utilities_per_tsubo_montly] },
        ],
      };

      // if (userType === RoleType.ADMIN || userType === RoleType.CONSULTANT || userType === RoleType.AGENT) {
      //   const embeddedConsoleExperience = await embeddingContext.embedConsole(frameOptions, contentOptions);
      // } else {
      //   const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);
      // }
      const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);
    };
  }

  const setTypeOfUser = async () => {
    const user = await Auth.currentUserInfo();
    setUserType(user.attributes["custom:role"]);
  };

  const onSetKey = (k) => {
    setKey(k);
    if (k === "data-analysis" && tenant && User) {
      getQuickSightAnalysis();
    }
  };

  const getParamsSimulation = async () => {
    const response = await analysisService.getParamsSimulation(tenant)
    if (response) {
      setAllPatternParams(response.data)
      setPatternNameList(response.data.map((e) => e.pattern_name))
    }
  };

  const setParamsPatternA = async (e) => {
    const new_data = allPatternParams.filter(params_one_pattern => params_one_pattern.pattern_name == e)[0];
    setParamValuesA(new_data)
    setPatternA(new_data.pattern)
  };

  const setParamsPatternB = async (e) => {
    const new_data = allPatternParams.filter(params_one_pattern => params_one_pattern.pattern_name == e)[0];
    setParamValuesB(new_data)
    setPatternB(new_data.pattern)
  };

  const getAdminComments = async () => {
    const params = {
      analysis: location.pathname.split("/")[1],
    };
    const response = analysisService.getAdminComments(params, tenant);
    try {
      const res = await response;
      if (res) {
        savedValues["admin_comments"] = res.data.comments;
        if (!res.data.comments?.length) {
          setEditAdmin(true);
        } else {
          setEditAdmin(false);
        }
      }
    } catch {
      savedValues["admin_comments"] = "";
      setEditAdmin(true);
    } finally {
      setFormValues(savedValues);
    }
  };

  const saveAdminComments = async (e) => {
    setAdminLoader(true)
    const data = {
      comments: e.admin_comments,
      analysis: location.pathname.split("/")[1],
    };
    const response = await analysisService.postAdminComments(data, tenant);
    if (response) {
      setAdminLoader(false)
      setFormValues(initialValues);
      getAdminComments();
      Swal.fire({
        title: t("comments_submitted_successfuly"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    }
  };

  const onEdit = async (formikType) => {
    if (formikType === "comments") {
      setEditAdmin(true);
    }
  };

  return (
    <Layout>
      <div className="col-12 px-2 child-layout">
        <Formik
          initialValues={formValues || initialValues}
          enableReinitialize={true}
          validateOnMount
        >
          {(formik) => {
            return (
              <Form className="text-purple p-1 h-100">
                <Grid container>
                  {/* タイトル */}
                  <Grid item xs={4} className="grid-padding">
                    <div className="d-flex justify-content-between" >
                      <div className="p-1 d-flex flex-column">
                        <div className="d-flex">
                          <h3 className="mb-0 text-body">
                            <b>{t("comparative_review")}</b>
                          </h3>
                        </div>
                        <p className="text-body mb-0">{t("comparative_review_title")}</p>
                      </div>
                    </div>
                  </Grid>
                  {/* コメント部分 */}
                  <Grid item xs={7} className="grid-padding">
                    <label
                      type="text-in"
                      className="clr-14213D pt-1"
                      htmlFor="admin_comments"
                    >
                      <h4>
                        <b>{t("admin_comments")}</b>
                      </h4>
                    </label>
                    <Field
                      as="textarea"
                      id="admin_comments"
                      name="admin_comments"
                      className="admin_comments"
                      rows={3}
                      disabled={
                        ![RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                          userType
                        ) || !editAdmin
                      }
                      placeholder={t("admin_comments")}
                    />
                    <div className="text-danger">
                      <ErrorMessage
                        name="admin_comments"
                        className="text-danger"
                      />
                    </div>
                  </Grid>

                  {/* 編集ボタン */}
                  <Grid item xs={1} className="grid-padding">
                    {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                      userType
                    ) && (
                        <div className=" align-self-end text-nowrap">
                          {!editAdmin ? (
                            <button
                              className="btn btn-14213D w-100"
                              onClick={() => onEdit("comments")}
                            >
                              {t("edit")}
                            </button>
                          ) : adminLoader ? (
                            <Loader />
                          ) : (
                            <button
                              className="btn btn-14213D w-100"
                              onClick={() => saveAdminComments(formik.values)}
                            >
                              {t("save")}
                            </button>
                          )}
                        </div>
                      )}
                    <div className="text-danger">
                      <ErrorMessage
                        name="admin_comments"
                        className="text-danger"
                      />
                    </div>
                    <button
                      className="mt-3 mb-0 bg-white border-0"
                      onClick={(e) => {
                        getQuickSightAnalysis();
                        getAdminComments();
                      }}
                    >
                      <h4 className="mt-0 mb-0 text-dark p-0">
                        <FaRedo />
                      </h4>
                    </button>
                  </Grid>

                  {/* パターンA・B選択 */}
                  <Grid item xs={12} className="grid-padding">
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                      {patternNameList && (

                        <select
                          name="pattern"
                          className="select-box"
                          onChange={(e) => {
                            getAdminComments(e.target.value)
                            setParamsPatternA(e.target.value);
                            setPatternNameA(e.target.value);
                          }}
                        >
                          <option
                            value="DEFAULT"
                            selected={patternA === null}
                            disabled
                          >
                            {t("select_pattern_A")}
                          </option>
                          {patternNameList.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      )}

                      {patternNameList && (
                        <select
                          name="pattern"
                          className="select-box"
                          onChange={(e) => {
                            getAdminComments(e.target.value)
                            setParamsPatternB(e.target.value);
                            setPatternNameB(e.target.value);
                          }}
                        >
                          <option
                            value="DEFAULT"
                            selected={patternB === null}
                            disabled
                          >
                            {t("select_pattern_B")}
                          </option>
                          {patternNameList.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      )}
                    </Grid>
                  </Grid>

                  {/* URL埋め込み */}
                  <Grid item xs={12} style={{ height: "100vh" }} className="grid-padding">
                    <div id="iframeBlock">
                      <div className="iframe-withoutTab">
                        <div className="iframeBody">
                          <div id="experience-container" className="container-dashboard-embedding"></div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout >
  );
}

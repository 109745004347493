import { ApiService } from "./api";

export class AnalysisService extends ApiService {
  async getQuickSightDashboardAnalysis(data) {
    console.log("analysis service:" + JSON.stringify(this.serializeParams(data)))
    return this.get(`/get-embed-url?${this.serializeParams(data)}`);
  }

  async postAdminComments(data, tenant) {
    return this.post(`/admin-comments?tenant=${tenant}`, data);
  }

  async getAdminComments(data, tenant) {
    return this.get(`/admin-comments?analysis=${data.analysis}&tenant=${tenant}`);
  }

  async getAnalysisStatus(tenant) {
    return this.get(`/analysis/status?tenant=${tenant}`);
  }

  async getAnalysisData(tenant, analysis) {
    return this.get(`/analysis/data?tenant=${tenant}&analysis=${analysis}`);
  }

  async getOfficeAddress(tenant) {
    return this.get(`/location-analysis/office-address?tenant=${tenant}`);
  }

  async postOfficeAddress(data) {
    return this.post(`/location-analysis/office-address`, data);
  }

  async getMap(tenant) {
    return this.get(`/location-analysis/location-map?tenant=${tenant}`);
  }

  async getSpaceAnalysisFloorMaps(tenant, update_timestamp) {
    return this.get(`/space-analysis/utilization-map?tenant=${tenant}&update_timestamp=${update_timestamp}`);
  }

  async getSpaceutitlizationMapFloorList(tenant, update_timestamp) {
    return this.get(`/space-analysis/floors?tenant=${tenant}&update_timestamp=${update_timestamp}`);
  }

  async getSpaceutitlizationHistoryList(tenant) {
    return this.get(`/space-analysis/historys?tenant=${tenant}`);
  }

  async setSpaceUtilizationData(floor, tenant, update_timestamp) {
    return this.get(`/space-analysis/data-by-floor?tenant=${tenant}&update_timestamp=${update_timestamp}&floor_level=${floor}`);
  }

  // Parameter patterns are common for cost-simulation and room-simulation
  async getParamsSimulation(tenant) {
    return this.get(`/simulation/params?tenant=${tenant}`);
  }

  async postDynamicDefaultRoomSimulation(params, tenant) {
    return this.post(`/room-simulation/dynamic-default?tenant=${tenant}`, params);
  }

  async postParamsRoomSimulation(params, pattern, tenant) {
    return this.post(`/room-simulation/params?tenant=${tenant}&pattern=${pattern}`, params);
  }

  async postDynamicDefaultCostSimulation(params, tenant) {
    return this.post(`/cost-simulation/dynamic-default?tenant=${tenant}`, params);
  }

  async postParamsCostSimulation(params, pattern, tenant) {
    return this.post(`/cost-simulation/params?tenant=${tenant}&pattern=${pattern}`, params);
  }

  async getTargetDateSlotsOperationAnalysis(tenant) {
    return this.get(`/operation-analysis/target-dates-slots?tenant=${tenant}`);
  }

  async postTargetDateSlotsOperationAnalysis(tenant, setting) {
    return this.post(`/operation-analysis/target-dates-slots?tenant=${tenant}`, setting);
  }

  async getInputMethodOperationAnalysis(tenant) {
    return this.get(`/operation-analysis/input-method?tenant=${tenant}`);
  }

  async postInputMethodOperationAnalysis(tenant, method) {
    return this.post(`/operation-analysis/input-method?tenant=${tenant}&method=${method}`);
  }

  async getInputFileMetaInfoOperationAnalysis(tenant, method) {
    return this.get(`/operation-analysis/input-file-meta-info?tenant=${tenant}&method=${method}`);
  }

  async postInputSettingOperationAnalysis(tenant, method, setting) {
    return this.post(`/operation-analysis/input-setting?tenant=${tenant}&method=${method}`, setting);
  }

  async getInputSettingOperationAnalysis(tenant, method) {
    return this.get(`/operation-analysis/input-setting?tenant=${tenant}&method=${method}`);
  }

  async getColumnValuesOperationAnalysis(tenant, column) {
    return this.get(`/operation-analysis/column-values?tenant=${tenant}&column=${column}`);
  }

  async createMerakiStack(tenant, setting) {
    return this.post(`/meraki/stack?tenant=${tenant}`, setting);
  }

  async setResourceOperationAnalysis(tenant) {
    return this.get(`/operation-analysis/set-resource?tenant=${tenant}`);
  }

  async createManualSampleOperationAnalysis() {
    return this.get(`/operation-analysis/create-manual-sample-excel`);
  }

  async getAreaOptions(area1, area2) {
    return this.get(`/area-options?area1=${area1}&area2=${area2}`);
  }

  async getOccupationsOneCompany(tenant) {
    return this.get(`/client/company/occupations/${tenant}`);
  }
}
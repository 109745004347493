import React, { useState, useEffect } from "react";
import Layout from "./layout/Layout";
import { ApiService } from "../../src/utils/services/api";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
const apiService = new ApiService();

const OfficeSample = () => {
  const tenantValues = {
    tenant1: 3,
    tenant2: 2,
  };

  const [tenant, setTenant] = useState("");
  const [_, setLang] = useState("");

  const { i18n } = useTranslation();
  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };
  useEffect(() => {
    getVerifier();
    if (i18n.language) {
      changeLang(i18n.language);
    }
  }, [i18n.language]);

  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setTenant(decoded["cognito:groups"][0]);
    } catch (err) { }
  };

  return (
    <Layout>
      <iframe
        src={`https://designers-office.jp/work/index.php?type=${tenantValues[tenant]}`}
        className="w-100 h-100"
      />
    </Layout>
  );
};
export default OfficeSample;

import React, { useState, useEffect } from "react";
import Layout from "./layout/Layout";
import { ApiService } from "../../src/utils/services/api";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { AnalysisService } from "../../src/utils/services/analysis.service";
import { useLocation } from "react-router-dom";
import { Formik, Field, Form, useField, useFormikContext, ErrorMessage } from 'formik';
import * as Yup from "yup";
import Auth from "@aws-amplify/auth";
import Swal from "sweetalert2";
import "./shared/Fileupload/Analysis.scss";
import { useStore } from "../../src/Store";
import { FaRedo } from "react-icons/fa";
import RoleType from "../utils/constants/RoleType";
import Loader from "../containers/Loader";
import { Modal } from "react-bootstrap";
import { IconContext } from 'react-icons'
import { AiOutlineRight } from 'react-icons/ai'

import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";

const apiService = new ApiService();
const analysisService = new AnalysisService();
const validationSchema = Yup.object({});

const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

export default function CostSimulation() {
  const [User, setUser] = useState("");
  const [EmbedUrl, setEmbedUrl] = useState("");
  const [_, setLang] = useState("");
  const [formValues, setFormValues] = useState(null);
  const [pattern, setPattern] = useState(null);
  const [patternName, setPatternName] = useState(null);
  const { t, i18n } = useTranslation();
  const [userType, setUserType] = React.useState("user");
  const { tenant } = useStore();
  const location = useLocation();
  const [editAdmin, setEditAdmin] = useState(false);
  const [cognitoTenant, setCognitoTenant] = useState('')
  const [adminLoader, setAdminLoader] = useState(false)
  const [paramLoader, setParamLoader] = useState(false)
  const [patternNameList, setPatternNameList] = useState("");
  const [allPatternParams, setAllPatternParams] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };

  const embed_options = {
    url: "",
    container: "",
    // scrolling: "yes",
    // height: "AutoFit",
    // resizeHeightOnSizeChangedEvent: false,
    // iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
    // width: "100%",
    // withIframePlaceholder: true,
    // // locale: "en-US",
    footerPaddingEnabled: true,
    // // sheetId: 'YOUR_SHEETID',  // use this option to specify initial sheet id to load for the embedded dashboard
    // sheetTabsDisabled: true, // use this option to enable or disable sheet tab controls in dashboard embedding
    // printEnabled: true, // use this option to enable or disable print option for dashboard embedding
    // undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
    // resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
    // // defaultEmbeddingVisualType: TABLE // this option only applies to experience embedding and will not be used for dashboard embedding
  };

  const getQuickSightAnalysis = async (e) => {
    document.querySelector('#experience-container').innerHTML = ``;
    const {
      createEmbeddingContext,
    } = QuickSightEmbedding;

    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata);
      },
    });

    if (tenant) {
      const params = {
        user: User,
        analysis: location.pathname.split("/")[1],
        tenant: cognitoTenant,
        tenant_dashboard: tenant,
        userType: userType,
      };
      const response = analysisService.getQuickSightDashboardAnalysis(params);
      const res = await response;
      const frameOptions = Object.assign({}, embed_options);
      frameOptions["url"] = res.data.EmbedUrl
      frameOptions["container"] = document.getElementById("experience-container");

      const contentOptions = {
        parameters: [
          // room simulation
          { Name: 'rateAttendance', Values: [e.rate_attendance] },
          { Name: 'numEmployee', Values: [e.num_employee] },

          // cost simulation
          { Name: 'tsubo', Values: [e.tsubo] },
          { Name: 'unitPriceTsuboPerTsuboMonthly', Values: [e.unit_price_tsubo_per_tsubo_monthly] },
          { Name: 'unitPriceAdminExpensePerTsuboMonthly', Values: [e.unit_price_admin_expense_per_tsubo_monthly] },
          { Name: 'contractMonths', Values: [e.contract_months] },
          { Name: 'initialWorkMonths', Values: [e.initial_work_months] },
          { Name: 'seatingCapacity', Values: [e.seating_capacity] },
          { Name: 'interiorConstructionCostPerTsubo', Values: [e.interior_construction_cost_per_tsubo] },
          { Name: 'interiorFurnitureFeePerTsubo', Values: [e.interior_furniture_fee_per_tsubo] },
          { Name: 'insituRestorationCostPerTsubo', Values: [e.insitu_restoration_cost_per_tsubo] },
          { Name: 'electricityAndUtilitiesPerTsuboMontly', Values: [e.electricity_and_utilities_per_tsubo_montly] },
        ],
      };
      // if (userType === RoleType.ADMIN || userType === RoleType.CONSULTANT || userType === RoleType.AGENT) {
      //   const embeddedConsoleExperience = await embeddingContext.embedConsole(frameOptions, contentOptions);
      // } else {
      //   const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);
      // }
      const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);
    };
  }

  useEffect(() => {
    if (i18n.language) {
      changeLang(i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (location.pathname === "/cost-simulation") {
      getVerifier();
      if (tenant) {
        getParamsSimulation();
      }
    }
  }, [location, tenant, pattern]);

  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setUserType(decoded['custom:role'])
      setUser(decoded["cognito:username"])
      setCognitoTenant(decoded['cognito:groups'][0])
    } catch (err) { }
  };

  const getAdminComments = async (e) => {
    const params = {
      analysis: location.pathname.split("/")[1],
    };

    const pattern_key = allPatternParams.filter(params_one_pattern => params_one_pattern.pattern_name == e)[0]["pattern"]

    const key = tenant + userType + pattern_key
    try {
      const response = await analysisService.getAdminComments(params, key);
      if (response) {
        setFormValues(formValues => ({ ...formValues, admin_comments: response.data.comments }))
      }
    } catch (err) {
      setFormValues(formValues => ({ ...formValues, admin_comments: "" }))
    }
  };

  const saveAdminComments = async (e) => {
    setAdminLoader(true)
    const data = {
      comments: e.admin_comments,
      analysis: location.pathname.split("/")[1],
    };
    const key = tenant + userType + pattern
    const response = await analysisService.postAdminComments(data, key);
    if (response) {
      setAdminLoader(false)
      Swal.fire({
        title: t("comments_submitted_successfuly"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
      getAdminComments()
      setEditAdmin(false);
    }
  };

  const onEdit = async (formikType) => {
    if (formikType === "comments") {
      setEditAdmin(true);
    }
  };

  const getParamsSimulation = async () => {
    const response = await analysisService.getParamsSimulation(tenant)
    if (response) {
      setAllPatternParams(response.data)
      setPatternNameList(response.data.map((e) => e.pattern_name))
    }
  };

  const getParamsOnePattern = async (e) => {
    const new_data = allPatternParams.filter(params_one_pattern => params_one_pattern.pattern_name == e)[0];

    setFormValues(new_data)
    setPattern(new_data.pattern)
  };

  const saveParamsCostSimulation = async (e) => {
    setParamLoader(true)
    const params = {
      num_employee: e.num_employee,
      rate_attendance: e.rate_attendance,
      tsubo: e.tsubo,
      unit_price_tsubo_per_tsubo_monthly: e.unit_price_tsubo_per_tsubo_monthly,
      unit_price_admin_expense_per_tsubo_monthly: e.unit_price_admin_expense_per_tsubo_monthly,
      contract_months: e.contract_months,
      initial_work_months: e.initial_work_months,
      seating_capacity: e.seating_capacity,
      interior_construction_cost_per_tsubo: e.interior_construction_cost_per_tsubo,
      interior_furniture_fee_per_tsubo: e.interior_furniture_fee_per_tsubo,
      insitu_restoration_cost_per_tsubo: e.insitu_restoration_cost_per_tsubo,
      electricity_and_utilities_per_tsubo_montly: e.electricity_and_utilities_per_tsubo_montly,
      seat_occupancy_rate: e.seat_occupancy_rate,
      pattern_name: e.pattern_name,
      is_default: false
    };
    const response = await analysisService.postParamsCostSimulation(params, pattern, tenant);
    if (response) {
      getParamsSimulation()
    }
    setPatternName(patternName)
  };

  return (
    <Layout>
      <div className="col-12 px-2 child-layout">
        {location.pathname === "/cost-simulation" && (
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {(formik) => {
              return (
                <Form className="text-purple p-1 h-100">
                  <Grid container >

                    {/* タイトル */}
                    <Grid item xs={4} className="grid-padding">
                      <div className="d-flex justify-content-between " >
                        <div className="p-1 d-flex flex-column ">
                          <div className="d-flex ">
                            <h3 className="mb-0 text-body">
                              <b>{t("cost_simulation")}</b>
                            </h3>
                          </div>
                          <p className="text-body mb-0 ">{t("cost_simulation_title")}</p>
                        </div>
                      </div>
                    </Grid>
                    {/* コメント部分 */}
                    <Grid item xs={7} className="grid-padding">
                      <label
                        type="text-in"
                        className="clr-14213D pt-1"
                        htmlFor="admin_comments"
                      >
                        <h4>
                          <b>{t("admin_comments")}</b>
                        </h4>
                      </label>
                      <Field
                        as="textarea"
                        id="admin_comments"
                        name="admin_comments"
                        className="admin_comments"
                        rows={3}
                        disabled={
                          ![RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                            userType
                          ) || !editAdmin
                        }
                        placeholder={t("admin_comments")}
                      />
                      <div className="text-danger">
                        <ErrorMessage
                          name="admin_comments"
                          className="text-danger"
                        />
                      </div>
                    </Grid>

                    {/* 編集ボタン */}
                    <Grid item xs={1} className="grid-padding">
                      {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                        userType
                      ) && (
                          <div className=" align-self-end text-nowrap">
                            {!editAdmin ? (
                              <button
                                className="btn btn-14213D w-100"
                                disabled={pattern === null}
                                onClick={() => onEdit("comments")}
                              >
                                {t("edit")}
                              </button>
                            ) : adminLoader ? (
                              <Loader />
                            ) : (
                              <button
                                className="btn btn-14213D w-100"
                                onClick={() => saveAdminComments(formik.values)}
                              >
                                {t("save")}
                              </button>
                            )}
                          </div>
                        )}
                      <div className="text-danger">
                        <ErrorMessage
                          name="admin_comments"
                          className="text-danger"
                        />
                      </div>
                      <button
                        className="mt-1 mb-0 bg-white border-0"
                        onClick={(e) => {
                          getQuickSightAnalysis();
                          getAdminComments();
                        }}
                      >
                        <h4 className="mt-0 mb-0 text-dark p-0">
                          <FaRedo />
                        </h4>
                      </button>
                    </Grid>

                    {/* PATTERN  and PARAMS and ACTION*/}
                    <Grid item xs={4} className="grid-padding">
                      <Grid container className=" gap-2" justifyContent="flex-start" alignItems="flex-start" >
                        {/* PATTERN */}
                        <Grid item xs={12} >
                          <Grid container className="container-pattern" direction="row" >
                            <Grid item xs={5} >
                              <h5 className="mt-2 ms-2">
                                <b style={{ "color": "#454648" }}>{t("pattern")}</b>
                              </h5>
                            </Grid>

                            <Grid item xs={7} className="d-flex align-items-center justify-content-center" >
                              {patternNameList && (
                                <select
                                  name="pattern"
                                  className="param-input"
                                  // className="btn btn-14213D "
                                  onChange={(e) => {
                                    getAdminComments(e.target.value)
                                    getParamsOnePattern(e.target.value);
                                    setPatternName(e.target.value);
                                    getVerifier();
                                  }}
                                >
                                  <option
                                    value="DEFAULT"
                                    selected={pattern === null}
                                    disabled
                                  >
                                    {t("select_pattern")}
                                  </option>
                                  {patternNameList.map((item) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                                </select>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* PARAMS */}
                        <Grid item xs={12} >
                          <Grid container className="container-params-cost-simulation pb-3 " direction="row" >
                            <Grid item xs={12} >
                              <h5 className="mt-2 ms-2">
                                <b style={{ "color": "#454648" }}>{t("params")}</b>
                              </h5>
                            </Grid>
                            <Grid item xs={12} >
                              <Grid container direction="row" >
                                <Grid item xs={12} >
                                  <h6 className="ms-1 mt-3 mb-0" >
                                    <b className="simulation-plan">{t("operation_plan")}</b>
                                  </h6>
                                </Grid>
                                <Grid container className="simulation-plan-frame " direction="row" >

                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="num_employee"
                                    >
                                      <b> {t("num_employee")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="num_employee"
                                      name="num_employee"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "num_employee",
                                          e.target.value || ""
                                        )
                                        formik.setFieldValue("seat_occupancy_rate", e.target.value * formValues['rate_attendance'] / formValues['seating_capacity'])
                                      }
                                      }
                                      placeholder={t("num_employee") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="num_employee"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="rate_attendance"
                                    >
                                      <b> {t("rate_attendance")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="rate_attendance"
                                      name="rate_attendance"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "rate_attendance",
                                          e.target.value || ""
                                        )
                                        formik.setFieldValue("seat_occupancy_rate", e.target.value * formValues['num_employee'] / formValues['seating_capacity'])
                                      }

                                      }
                                      placeholder={t("rate_attendance") + " " + t("input_param") + " " + t("from_0_to_1")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="rate_attendance"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="seating_capacity"
                                    >
                                      <b> {t("seating_capacity")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="seating_capacity"
                                      name="seating_capacity"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "seating_capacity",
                                          e.target.value || ""
                                        )
                                        formik.setFieldValue("seat_occupancy_rate", formValues['num_employee'] * formValues['rate_attendance'] / e.target.value)
                                      }
                                      }
                                      placeholder={t("seating_capacity") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="seating_capacity"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="seat_occupancy_rate"
                                    >
                                      <b> {t("seat_occupancy_rate")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="seat_occupancy_rate"
                                      name="seat_occupancy_rate"
                                      className="param-input"
                                      disabled={true}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "seat_occupancy_rate",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("seat_occupancy_rate") + " " + t("input_param") + " " + t("from_0_to_1")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="seat_occupancy_rate"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} >
                              <Grid container direction="row" >
                                <Grid item xs={12} >
                                  <h6 className="ms-1 mt-3 mb-0">
                                    <b className="simulation-plan">{t("rental_plan")}</b>
                                  </h6>
                                </Grid>
                                <Grid container className="simulation-plan-frame" direction="row" >

                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="tsubo"
                                    >
                                      <b> {t("tsubo")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="tsubo"
                                      name="tsubo"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "tsubo",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("tsubo") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="tsubo"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="unit_price_tsubo_per_tsubo_monthly"
                                    >
                                      <b> {t("unit_price_tsubo_per_tsubo_monthly")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="unit_price_tsubo_per_tsubo_monthly"
                                      name="unit_price_tsubo_per_tsubo_monthly"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "unit_price_tsubo_per_tsubo_monthly",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("unit_price_tsubo_per_tsubo_monthly") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="unit_price_tsubo_per_tsubo_monthly"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="unit_price_admin_expense_per_tsubo_monthly"
                                    >
                                      <b> {t("unit_price_admin_expense_per_tsubo_monthly")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="unit_price_admin_expense_per_tsubo_monthly"
                                      name="unit_price_admin_expense_per_tsubo_monthly"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "unit_price_admin_expense_per_tsubo_monthly",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("unit_price_admin_expense_per_tsubo_monthly") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="unit_price_admin_expense_per_tsubo_monthly"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="contract_months"
                                    >
                                      <b> {t("contract_months")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="contract_months"
                                      name="contract_months"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "contract_months",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("contract_months") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="contract_months"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>

                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="electricity_and_utilities_per_tsubo_montly"
                                    >
                                      <b> {t("electricity_and_utilities_per_tsubo_montly")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="electricity_and_utilities_per_tsubo_montly"
                                      name="electricity_and_utilities_per_tsubo_montly"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "electricity_and_utilities_per_tsubo_montly",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("electricity_and_utilities_per_tsubo_montly") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="electricity_and_utilities_per_tsubo_montly"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} >
                              <Grid container direction="row" >
                                <Grid item xs={12}>
                                  <h6 className="ms-1 mt-3 mb-0">
                                    <b className="simulation-plan">{t("construction_plan")}</b>
                                  </h6>
                                </Grid>
                                <Grid container className="simulation-plan-frame " direction="row" >

                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="initial_work_months"
                                    >
                                      <b> {t("initial_work_months")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="initial_work_months"
                                      name="initial_work_months"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "initial_work_months",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("initial_work_months") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="initial_work_months"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>

                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="interior_construction_cost_per_tsubo"
                                    >
                                      <b> {t("interior_construction_cost_per_tsubo")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="interior_construction_cost_per_tsubo"
                                      name="interior_construction_cost_per_tsubo"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "interior_construction_cost_per_tsubo",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("interior_construction_cost_per_tsubo") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="interior_construction_cost_per_tsubo"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="interior_furniture_fee_per_tsubo"
                                    >
                                      <b> {t("interior_furniture_fee_per_tsubo")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="interior_furniture_fee_per_tsubo"
                                      name="interior_furniture_fee_per_tsubo"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "interior_furniture_fee_per_tsubo",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("interior_furniture_fee_per_tsubo") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="interior_furniture_fee_per_tsubo"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={5} >
                                    <label
                                      type="text"
                                      className="clr-14213D fnt-14 param"
                                      htmlFor="insitu_restoration_cost_per_tsubo"
                                    >
                                      <b> {t("insitu_restoration_cost_per_tsubo")}</b>
                                    </label>
                                  </Grid>
                                  <Grid item xs={7} >
                                    <Field
                                      type="text"
                                      id="insitu_restoration_cost_per_tsubo"
                                      name="insitu_restoration_cost_per_tsubo"
                                      className="param-input"
                                      disabled={!pattern}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "insitu_restoration_cost_per_tsubo",
                                          e.target.value || ""
                                        )
                                      }
                                      placeholder={t("insitu_restoration_cost_per_tsubo") + " " + t("input_param")}
                                    />
                                    <div className="text-danger mb-1 ">
                                      <ErrorMessage
                                        name="insitu_restoration_cost_per_tsubo"
                                        className="text-danger"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* ACTION BUTTONS */}
                        <Grid item xs={12} >
                          <Grid container direction="row" justifyContent="center" spacing={1}>

                            <Grid item xs={12} >
                              <Grid container direction="row" spacing={0} justifyContent="center">
                                <button
                                  type="button"
                                  className="btn-simulate"
                                  disabled={!formik.isValid || !tenant}
                                  onClick={() => getQuickSightAnalysis(formik.values)}
                                >
                                  <b>{t("Simulate")}</b>
                                </button>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container direction="row" spacing={0} justifyContent="center">
                                <button
                                  type="button"
                                  className="btn-save"
                                  disabled={!formik.isValid || !tenant}
                                  onClick={() => handleShow()}
                                >
                                  <b>{t("Save")}</b>
                                </button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* DASHBOARD EMBEDDING */}
                    <Grid item xs={8} className="grid-padding">
                      <div id="iframeBlock">
                        <div className="iframe-withoutTab">
                          <div className="iframeBody">
                            <div id="experience-container" className="container-dashboard-embedding"></div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  {/* SAVE PATTERN */}
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title className="text-dark">
                        {t("save_pattern")}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* PatternName */}
                      <Form className="text-body" onSubmit={formik.handleSubmit}>
                        <div className="col-12">
                          <label
                            type="text"
                            className="clr-14213D fnt-14 param"
                            htmlFor="pattern_name"
                          >
                            <b> {t("pattern_name")}</b>
                          </label>
                          <Field
                            type="text"
                            id="pattern_name"
                            name="pattern_name"
                            className="w-100 "
                            placeholder={t("pattern_name")}
                            // disabled={isEdit}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "pattern_name",
                                e.target.value || ""
                              )
                              setPatternName(e.target.value.replaceAll(" ", ""))
                            }
                            }
                          />
                          <div className="text-danger mb-1 ">
                            <ErrorMessage name="tenantId" className="text-danger" />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end pt-2">
                          {/* SAVE */}
                          <button
                            type="button"
                            className="btn btn-green op-100"
                            disabled={!formik.isValid}
                            onClick={() => {
                              saveParamsCostSimulation(formik.values)
                              setShow(false)
                              setPattern(pattern)
                            }
                            }
                          >
                            {t("save")}
                          </button>

                          {/* CANCEL */}
                          <button
                            type="button"
                            className="btn btn-E65C4F ms-3 op-100"
                            onClick={() => setShow(false)}
                          >
                            {t("cancel")}
                          </button>
                        </div>
                      </Form>
                    </Modal.Body>
                  </Modal>
                </Form>
              );
            }}
          </Formik>
        )
        }
      </div >
    </Layout >
  )
}
import { API, Auth } from "aws-amplify";
import * as _ from 'lodash';
export class ApiService {
  api = "";
  constructor() {
    this.api = "wdp-api";
  }

  async getToken() {
    try{      
      const data = await Auth.currentSession();
      const valid = data.isValid();
      if (valid) {
        return data.idToken.jwtToken;
      }   else {
        window.open("/", "_self");
      }
    } catch (err) {
      console.log("gettokenerr")
      return "Bearer";
    }
  }

  async get(endpoint, queryParams = {}) {
    const params = {
      headers: { Authorization: await this.getToken() },
      response: true,
      queryStringParameters: queryParams,
    };
    return API.get(this.api, endpoint, params);
  }

  async post(endpoint, data, queryParams = {}, responseType) {
    const params = {
      headers: { Authorization: await this.getToken() },
      response: true,
      body: data,
      queryStringParameters: queryParams,
      responseType
    };
    return API.post(this.api, endpoint, params);
  }

  async put(endpoint, data) {
    const params = {
      headers: { Authorization: await this.getToken() },
      response: true,
      body: data,
    };
    return API.put(this.api, endpoint, params);
  }
  async delete(endpoint) {
    const params = {
      headers: { Authorization: await this.getToken() },
      response: true,
    };
    return API.del(this.api, endpoint, params);
  }

  serializeParams(obj) {
    const str = [];
    for (const prop in obj) {
      if (
        obj.hasOwnProperty(prop) &&
        !_.isNull(obj[prop]) &&
        !_.isUndefined(obj[prop]) &&
        !(_.isArray(obj[prop]) && _.isEmpty(obj[prop])) &&
        obj[prop] !== undefined &&
        obj[prop] !== ''
      ) {
        str.push(
          encodeURIComponent(prop) +
          '=' +
          encodeURIComponent(_.isArray(obj[prop]) ? obj[prop].join(',') : obj[prop]),
        );
      }
    }
    return str.join('&');
  }
}

import { ApiService } from "./api";

export class RegisterService extends ApiService {
    async createUser(data) {
        return this.post(`/user`, data);
    }

    deleteUser(data) {
        return this.delete(`/user?${this.serializeParams(data)}`)
    }

    async adminSetUserPwd(email) {
        const data = {
            email: email
        };
        return this.post(`/admin-set-password`,data);
    }
}
export const industry_en = {
  construction: "Construction",
  real_estate: "Real estate",
  energy: "Energy",
  coal_mining: "Coal and ore mining",
  material_processing: "Material / Material processing",
  mechanical_industry: "Mechanical and electrical industry",
  transport_machinery: "Transport machinery",
  food: "Food",
  consumer_goods: "Consumer goods",
  medical_biotechno: "Medical and biotechnology",
  intermediary_distribution: "Intermediary distribution",
  retail_trade: "Retail trade",
  food_Service: "Food service and mid - market food",
  finance: "Finance",
  transport_services: "Transport services",
  advertisement: "Advertisement",
  inform_technology: "Information technology",
  consumer_services: "Consumer services",
  corporate_services: "Corporate Services",
  public_service: "Public service",
  other: "Other",

};

export const industry_ja = {
  construction: "建設",
  real_estate: "不動産",
  energy: "エネルギー",
  coal_mining: "石炭・鉱石採掘",
  material_processing: "素材・素材加工品",
  mechanical_industry: "機械・電気製品",
  transport_machinery: "輸送機械",
  food: "食品",
  consumer_goods: "消費財",
  medical_biotechno: "医療・バイオ",
  intermediary_distribution: "中間流通",
  retail_trade: "小売",
  food_Service: "外食・中食",
  finance: "金融",
  transport_services: "運輸サービス",
  advertisement: "広告",
  inform_technology: "ーIT・情報通信サビス",
  consumer_services: "消費者サービス",
  corporate_services: "法人サービス",
  public_service: "公共サービス",
  other: "その他",

};

export const industry_en_2 = [
  { industry1: 'construction', industry2: 'construction_1' },
  { industry1: 'construction', industry2: 'construction_2' },
]

export const industry_ja_2 = [
  { industry1: 'IT・インターネット', industry2: 'インターネットサービス' },
  { industry1: 'IT・インターネット', industry2: 'デジタルマーケティング' },
  { industry1: 'IT・インターネット', industry2: 'Sler' },
  { industry1: 'IT・インターネット', industry2: 'ソフトウェア' },
  { industry1: 'IT・インターネット', industry2: 'ハードウェア' },
  { industry1: 'IT・インターネット', industry2: '通信・キャリア' },
  { industry1: 'IT・インターネット', industry2: 'その他' },
  { industry1: 'メーカー', industry2: '電気・電子' },
  { industry1: 'メーカー', industry2: '半導体' },
  { industry1: 'メーカー', industry2: '機械' },
  { industry1: 'メーカー', industry2: '精密・計測機器' },
  { industry1: 'メーカー', industry2: '自動車・自動車部品' },
  { industry1: 'メーカー', industry2: '化学・石油' },
  { industry1: 'メーカー', industry2: 'バイオ' },
  { industry1: 'メーカー', industry2: '素材' },
  { industry1: 'メーカー', industry2: '食品・飲料' },
  { industry1: 'メーカー', industry2: '日用品' },
  { industry1: 'メーカー', industry2: '化粧品' },
  { industry1: 'メーカー', industry2: 'アパレル・ファッション' },
  { industry1: 'メーカー', industry2: 'その他' },
  { industry1: '商社', industry2: '総合商社' },
  { industry1: '商社', industry2: '専門商社' },
  { industry1: '流通・小売', industry2: '流通' },
  { industry1: '流通・小売', industry2: '小売' },
  { industry1: '流通・小売', industry2: 'その他' },
  { industry1: 'コンサルティング', industry2: 'コンサルティング' },
  { industry1: 'コンサルティング', industry2: 'シンクタンク' },
  { industry1: 'コンサルティング', industry2: 'リサーチ' },
  { industry1: 'コンサルティング', industry2: 'その他' },
  { industry1: 'マスコミ・メディア', industry2: '広告・PR' },
  { industry1: 'マスコミ・メディア', industry2: 'テレビ・放送・映像・音響' },
  { industry1: 'マスコミ・メディア', industry2: '新聞・出版' },
  { industry1: 'マスコミ・メディア', industry2: '印刷' },
  { industry1: 'マスコミ・メディア', industry2: 'その他' },
  { industry1: 'エンターテインメント', industry2: '音楽' },
  { industry1: 'エンターテインメント', industry2: '映画' },
  { industry1: 'エンターテインメント', industry2: 'ゲーム' },
  { industry1: 'エンターテインメント', industry2: 'その他' },
  { industry1: '金融', industry2: '銀行・信託銀行' },
  { industry1: '金融', industry2: '信用金庫・組合' },
  { industry1: '金融', industry2: '証券' },
  { industry1: '金融', industry2: '投資銀行' },
  { industry1: '金融', industry2: 'アセットマネジメント' },
  { industry1: '金融', industry2: 'プライベートエクイティ・ファンド' },
  { industry1: '金融', industry2: '不動産ファンド' },
  { industry1: '金融', industry2: 'ベンチャーキャピタル' },
  { industry1: '金融', industry2: 'クレジット・信販' },
  { industry1: '金融', industry2: '政府系金融機関' },
  { industry1: '金融', industry2: 'その他' },
  { industry1: '建設', industry2: '建設・建築・土木' },
  { industry1: '建設', industry2: '設備・電気' },
  { industry1: '建設', industry2: '住宅設備・ハウスメーカー' },
  { industry1: '建設', industry2: 'プラント・エンジニアリング' },
  { industry1: '建設', industry2: '内装・リフォーム・インテリア' },
  { industry1: '建設', industry2: 'その他' },
  { industry1: 'メディカル', industry2: '医薬品メーカー' },
  { industry1: 'メディカル', industry2: '医療機器メーカー' },
  { industry1: 'メディカル', industry2: '医薬品卸' },
  { industry1: 'メディカル', industry2: '医療機器卸' },
  { industry1: 'メディカル', industry2: '病院・クリニック' },
  { industry1: 'メディカル', industry2: '大学・研究施設' },
  { industry1: 'メディカル', industry2: 'CRO' },
  { industry1: 'メディカル', industry2: '臨床検査機器・診断薬' },
  { industry1: 'メディカル', industry2: 'ドラッグストア・調剤薬局' },
  { industry1: 'メディカル', industry2: '再生医療・バイオベンチャー' },
  { industry1: 'メディカル', industry2: 'その他' },
  { industry1: 'エネルギー', industry2: '電気・ガス・水道' },
  { industry1: 'エネルギー', industry2: '石油' },
  { industry1: 'エネルギー', industry2: 'エネルギー' },
  { industry1: 'エネルギー', industry2: 'その他' },
  { industry1: '保険', industry2: '生命保険' },
  { industry1: '保険', industry2: '損害保険' },
  { industry1: '保険', industry2: 'その他' },
  { industry1: '不動産', industry2: 'デベロッパー' },
  { industry1: '不動産', industry2: '不動産仲介' },
  { industry1: '不動産', industry2: '不動産管理' },
  { industry1: '不動産', industry2: 'その他' },
  { industry1: '士業', industry2: '監査・税理士法人' },
  { industry1: '士業', industry2: '法律事務所' },
  { industry1: '士業', industry2: 'その他' },
  { industry1: 'サービス', industry2: '人材紹介・人材派遣' },
  { industry1: 'サービス', industry2: 'アウトソーシング・コールセンター' },
  { industry1: 'サービス', industry2: '旅行・観光' },
  { industry1: 'サービス', industry2: 'ホテル' },
  { industry1: 'サービス', industry2: 'アミューズメント' },
  { industry1: 'サービス', industry2: '福祉・介護' },
  { industry1: 'サービス', industry2: '外食' },
  { industry1: 'サービス', industry2: 'ブライダル' },
  { industry1: 'サービス', industry2: 'その他' },
  { industry1: '運輸・交通', industry2: '海運' },
  { industry1: '運輸・交通', industry2: '鉄道' },
  { industry1: '運輸・交通', industry2: '陸運' },
  { industry1: '運輸・交通', industry2: '空輸' },
  { industry1: '運輸・交通', industry2: '空港' },
  { industry1: '運輸・交通', industry2: 'その他' },
  { industry1: '物流・倉庫', industry2: '物流' },
  { industry1: '物流・倉庫', industry2: '倉庫' },
  { industry1: '物流・倉庫', industry2: 'その他' },
  { industry1: 'その他（教育・官公庁など）', industry2: '教育' },
  { industry1: 'その他（教育・官公庁など）', industry2: '官公庁' },
  { industry1: 'その他（教育・官公庁など）', industry2: '自治体' },
  { industry1: 'その他（教育・官公庁など）', industry2: '農林・水産' },
  { industry1: 'その他（教育・官公庁など）', industry2: 'その他' }
]
import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from "react-chartjs-2";
import Loader from "../containers/Loader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    indexAxis: "y",
    scales: {
        x: {
            stacked: true,
            display: false,
            grid: {
                display: false
            },
        },
        y: {
            stacked: true,
            grid: {
                display: false
            },
        }
    },
    elements: {
        bar: {
            borderWidth: 2
        }
    },
    responsive: true,
    plugins: {
        legend: {
            position: "top"
        },
        title: {
            display: false,
        },
        datalabels: {
            display: true,
            color: "white",
            formatter: (value, ctx) => {
                return value + "%";
            },
            font: {
                size: "20px",
            }
            // anchor: "end",
            // offset: -20,
            // align: "start"
        }
    }
};

export const StackedBarChart = props => {
    return props.data === "empty" ? (
        <div className="top-page-content-1row top-page-content-no-data">
            No Data
        </div>
    ) : props.data ? (
        <div className="top-page-content-1row">
            <div>
                <p className="kpi-card-title">{props["title"]}</p>
            </div>
            <Bar
                options={options}
                plugins={[ChartDataLabels]}
                data={props["data"][props.data_key]}
                width={1000}
            // height={120}
            />
        </div>
    ) : (
        <div className="top-page-content-1row">
            <Loader></Loader>
        </div>
    )
};
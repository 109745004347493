import { ApiService } from "./api";

export class ClientService extends ApiService {
  async createCompany(data) {
    return this.post("/client/company", data);
  }

  async listCompany(data) {
    return this.get(`/client/company?${this.serializeParams(data)}`);
  }

  async getCompany(id) {
    return this.get(`/client/company/${id}`);
  }

  async deleteCompany(id) {
    return this.delete(`/client/company/${id}`);
  }

  async getUser(data) {
    return this.get(`/client/user?${this.serializeParams(data)}`);
  }

  async postCsv(data) {
    return this.post(`/client/user/file`, data);
  }

  async checkIp() {
    return this.get("/check-ip");
  }

  async checkContractTime(email) {
    return this.get(`/check-user-tenant-expiretime?email=${email}`);
  }

  async isSameAsTempPassword(email, password) {
    return this.post(`/is-same-as-temp-password`, { email, new_password: password });
  }
}

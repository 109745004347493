import { React } from "react";
import Header from "../Nav";
import Sidebar from "../Sidebar";
import layoutScss from "../layout/layout.scss";
import { useTranslation } from "react-i18next";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import useCompaniesInfo from "../../../src/useDataFeching";

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { companies, setCompanies, isLoading } = useCompaniesInfo();
  return (
    <div
      className={
        i18n.language === "en" ? "english-font-family" : "japan-font-family"
      }
    >
      <Header companies={companies} setCompanies={setCompanies} isLoading={isLoading} />
      <div className="d-flex w-100" style={{ position: "sticky" }}>
        <Sidebar activeListItem="summary" />
        <div className="mx-1 vh-fix mt-1 w-100">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;

import React, { useState, useEffect, useCallback } from "react";
import Layout from "./layout/Layout";
import { Tab, Tabs, Carousel, Modal, Button } from "react-bootstrap";
import "./shared/Fileupload/Analysis.scss";
import { useTranslation } from "react-i18next";
import { AnalysisService } from "../utils/services/analysis.service";
import { ApiService } from "../utils/services/api";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import workplace from "../assets/img/workplace.png";
import Swal from "sweetalert2";
import { useStore } from "../Store";
import RoleType from "../utils/constants/RoleType";
import { FaRedo } from "react-icons/fa";
import Loader from "./Loader";
import {
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";

const initialValues = {
    admin_comments: "",
};
const savedValues = {
    admin_comments: "",
};
const validationSchema = Yup.object({

});
const apiService = new ApiService();
const analysisService = new AnalysisService();

const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

export default function Analysis() {
    const [key, setKey] = useState("data-analysis");
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState("");
    const { tenant } = useStore();
    const [User, setUser] = useState("");
    const [EmbedUrl, setEmbedUrl] = useState("");
    const location = useLocation();
    const [formValues, setFormValues] = useState(null);
    const [userType, setUserType] = React.useState("user");
    const [editAdmin, setEditAdmin] = useState(false);
    const [index, setIndex] = useState(0);
    const [cognitoTenant, setCognitoTenant] = useState("");
    const [floorMapInfo, setFloorMapInfo] = useState([]);
    const [floorMap, setFloorMap] = useState(null);
    const [floorList, setFloorList] = useState(["all"]);
    const [historyList, setHistoryList] = useState([]);
    const [updateTimeStamp, setUpdateTimeStamp] = useState("");
    const [all, setAll] = useState(true);
    const [floor, setFloor] = useState("all");
    const [adminLoader, setAdminLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState('')
    const [workplaceData, setWorkplaceData] = useState(false);

    const embed_options = {
        url: "",
        container: "",
        // scrolling: "yes",
        // height: "AutoFit",
        // resizeHeightOnSizeChangedEvent: false,
        // iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
        // width: "100%",
        // withIframePlaceholder: true,
        // // locale: "en-US",
        footerPaddingEnabled: true,
        // // sheetId: 'YOUR_SHEETID',  // use this option to specify initial sheet id to load for the embedded dashboard
        // sheetTabsDisabled: true, // use this option to enable or disable sheet tab controls in dashboard embedding
        // printEnabled: true, // use this option to enable or disable print option for dashboard embedding
        // undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
        // resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
        // // defaultEmbeddingVisualType: TABLE // this option only applies to experience embedding and will not be used for dashboard embedding
    };
    const handleWorkplaceDataclose = () => setWorkplaceData(false);
    const handleClose = () => setShow(false);
    const handleShow = (e, url) => {
        setShow(true)
        setUrl(url)
    };
    const changeLang = (val) => {
        setLang(val);
        sessionStorage.setItem("lang", val);
        i18n.changeLanguage(val);
    };

    useEffect(() => {
        getVerifier();
        getQuickSightAnalysis(floor, updateTimeStamp)
        if (i18n.language) {
            changeLang(i18n.language);
        }
    }, [i18n.language, User, tenant, location, userType]);

    useEffect(() => {
        if (tenant && location) {
            getAdminComments();
        }
        // if (tenant && location) {
        //     getSpaceutitlizationHistoryList();
        // }
        if (tenant && location) {
            getSpaceAnalysisFloorMaps(tenant);
            getSpaceutitlizationMapFloorList();
        }
        if (location.pathname) {
            setKey("data-analysis");
        }
    }, [location, tenant, formValues]);

    const getVerifier = async () => {
        try {
            const response = await apiService.getToken();
            const decoded = jwt_decode(response);
            setUser(decoded["cognito:username"]);
            setCognitoTenant(decoded["cognito:groups"][0]);
            setUserType(decoded["custom:role"]);
        } catch (err) { }
    };

    const getQuickSightAnalysis = async (e, updateTimestamp) => {
        document.querySelector('#experience-container').innerHTML = ``;

        const {
            createEmbeddingContext,
        } = QuickSightEmbedding;

        const embeddingContext = await createEmbeddingContext({
            onChange: (changeEvent, metadata) => {
                console.log('Context received a change', changeEvent, metadata);
            },
        });
        if (tenant) {
            const params = {
                user: User,
                analysis: location.pathname.split("/")[1],
                tenant: cognitoTenant,
                tenant_dashboard: tenant,
                userType: userType,
            };
            const response = await analysisService.getQuickSightDashboardAnalysis(params);
            const frameOptions = Object.assign({}, embed_options);
            frameOptions["url"] = response.data.EmbedUrl
            frameOptions["container"] = document.getElementById("experience-container");

            const contentOptions = {
                parameters: [
                    {
                        Name: 'updateTimestamp',
                        Values: [
                            updateTimestamp
                        ],
                    },
                    {
                        Name: 'floorLevel',
                        Values: [
                            e
                        ],
                    }
                ],
            };

            const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);

        };
        setFloor(e)
    }

    const getAdminComments = async () => {
        const params = {
            analysis: location.pathname.split("/")[1],
        };
        const response = await analysisService.getAdminComments(params, tenant);
        if (response) {
            savedValues["admin_comments"] = response.data.comments;
            setFormValues(savedValues);
        }
    };

    const saveAdminComments = async (e) => {
        setAdminLoader(true);
        const data = {
            comments: e.admin_comments,
            analysis: location.pathname.split("/")[1],
        };
        const response = await analysisService.postAdminComments(data, tenant);
        if (response) {
            setAdminLoader(false);
            setFormValues(initialValues);
            getAdminComments();
            Swal.fire({
                title: t("comments_submitted_successfuly"),
                confirmButtonColor: "#D57B85",
                confirmButtonText: "OK",
                allowOutsideClick: false,
            })
            setEditAdmin(false);
        }
    };

    const onEdit = async (formikType) => {
        if (formikType === "comments") {
            setEditAdmin(true);
        }
    };
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const getSpaceAnalysisFloorMaps = async (tenant) => {
        const {
            data: { data },
        } = await analysisService.getSpaceAnalysisFloorMaps(tenant, updateTimeStamp);
        setFloorMapInfo(data);
    };

    const handleFloor = async (e) => {
        getQuickSightAnalysis(e, updateTimeStamp)
        setAll(false);
        setFloor(e);
        if (e === "all") {
            setAll(true);
        } else if (e !== "all") {
            const info = floorMapInfo.find((item) => {
                return item.floor === e;
            });
            setFloorMap(info);
        }
    };

    const handleUpdateTimestamp = async (e) => {
        getQuickSightAnalysis(floor, e)
        setUpdateTimeStamp(e.target.value)
        setAll(true);
    };

    const getSpaceutitlizationMapFloorList = async () => {
        const res = await analysisService.getSpaceutitlizationMapFloorList(tenant, updateTimeStamp);
        setFloorList(res.data.floors.filter((v, i, a) => a.indexOf(v) === i));
    };

    const getSpaceutitlizationHistoryList = async () => {
        const res = await analysisService.getSpaceutitlizationHistoryList(tenant);
        console.log("historyList:" + JSON.stringify(res))
        setHistoryList(res.data.historys.filter((v, i, a) => a.indexOf(v) === i).slice(0, 10));
        if(!updateTimeStamp && res.data.historys.length > 0){
            setUpdateTimeStamp(historyList?historyList[0]:"")
        }
    };
    
    const dateFormat = (time) => {
        const dateTime = new Date(Number(time))
        const yearStr = dateTime.getFullYear()
        const monthStr = ("00" + (dateTime.getMonth() + 1)).slice( -2 )
        const dateStr = ("00" + (dateTime.getDate() + 1)).slice( -2 )
        const hourStr = ("00" + (dateTime.getHours() + 1)).slice( -2 )
        const minituteStr = ("00" + (dateTime.getMinutes() + 1)).slice( -2 )
        const formatStr = yearStr + "年" 
                            + monthStr + "月" 
                            + dateStr + "日 "
                            + hourStr + "時"
                            + minituteStr + "分データ"
        return formatStr
    };

    return (
        <Layout>
            <div className="col-12 px-2 child-layout">
                {location.pathname === "/space-analysis" && (
                    <Formik
                        initialValues={formValues || initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        validateOnMount
                    >
                        {(formik) => {
                            return (
                                <Form className="text-purple p-1 h-100">
                                    <Grid container>
                                        {/* タイトル */}
                                        <Grid item xs={4} className="grid-padding">
                                            <div className="d-flex justify-content-between container-title" >
                                                <div className="p-1 d-flex flex-column ">
                                                    <div className="d-flex ">
                                                        <h3 className="mb-0 text-body">
                                                            <b>{t("Space")}</b>
                                                        </h3>
                                                    </div>
                                                    <p className="text-body mb-0 ">{t("analysis_space")}</p>
                                                </div>
                                            </div>
                                        </Grid>
                                        {/* コメント部分 */}
                                        <Grid item xs={7} className="grid-padding">
                                            <label
                                                type="text-in"
                                                className="clr-14213D pt-1"
                                                htmlFor="admin_comments"
                                            >
                                                <h4>
                                                    <b>{t("admin_comments")}</b>
                                                </h4>
                                            </label>
                                            <Field
                                                as="textarea"
                                                id="admin_comments"
                                                name="admin_comments"
                                                className="admin_comments"
                                                rows={3}
                                                disabled={
                                                    ![RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                                                        userType
                                                    ) || !editAdmin
                                                }
                                                placeholder={t("admin_comments")}
                                            />
                                        </Grid>

                                        {/* 編集ボタン */}
                                        <Grid item xs={1} className="grid-padding">
                                            {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                                                userType
                                            ) && (
                                                    <div className=" align-self-end text-nowrap">
                                                        {!editAdmin ? (
                                                            <button
                                                                className="btn btn-14213D w-100"
                                                                onClick={() => onEdit("comments")}
                                                            >
                                                                {t("edit")}
                                                            </button>
                                                        ) : adminLoader ? (
                                                            <Loader />
                                                        ) : (
                                                            <button
                                                                className="btn btn-14213D w-100"
                                                                onClick={() => saveAdminComments(formik.values)}
                                                            >
                                                                {t("save")}
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            <div className="text-danger">
                                                <ErrorMessage
                                                    name="admin_comments"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <button
                                                className="mt-3 mb-0 bg-white border-0"
                                                onClick={(e) => {
                                                    getQuickSightAnalysis(floor, updateTimeStamp);
                                                    getAdminComments();
                                                }}
                                            >
                                                <h4 className="mt-0 mb-0 text-dark p-0">
                                                    <FaRedo />
                                                </h4>
                                            </button>
                                        </Grid>

                                        <Grid item xs={12} style={{ height: "80vh" }} className="grid-padding">
                                            {floorList && (
                                                <div className="row my-1">
                                                    {/* 画像 */}
                                                    <Grid item xs={12} >
                                                        <div className={`${floorMap && !all ? '' : ''}`}>
                                                            {/* 個別の画像の場合 */}
                                                            {floorMap && !all && (
                                                                <>
                                                                    <div className="d-flex justify-content-start col-12">
                                                                        <h3 className="mb-0 text-body">{floor}</h3>
                                                                    </div>

                                                                    <div className="">
                                                                        <img
                                                                            onClick={(e) => {
                                                                                handleShow(e, floorMap.url)
                                                                            }}
                                                                            className="container-image"
                                                                            src={floorMap.url}
                                                                            alt={
                                                                                floorMap.url
                                                                                    ? ""
                                                                                    : t("image_not_found")
                                                                            }
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            {!floorMap && !all && (
                                                                <>
                                                                    <div className="d-flex justify-content-start col-12">
                                                                        <h3 className="mb-0 text-body">{floor}</h3>
                                                                    </div>
                                                                    <div className="container-image-not-found">
                                                                        {t("image_not_found")}
                                                                    </div>
                                                                </>
                                                            )}
                                                            {/* allの場合 */}
                                                            {all && floorMapInfo && (
                                                                <>
                                                                    <div class="container-fluid">
                                                                        <div class="row flex-nowrap">
                                                                            {floorMapInfo.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className=" col-4">
                                                                                            <h3 className="mb-0 text-body">{item.floor}</h3>
                                                                                            <img onClick={(e) => {
                                                                                                handleShow(e, item.url)
                                                                                            }}
                                                                                                className="container-image"
                                                                                                src={item.url}
                                                                                                alt="image"
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {all && !floorMapInfo && (
                                                                <>
                                                                    <div className="d-flex justify-content-start col-12">
                                                                        <h3 className="mb-0 text-body">{floor}</h3>
                                                                    </div>
                                                                    <div className="container-image-not-found">
                                                                        {t("image_not_found")}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>

                                                    </Grid>

                                                    {/* WorkPlace Description */}
                                                    <Grid item xs={12} >
                                                        <Grid container direction="row" justifyContent="left" alignItems="center">

                                                            <div className="col-12 d-flex justify-content-start align-items-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn-description"
                                                                    disabled={!formik.isValid}
                                                                    onClick={() => setWorkplaceData(true)}
                                                                >
                                                                    {t("Description")}
                                                                </button>

                                                                <select
                                                                    name="floor"
                                                                    className="select-box"
                                                                    onChange={(e) => {
                                                                        handleFloor(e.target.value);
                                                                    }}>
                                                                    <option
                                                                        value="DEFAULT"
                                                                        disabled
                                                                        selected={floor === null}
                                                                    >
                                                                        {t("select_floor")}
                                                                    </option>
                                                                    {floorList.map((item) => {
                                                                        if (item === "all") {
                                                                            return <option value={item} selected>{item}</option>;
                                                                        } else {
                                                                            return <option value={item}>{item}</option>;
                                                                        }

                                                                    })}
                                                                </select>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    {/* DASHBOARD EMBEDDING */}
                                                    <Grid item xs={12} style={{ height: "100vh" }} className="grid-padding" >
                                                        <div id="iframeBlock">
                                                            <div className="iframe-withoutTab">
                                                                <div className="iframeBody">
                                                                    <div id="experience-container" className="container-dashboard-embedding"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </div>
                                            )}
                                        </Grid>

                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                )
                }
            </div >
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                // fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img
                        className="d-block w-100"
                        src={url}
                        alt="First slide"
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={workplaceData}
                onHide={handleWorkplaceDataclose}
                className="custom-modal"
                centered
            >
                <Modal.Header closeButton className="border-0">
                    <h4 className="clr-14213D">{t("workPlace")}</h4>
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex  clr-14213D gap-2">
                        <div className="admin_comments">
                            <img src={workplace} alt="Image not availabe" height={700} />
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </Layout >
    );
}
import React, { useState, useEffect, useLayoutEffect } from "react";
import { FaAngleRight, FaCloudUploadAlt } from "react-icons/fa";
import { saveAs } from "file-saver";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./shared/Fileupload/Analysis.scss";
import { useStore } from "../Store";
import { AnalysisService } from "../utils/services/analysis.service";
import Swal from "sweetalert2";
import { FileService } from "../utils/services/file.service";

const analysisService = new AnalysisService();

const WifiAccessPointSettingModal = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();

  const [secret, setSecret] = useState(null);
  const [validator, setValidator] = useState(null);

  const [inputFileMetaInfo, setInputFileMetaInfo] = useState([]);
  const { tenant } = useStore();

  const fileService = new FileService();

  useEffect(() => {
    getInputFileMetaInfoOperationAnalysis()
    getInputSettingOperationAnalysis()
  }, []);

  const getInputFileMetaInfoOperationAnalysis = async () => {
    const response = await analysisService.getInputFileMetaInfoOperationAnalysis(tenant, "specify_method_name_here")
    if (response) {
      setInputFileMetaInfo(response.data);
    }
  };

  const getInputSettingOperationAnalysis = async () => {
    const response = await analysisService.getInputSettingOperationAnalysis(tenant, "wifiAccessPoint")
    if (response.data) {
      setSecret(response.data.secret)
    }
  }

  const postInputSettingOperationAnalysis = async () => {
    const setting = {
      "secret": secret,
    }
    const response = await analysisService.postInputSettingOperationAnalysis(tenant, "wifiAccessPoint", setting)
    if (response) {
      getInputSettingOperationAnalysis()
    }
    Swal.fire({
      title: t("setting_updated"),
      confirmButtonColor: "#D57B85",
      confirmButtonText: "OK",
      allowOutsideClick: false,
    })
  };

  const createMerakiStack = async () => {
    const setting = {
      "secret": secret,
      "validator": validator,
    }
    const response = await analysisService.createMerakiStack(tenant, setting)
    if (response) {
      Swal.fire({
        title: t("new_meraki_stack_created"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    }
  }

  const handleSecretChange = (e) => {
    setSecret(e.target.value);
  }

  const handleValidatorChange = (e) => {
    setValidator(e.target.value);
  }

  return (
    <Modal
      className="operational-setting-sub-modal"
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <h4 className="clr-14213D mb-0 "><b>{t("automaticallyCollected")}</b></h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column clr-14213D">
          <div className="d-flex flex-column">
            <div className="mb-2 d-flex">
              <label className="me-2 col-2 bg-6793bd labelExcel">
                <b className="p-1">SecretKey</b>
              </label>
              <input
                className="bg-clrded col-10 border-0 h4 mb-0"
                style={{ fontSize: "inherit" }}
                onChange={handleSecretChange}
                value={secret}
                name="secret"
                id="secret"
              />
            </div>
            <div className="d-flex">
              <label className="me-2 col-2 bg-6793bd labelExcel">
                <b className="p-1">Endpoint</b>
              </label>
              <input
                className="bg-clrded col-10 border-0 h4 mb-0"
                style={{ fontSize: "inherit" }}
                placeholder="https://3q1nnztwod.execute-api.ap-northeast-1.amazonaws.com/dev/{tenantId}"
                value={"https://3q1nnztwod.execute-api.ap-northeast-1.amazonaws.com/dev/" + "meraki-" + tenant}
                name="endpoint"
                id="endpoint"
              />
            </div>
          </div>



          <div className=" mt-4">
            <div className="d-flex">
              <div className="col-2 bg-clrded "> <p className="mb-5 mt-2 mx-4"><b>STEP1</b></p></div>
              <div className="col-10 ms-4">
                <button className=" mx-auto btn-6793b6 px-4 py-1">
                  <a href="https://n240.meraki.com/login/dashboard_login">{t("openSetting")}</a>
                </button>

                <p className="mt-2">{t("pleaseCompleteSettingURL")}</p>

                <input style={{ position: "relative", bottom: "10px" }}
                  onChange={handleValidatorChange}
                  value={validator}
                  name="validator"
                  className="ps-1 select-class"
                  id="validator"
                />
              </div>
            </div>
            <div className="d-flex">
              <div className="col-2 bg-clrded"> <p className="mb-5 mt-2 mx-4"><b>STEP2</b> </p></div>
              <div className="col-10 ms-4">
                <p className="mt-2">{t("pleaseRegisterSettingURL")}</p>

              </div>
            </div>


          </div>

          <button
            className=" btn btn-submit mt-4 mx-auto"
            onClick={() => { postInputSettingOperationAnalysis(); createMerakiStack() }}
          >
            {t("register")}
            <FaAngleRight />
          </button>
        </div>

      </Modal.Body>
    </Modal>
  )
};
export default WifiAccessPointSettingModal;
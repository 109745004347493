import React, { useState, useEffect, useCallback } from "react";
import Files from "react-files";
import FileStyles from "../Fileupload/FileUpload.module.scss";
import { Button, Form } from "react-bootstrap";
import { FileService } from "../../../utils/services/file.service";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { ApiService } from "../../../utils/services/api";
import Cloud from "../../../assets/img/cloud.png";
import { useStore } from "../../../Store";
import axios from "axios";
import { AnalysisService } from "../../../utils/services/analysis.service";
import Loader from "../../Loader";
import { FaAngleRight, FaCloudUploadAlt } from "react-icons/fa";
import { useDropzone } from 'react-dropzone';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

const apiService = new ApiService();
const fileService = new FileService();
const analysisService = new AnalysisService();

const FileUpload = ({ fileTypesforCsv, fileTypesforJpg, branch ,historyList}) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("");
  const { tenant } = useStore();
  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [isDropDown, setDropDowndisable] = useState(true);
  const [isFile, setFileDisable] = useState(true);
  const [parsedData, setParsedData] = useState([]);
  const [floor, setFloor] = useState(null);
  const [floorList, setFloorList] = useState("");
  const [loader, setLoader] = useState(false);

  const onFilesChange = (files) => {
    console.log(files)
    if (!files) {
      setFileDisable(true);
    } else if (files && files[0].type === "text/csv") {
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          results.data.map((ele) => {
            ele["tenant"] = tenant;
          });
          setParsedData(Papa.unparse(results.data));
        },
      });
      setFiles(files);
      setFileDisable(false);
    } else {
      setFiles(files);
      setFileDisable(false);
    }
  };

  const dateFormat = (time) => {
    const dateTime = new Date(Number(time))
    const yearStr = dateTime.getFullYear()
    const monthStr = ("00" + (dateTime.getMonth() + 1)).slice( -2 )
    const dayStr = ("00" + (dateTime.getDate() + 1)).slice( -2 )
    const hourStr = ("00" + (dateTime.getHours() + 1)).slice( -2 )
    const minituteStr = ("00" + (dateTime.getMinutes() + 1)).slice( -2 )
    const formatStr = yearStr + "年" 
                        + monthStr + "月" 
                        + dayStr + "日 "
                        + hourStr + "時"
                        + minituteStr + "分データ"
    return formatStr
};

  useEffect(() => {
    if (i18n.language) {
      changeLang(i18n.language);
    }
  }, [i18n.language]);

  const saveFile = () => {
    const fileName =
      branch.analysis === "space-analysis"
        ? `sample-space-analysis-utilization-info.xlsx`
        : `sample-${branch.analysis}-${branch.type}.xlsx`;

    saveAs(`${process.env.REACT_APP_CSV_BASE_URL}/${fileName}`, fileName);
  };

  const confirmation = () => {
    if (historyList && historyList.length < 10 ) {
      Swal.fire({
        title: t("space_analysis_save_title"),
        text: t("space_analysis_save_message", {count: historyList.length}),
        showCancelButton: true,
        confirmButtonColor: "#66ab79",
        confirmButtonText: "保存",
        cancelButtonColor: "#D57B85",
        cancelButtonText: "キャンセル",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          onSubmit(files, branch);
        }
      });
    }  else if(historyList && historyList.length >= 10 )  {
      Swal.fire({
        title: t("space_analysis_beyond_limit_title"),
        html: t("space_analysis_beyond_limit_message", {deleteHistory: dateFormat(historyList[9])}),
        showCancelButton: true,
        confirmButtonColor: "#66ab79",
        confirmButtonText: "保存",
        cancelButtonColor: "#D57B85",
        cancelButtonText: "キャンセル",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          onSubmit(files, branch);
        }
      });
    }
  };

  const onSubmit = async (files, branch) => {
    // 画像の場合
    if (files.length && branch.type === "utilization-map") {
      const file = files[0];
      console.log(file)
      const extension = file.name.split(".").pop();
      console.log(extension)
      setLoader(true)
      if (!floor) {
        return Swal.fire({
          title: "Error",
          text: "Please select floor.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Retry",
          customClass: {
            confirmButton: "btn btn-primary op-100 ",
          },
        });
      }

      switch (extension) {
        case 'pdf': {
          console.log("pdf")
          const {
            data: { url: uploadUrl },
          } = await fileService.getUploadUrl(
            `raw-space-analysis-utilization-map/${tenant}_${floor}.png`
          );

          const pdfBlob = new Blob([file], { type: file.type });
          const pdfData = new Uint8Array(await pdfBlob.arrayBuffer());
          pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
          const pdfDocument = await pdfjs.getDocument({ data: pdfData }).promise;
          const page = await pdfDocument.getPage(1);
          const scale = 1.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          await page.render({ canvasContext: context, viewport }).promise;
          const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

          console.log(blob)
          await axios.put(uploadUrl, blob, {
            headers: {
              "Content-Type": "image/png",
            },
          });

          if (blob && file.type && uploadUrl && !floor) {
            console.log("uploading file")
            setLoader(false)
            Swal.fire({
              title: t("data_uploaded"),
              text: t("file_has_been_uploaded"),
              icon: "success",
              confirmButtonColor: "#D57B85",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            })
            setFiles([]);
          }
          break;
        }

        default:
          console.log("default")
          const {
            data: { url: uploadUrl },
          } = await fileService.getUploadUrl(
            `raw-space-analysis-utilization-map/${tenant}_${floor}.${extension}`
          );
          const blob = new Blob([file], { type: file.type });
          await axios.put(uploadUrl, blob, {
            headers: {
              "Content-Type": file.type,
            },
          });

          if (blob && file.type && uploadUrl && !floor) {
            console.log("uploading file")
            setLoader(false)
            Swal.fire({
              title: t("data_uploaded"),
              text: t("file_has_been_uploaded"),
              icon: "success",
              confirmButtonColor: "#D57B85",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            })
            setFiles([]);
          }
      }
    } else {
      //画像以外
      setLoader(true)
      const file = files[0];
      console.log(file)
      const extension = file.name.split(".").pop();
      console.log(extension)
      const {
        data: { url: uploadUrl },
      } = await fileService.getUploadUrl(
        branch.analysis === 'operation-analysis' ? `raw-${branch.analysis}-${branch.type}/${tenant}/raw-${branch.analysis}-${branch.type}-${tenant}-${Math.floor(Date.now() / 1000)}.${extension}` :
        // branch.analysis === 'space-analysis' ? `raw-${branch.analysis}-${branch.type}/raw-${branch.analysis}-${branch.type}-${tenant}-${Date.now()}.${extension}`:
          `raw-${branch.analysis}-${branch.type}/raw-${branch.analysis}-${branch.type}-${tenant}-${Math.floor(Date.now() / 1000)}.${extension}`
      );
      const blob = new Blob([file], { type: file.type });
      await axios.put(uploadUrl, blob, {
        headers: {
          "Content-Type": file.type,
        },
      });
      if (file.type && blob && uploadUrl) {
        setLoader(false)
        Swal.fire({
          title: t("data_uploaded"),
          text: t("file_has_been_uploaded"),
          icon: "success",
          confirmButtonColor: "#D57B85",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        })
        setFiles([]);
        return;
      }
    }
  };

  const onFilesError = (error, file) => {
    setError(error.message);
  };

  const handleFloorChange = (e) => {
    if (e) {
      if (files.length) {
        setFiles([]);
      }
      setFloor(e);
    }
  };
  const location = useLocation();

  useEffect(() => {
    getSpaceutitlizationMapFloorList()
  }, [location, tenant, floor]);

  const getSpaceutitlizationMapFloorList = async () => {
    const res = await analysisService.getSpaceutitlizationMapFloorList(tenant, "")
    setFloorList(res.data.floors.filter((v, i, a) => v !== 'all' && a.indexOf(v) === i))
  }


  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles); // （4）
    onFilesChange(acceptedFiles)
  }, []); // 空配列を指定したのでonDropのインスタンスはコンポーネントの破棄まで更新されない

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop });

  return (
    <div>
      <div  {...getRootProps()} className="d-flex files-dropzone w-75 mx-auto flex-column align-items-center justify-content-center">
        <input {...getInputProps()} />
        <div className="d-flex justify-content-lg-between pb-3">

          {/* 空間分析用の階の設定 */}
          {branch &&
            branch.analysis === "space-analysis" &&
            branch.type === "utilization-map" && (
              <div className="me-3 d-flex">
                {floorList &&
                  <div className="col-12 ">
                    <select className="btn btn-14213D " name="floor" onChange={(e) => { handleFloorChange(e.target.value) }}>
                      <option value="DEFAULT" selected={floor === null}
                        disabled>
                        {t("select_floor")}
                      </option>
                      {floorList.map((item) => {
                        return (
                          <option value={item}>
                            {item}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                }
              </div>
            )}

          {/* フォームの選択とダウンロードボタン */}
          <div className={`${FileStyles.box}  align-items-center`}>
            {/* ダウンロードボタン */}
            <div className="col-12" hidden={branch && !branch.type}>
              {branch && branch.type !== "utilization-map" && (
                <button
                  className={`btn btn-link op-100 op-100`}
                  onClick={saveFile}
                >
                  {t("downloadSample")}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* ドラッグアンドドロップしてくださいと雲マーク */}
        <h6 className="mb-4">{t("drag_drop")}</h6>
        <FaCloudUploadAlt size={60} />

        <Button className="text-decoration-none" variant="link">
          {" "}
          {t("dropYourImage")}
        </Button>

        {files.length
          ? files.map((ele) => {
            return (
              <div className="pb-2 pt-1 ps-2 text-black-50" key={ele.id}>
                {ele.name}
              </div>
            );
          })
          : ""}

        {error ? <div className="text-danger">{error}</div> : ""}


        <div className="d-flex justify-content-center">
          {loader ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center pt-2">
              <button
                type="button"
                className="btn btn-green op-100"
                disabled={!files.length || (branch.type === "utilization-map" && !floor)}
                onClick={(e) => {
                  e.stopPropagation(); // ファイル選択ダイアログが再度開かれる現象を回避
                  // if(branch.analysis === "space-analysis" && branch.type === "utilization-info"){
                  //   confirmation();
                  // } else {
                  onSubmit(files, branch);
                  // }
                }}
              >
                {t("save")}
              </button>
            </div>
          )
          }
        </div>
      </div>
    </div >
  );
};

export default FileUpload;

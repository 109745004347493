import React, { useState, useEffect } from "react";
import { Modal, Card, Alert } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SurveyService } from "../utils/services/survey.service";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import "./survey.scss";
import { FaEdit, FaPlusCircle, FaRegTrashAlt, FaPlus } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useSortableData } from "../utils/useSortableData";

const surveyService = new SurveyService();
const initialValues = {
  survey_title: "",
  survey: "",
};
const savedValues = {
  survey_title: "",
  survey: "",
};
const validationSchema = Yup.object({
  survey_title: Yup.string().required("Survey Title is required."),
});
const validateSurvey = (value) => {
  let error;
  if (!value) {
    return (error = "Survey json is required");
  } else {
    try {
      const result = JSON.parse(value);
      return (error = "");
    } catch (err) {
      return (error = "Invalid json");
    }
  }
};

const SurveySetting = ({ title }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("");

  const location = useLocation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formValues, setFormValues] = useState(null);
  const [surveyList, setSurveylist] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const { sortItems, requestSort, sortConfig } = useSortableData(
    surveyList || ""
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };
  const addSurvey = async () => {
    setShow(true);
    setFormValues(initialValues);
    setIsEdit(false);
  };

  const onSubmit = async (e) => {
    const params = { survey_title: e.survey_title, survey: e.survey };
    const response = await surveyService.postSurveys(title, params);
    if (response) {
      getSurveys();
      setShow(false);
    }
  };

  const getSurveys = async () => {
    try {
      const response = await surveyService.getSurveys(title);
      setSurveylist(response.data);
    } catch (err) { }
  };

  const editSurveyList = async (data) => {
    surveyList.map((ele) => {
      if (data.survey_id === ele.survey_id) {
        const savedValues = ele;
        savedValues["survey"] = JSON.stringify(savedValues.survey).replace(
          /\n/g,
          ""
        );
        savedValues["survey"] = JSON.parse(savedValues.survey);
        setFormValues(savedValues);
        setIsEdit(true);
        setShow(true);
      }
    });
  };

  const updateSurveyList = async (ele) => {
    const params = {
      survey_id: ele.survey_id,
      survey: ele.survey,
      survey_title: ele.survey_title,
      type: title,
    };
    const response = await surveyService.updateSurvey(params);
    if (response) {
      getSurveys();
      handleClose(true);
      setSurveylist(
        surveyList.map((surve) =>
          surve.survey_id === ele.surve ? { ...surve } : surve
        )
      );
    }
  };

  const confirmDelete = (survey_id) => {
    Swal.fire({
      title: t("are_u_Sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D57B85",
      cancelButtonColor: "#66AB79",
      confirmButtonText: "削除する",
      cancelButtonText: "キャンセル",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSurvey(survey_id);
        Swal.fire({
          title: "削除しました",
          confirmButtonColor: "#D57B85",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        })
      }
    });
  };
  const deleteSurvey = async (survey_id) => {
    const response = await surveyService.deleteSurvey(survey_id, title);
    if (response) {
      getSurveys();
      handleClose(true);
      setSurveylist(
        surveyList.map((surve) =>
          surve.survey_id === survey_id ? { ...surve } : surve
        )
      );
    }
  };

  const confirmDuplication = (row) => {
    duplicationRow(row);
  };
  ;

  const duplicationRow = async (e) => {
    const params = { survey_title: e.survey_title, survey: e.survey };
    const response = await surveyService.postSurveys(title, params);
    if (response) {
      getSurveys();
      setShow(false);
    }
  };
  useEffect(() => {
    if (i18n.language) {
      changeLang(i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    getSurveys();
  }, [location]);

  const handleSearch = (event) => { };

  return (
    <>
      <div className="col-12 px-2">
        <div className="d-flex justify-content-between ">
          <label htmlFor="search" className="text-dark mb-2">
            <input
              id="search"
              type="text"
              onChange={handleSearch}
              className="form-control rounded-0"
              placeholder={t("search_data")}
            />
          </label>
          <div className="d-flex">
            <button className="btn add-btn op-100" onClick={() => addSurvey()}>
              <FaPlusCircle className="align-text-middle" /> {t("add")}
            </button>
          </div>
        </div>
        <div className="research-management-cont">
          <Card className="my-2 border-0">
            <Card.Body className=" card-body-height p-0">
              <Card.Text className=" px-2 ">
                <table className="table table-striped  clr-14213D">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">
                        <button
                          type="button"
                          onClick={() => requestSort("survey_title")}
                          className={`${getClassNamesFor("survey_title")} btn`}
                        >
                          {title === "workshops"
                            ? t("workshop_title")
                            : title === "topinterviews"
                              ? t("top_interview_theme")
                              : t("surveyTitle")}
                        </button>
                      </th>
                      <th scope="col">{t("action")}</th>
                    </tr>
                  </thead>

                  <tbody className="border">
                    {sortItems &&
                      sortItems.map((row, i) => (
                        <tr key={i}>
                          <td scope="row" className="col-2 align-middle">
                            {i + 1}
                          </td>
                          <td className="col-5 align-middle">
                            {row.survey_title ?? row.survey_title}
                          </td>
                          <td className="col-5 bg-transparent p-0 d-flex w-100">
                            <button
                              className="btn btn-green me-2 op-100 rounded-0"
                              onClick={() => editSurveyList(row)}
                            >
                              <FaEdit className="align-text-middle" />{" "}
                              <span className="pt-1">{t("edit")} </span>
                            </button>
                            <button
                              className="btn btn-E65C4F op-100 me-2 rounded-0  d-flex align-items-center justify-content-center"
                              onClick={() => confirmDelete(row.survey_id)}
                            >
                              <FaRegTrashAlt className="align-text-middle" />{" "}
                              <span className="ps-1">{t("delete")}</span>
                            </button>
                            <button
                              className="btn btn-6793bd op-100 rounded-0 d-flex align-items-center justify-content-center"
                              onClick={() => confirmDuplication(row)}
                            >
                              <FaPlus className="align-text-middle" />{" "}
                              <span className="ps-1">{t("duplication")}</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="add-agent-popup">
        <Modal.Header closeButton>
          <h5 className="clr-14213D mb-0 ">
            <b>
              {" "}
              {isEdit
                ? title === "workshops"
                  ? t("edit_Workshop")
                  : title === "topinterviews"
                    ? t("edit_topInterview")
                    : t("editSurvey")
                : title === "workshops"
                  ? t("create_Workshop")
                  : title === "topinterviews"
                    ? t("create_topInterview")
                    : t("createSurvey")}
            </b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          {!isEdit ? (
            <Alert key="primary" variant="success">
              {t("followThis")}{" "}
              <Alert.Link
                target="_blank"
                href="https://surveyjs.io/Survey-Creator"
              >
                {t("link")}
              </Alert.Link>
              {t("createJsonTextCopy")}
            </Alert>
          ) : (
            <Alert key="primary" variant="primary">
              {t("followThis")}
              <Alert.Link
                target="_blank"
                href="https://surveyjs.io/Survey-Creator"
              >
                {t("link")}
              </Alert.Link>
              {t("editJsonTextXCopy")}
            </Alert>
          )}
          <div>
            <Formik
              initialValues={formValues || initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form className="text-purple" onSubmit={formik.handleSubmit}>
                    <label
                      type="text"
                      className="clr-14213D fnt-14"
                      htmlFor="survey_title"
                    >
                      <b>
                        {" "}
                        {title === "workshops"
                          ? t("workshop_title")
                          : title === "topinterviews"
                            ? t("top_interview_theme")
                            : t("surveyTitle")}
                      </b>
                    </label>
                    <Field
                      type="text"
                      id="survey_title"
                      name="survey_title"
                      className="w-100 "
                      placeholder={
                        title === "workshops"
                          ? t("workshop_title")
                          : title === "topinterviews"
                            ? t("top_interview_theme")
                            : t("surveyTitle")
                      }
                    />
                    <div className="text-danger mb-1 ">
                      <ErrorMessage
                        name="survey_title"
                        className="text-danger"
                      />
                    </div>
                    <label
                      type="text-in"
                      className="clr-14213D"
                      htmlFor="survey_title"
                    >
                      <b>
                        {title === "workshops"
                          ? t("workshop")
                          : title === "topinterviews"
                            ? t("top_interview")
                            : t("survey")}
                      </b>
                    </label>
                    <Field
                      as="textarea"
                      id="survey"
                      name="survey"
                      className="w-100"
                      rows={10}
                      placeholder={t("placeYourJsonData")}
                      validate={validateSurvey}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="survey" className="text-danger" />
                    </div>
                    <div className="d-flex justify-content-end pt-2">
                      {isEdit ? (
                        <button
                          type="button"
                          className="btn btn-success op-100"
                          disabled={!formik.isValid}
                          onClick={() => updateSurveyList(formik.values)}
                        >
                          {t("update")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-green op-100"
                          disabled={!formik.isValid}
                          onClick={() => onSubmit(formik.values)}
                        >
                          {t("save")}
                        </button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SurveySetting;

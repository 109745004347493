import { ExposureZeroTwoTone } from "@material-ui/icons";

export const holidays_in_english = [
  "2023/1/1, Sun, New Year's Day",
  "2023/1/2, Monday, substitute holiday",
  "2023/1/9, Mon, Coming of Age Day",
  "2023/2/11, Sat, National Day",
  "2023/2/23, Thu, Emperor's Birthday",
  "2023/3/21, Tue, Vernal Equinox Day",
  "2023/4/29, Sat, Showa Day",
  "2023/5/3, Wed, Constitution Day",
  "2023/5/4, Thursday, Greenery Day",
  "2023/5/5, Fri, Children's Day",
  "2023/7/17, Monday, Sea Day",
  "2023/8/11, Fri, Mountain Day",
  "2023/9/18, Mon, Respect-for-Senior-Citizens Day",
  "2023/9/23, Sat, Autumnal Equinox Day",
  "2023/10/9, Monday, Sports Day",
  "2023/11/3, Fri, Culture Day",
  "2023/11/23, Thu, Labor Thanksgiving Day"
];

export const holidays_in_japanese = [
  "2023/1/1, 日, 元日",
  "2023/1/2, 月, 振替休日",
  "2023/1/9, 月, 成人の日",
  "2023/2/11, 土, 建国記念の日",
  "2023/2/23, 木, 天皇誕生日",
  "2023/3/21, 火, 春分の日",
  "2023/4/29, 土, 昭和の日",
  "2023/5/3, 水, 憲法記念日",
  "2023/5/4, 木, みどりの日",
  "2023/5/5, 金, こどもの日",
  "2023/7/17, 月, 海の日",
  "2023/8/11, 金, 山の日",
  "2023/9/18, 月, 敬老の日",
  "2023/9/23, 土, 秋分の日",
  "2023/10/9, 月, スポーツの日",
  "2023/11/3, 金, 文化の日",
  "2023/11/23, 木, 勤労感謝の日"
]

export const days_in_english=[
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
]

export const days_in_japanese=[
"月曜日",
"火曜日",
"水曜日",
"木曜日",
"金曜日",
"土曜日",
"日曜日"
]
